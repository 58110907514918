.offerrow {
  &_offer-details {
    margin-bottom: 0.4rem;
    width: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
  }

  &_row {
    display: flex;
    align-content: center;
    align-items: center;
  }

  &_offer-img {
    height: 5rem;
    width: 8rem;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 0.5rem;
    margin-top: 0.4rem;
  }

  &_offer-title {
    font-family: Poppins;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.8125rem;

    @include respond(phone) {
      font-size: 1.3rem;
    }
  }

  &_discount {
    display: flex;
    align-self: "center";
    justify-content: center;
    align-items: center;
    background-color: #b7ffe4;
    border-radius: 0.3rem;
    width: 8rem;
    max-width: 10rem;
    height: 3.5rem;

    @include respond(phone) {
      //  display: flex;
      // width: auto;
      width: 7.5rem;
      // min-width: 8rem;
      height: 3.3rem;
    }
  }

  &_discounttext {
    display: flex;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: small;
    color: #286b53;

    @include respond(phone) {
      font-size: smaller;
    }
  }

  &_tickets {
    border: 0.8rem solid white;
    border-radius: 50%;
    margin-left: -3.5rem;

    @include respond(phone) {
      margin-left: -0.09rem;
    }

    @include respond(tab-port) {
      margin-left: -1rem;
    }

    @include respond(desktop) {
      margin-left: -2rem;
    }
  }

  &_ticketsright {
    border: 0.8rem solid white;
    border-radius: 50%;
    margin-right: -3.5rem;

    @include respond(phone) {
      margin-right: -0.09rem;
    }

    @include respond(tab-port) {
      margin-right: -1rem;
    }

    @include respond(desktop) {
      margin-right: -2rem;
    }
  }

  &_price {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: 1.8125rem;
    display: flex;
    align-items: center;

    @include respond(phone) {
      font-size: xx-small;
    }
  }

  &_coupon {
    margin-left: -0.5rem;

    @include respond(phone) {
      margin-left: -0.8rem;
      font-size: x-small;
    }
    @include respond(tab-port) {
      margin-left: -0.9rem;
      font-size: small;
    }
  }
}

.bottom-margin-12 {
  margin-bottom: 0.75rem;
}

.bottom-margin-24 {
  margin-bottom: 0.75rem;

  // commenting this code for bigscreen testing purpose

//   @include respond(too-big){
//       margin-top: 2rem;
//   }
}
