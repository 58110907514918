.bussiness {

    &_header {
        text-align: center;
        margin: 20px;
    }

    &_mainLogo {
        max-width: 50%;
    }

    &_header-text {
        text-align: center
    }

    &_weblist {
        padding-top: 0.625rem;
        margin: 0.3125rem 0;
    }

    &_btn {
        margin-top: 2rem;
    }

    &_revise-terms {
        margin-bottom: 2rem;
    }

    &_select-tc {
        max-width: 100%;
        width: 100%;
    }

    &_check-in {
        padding-top: 2rem;
    }

    &_tc-options {
        width: 100%;
        word-break: break-all
    }

    &_tc-conditional {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &_buttons_conditional {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center
    }
}