.dominospizzstyle{
    font-weight: "bold";
    width: 37.1875rem;
height: 2.3125rem;

font-family: Helvetica;
font-style: normal;
font-weight: 700;
font-size: 2.25rem;
line-height: 1.5rem
}

.instorecarbside{
    width: 14.1875rem;
height: 1.8125rem;
font-family:Helvetica;
font-style:normal;
font-weight:400;
font-size: 1.125rem;
line-height: 1.8125rem
}

.milespheonix{
    width: 14.1875rem;
height: 1.8125rem;
 color:#727272;
font-family: Helvetica;
font-style: normal;
font-weight: 400;
font-size: 1.125rem;
line-height: 1.8125rem
}

.famapkeraltdiv{
    padding: 1rem;
    border-radius: 50%;
    border: .3px solid #ddd;
    margin: 1rem;
    cursor: pointer;
    width:0.25rem;
    height:0.25rem;
    margin-top:0.875rem
}

.famapkeralticon{
    font-size: 2rem;
    width:15px;
    margin-top:-1.7rem;
    margin-left:-0.5rem
}

.faphonediv{
    padding: 1rem;
  border-radius: 50%;
  border: .3px solid #ddd;
  margin:1rem;
  cursor: pointer;
  width:0.3125rem;
  height:0.3125rem;  
}

.faphoneicon{
    font-size: 1rem; 
    width:30px;
    margin-top:-2rem;
    margin-left:-1rem 
}

.faglobediv{
    padding: 1rem;
    border-radius: 50%;
    border: .3px solid #ddd;
    margin: 1.1rem;
    cursor: pointer;
    width:5px;
    height:5px  
}

.faglobeicon{
    font-size: 2rem;  
    width:20px;
    margin-top:-2rem;
    margin-left:-0.7rem
}

.faclockdiv{
    padding: 1rem;
    border-radius:50%;
    border: .3px solid #ddd;
    margin: 1.1rem;
    cursor: pointer;
    width:0.4375rem;
    height:0.4375rem ; 
}

.faclockicon{
    font-size: 2rem;
    width:20px;
    margin-top:-1.9rem;
    margin-left:-0.7rem;
}

.firstorderreviewrow{
   // background-color: red;
    margin-bottom: 2rem;
    
    
}

.firstorderreviewrowcol1{
   // background-color: green;
    // padding-right:1rem;
    // padding-left: 1rem;
}

.firstorderreviewrowcol2{
    //background-color: yellow;
    justify-items: flex-end;
    //justify-content: flex-end;
    // padding-left:1rem
}

.totalamoutrow{
    //background-color:pink ; 
    flex-wrap: nowrap;
}

.totalamoutrowcol1{
    // padding-left: 1rem;
    // padding-right: 2rem;
}

.totalamoutrowcol1row1{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    
}

.totalamoutrowcol1row2{
    margin-bottom: 2rem;
}

.totalamoutrowcol1row3{
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
}

.rowqtybtn{
    width:19rem;
   
}

.qtyinputnumberbtn{
    max-height:3rem;
    width:6rem
}

.totalpayableamounth5{
    margin-top: 0.5rem;
}
.totaltaxh5{
    margin-top: 0.5rem; 
}

.deliverytimebigrow{
    margin-left:1rem;
    flex-wrap:nowrap;
}

.deliverytimecol{
    margin-left:5rem;
}

.specialinstructioninput{
    margin-top: 2rem;
}

