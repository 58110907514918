.ant-menu-submenu-title {
  display: block !important;
}

.hover-menu {
  &:hover {
    color: #fff !important;
  }
}

.app-header {
  padding: 0;
  border: 0;

  //  display:flex;

  &.ant-layout-header {
    height: $l_header_height;
    line-height: $l_header_height;
  }

  .app-header-inner {
    height: $l_header_height;
    background-color: #fff;
  }

  &_offer-btn {
    border: 2px solid white;
    height: 2.75rem;
    font-size: 1rem;
    font-weight: 400;
    background-color: #257CDB;
    border-radius: 0.5rem;
    margin-left: 1rem;
    margin-right: 2rem;
    padding: 0rem 1rem;
    @media (min-width: 2560px) {
      vertical-align: middle;
      height: 90%;
    }
    @media (min-width: 2200px) and (max-width:2560px) {
      height: 2rem;
      top: 4px;
    }
    @media (max-width: 992px) {
      font-size: 1.3rem;
      height: 4rem;
    }
  }
}
//  .app-header-mobile {
//    display: none
//  }

//  @media screen and (min-width:320px) and (max-width:768px) {
//    .logo {
//      display: none;
//    }

//    .ant-menu-horizontal {
//      display: block;
//      width: 100%;
//    }

//    .app-header {
//      display: none;
//    }

//    .app-header_offer-btn-mobile {
//      border: 2px solid white;
//      font-size: 1rem;
//      font-weight: 400;
//      background-color: #257CDB;
//      border-radius: .5rem;
//    }

//  .app-header-mobile {
//    padding: 0;
//    border: 0;
//   //  display: flex;

//    &.ant-layout-header {
//      height: $l_header_height;
//      line-height: $l_header_height;
//     //  background-color: #257CDB;
//    }

//    .app-header-inner {
//      height: $l_header_height;
//      background-color: #fff;
//    }
//  }
//  }

.app-header_offer-btn.bg-danger-fyndr{
    width: 10rem;
    min-height: 35px;
    top: 13px;
    @media (min-width: 2200px)and (max-width:2559px) {
      top: 9px;
      height: 43px;
    }
    @media (min-width: 2560px){
      top: 0px;
      height: 57px;
    }
}

.app-header_wallet-btn {
  background: transparent;
  border: none;
  color: #fff;
  padding: 0;
  font-size: 1rem;
  @media (max-width: 992px) {
    font-size: 1.3rem;
  }
}

.app-header_wallet-btn:hover {
  border: none;
  background-color: transparent;
  color: #fff;
}

.app-header_offer-btn:hover {
  border: 2px solid white;
  background-color: #257cdb;
}
.app-header_wallet-btn:focus {
  border: none;
  background-color: transparent;
  color: #fff;
}
.app-header_wallet-btn:active {
  border: none;
  background-color: transparent;
  color: #fff;
}
.app-header-menuitem.ant-menu-item:active,
.ant-menu-submenu-title:active {
  border: none;
  background-color: transparent;
  color: #fff;
}
.app-header
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-active::after {
  border-bottom: 2px solid white;
}
.header_menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0px 0px !important;
}
.app-header-inner {
  display: flex;
  justify-content: space-between;
  transition: $l_transition_antd;

  .logo-img {
    float: left;
    margin: 18px 15px 0;
  }

  .ant-divider-vertical {
    background: rgba(222, 222, 222, 0.5);
    top: -2.5px;
  }

  .header-right {
    padding-right: 5px;
    max-width: 100%;
  }

  .header-right-common {
    padding-right: 0.5rem;
    @media (min-width: 768px)and (max-width:991px) {
      padding-right: 10.5rem;
    }
    @media (min-width: 992px)and (max-width:1060px) {
      padding-right: 4.5rem;
    }
  }

  .header-icon {
    display: inline-block;
    height: $l_header_height;
    line-height: $l_header_height;
    padding: 0 14px;

    @media only screen and (min-width: $l_screen_md_min) {
      padding: 0 18px;
    }
  }

  .ant-menu-horizontal {
    line-height: ($l_header_height - 2);
    border-color: transparent;
  }

  a.list-inline-item {
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .list-inline-item {
    padding: 0 0.875rem;
    margin: 0;
    transition: all 0.3s;

    .ant-badge {
      .ant-badge-count {
        font-size: 10px;
        line-height: 20px;
        font-weight: $font-weight-light;
        padding: 0 4px;
      }
    }

    .anticon {
      display: inline-block;
    }

    .list-icon {
      font-size: 1rem;
      line-height: $l_header_height;
      padding: 0 4px;
    }

    .list-icon-v2 {
      font-size: 1rem;
      padding: 0 4px;
    }

    .list-notification-icon {
      font-size: 1rem;
      padding: 4px;
    }

    .avatar-text {
      margin-left: 0.5rem;
    }
  }
}

.app-header-dropdown {
  .ant-dropdown-menu-item {
    i {
      margin-right: 0.5rem;
    }
  }
}

//
.app-header-popover {
  max-width: 300px;

  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    max-width: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}

.app-header-notifications {
  .ant-tabs-nav .ant-tabs-tab {
    padding: 1rem;
  }

  .ant-tabs-bar {
    margin-bottom: 0.25rem;
  }

  .ant-list-item {
    padding: 0.75rem 1.5rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba($info, 0.08);
      cursor: pointer;
    }
  }

  .ant-list-footer {
    padding: 0;

    a {
      display: block;
      padding: 0.75rem;
      text-align: center;
      color: $body-color2;
      transition: all 0.2s;

      &:hover {
        color: $body-color;
        background-color: rgba($gray-900, 0.03);
      }
    }
  }
}

.search-box {
  max-width: 180px;
  height: $l_header_height;
  line-height: 24px;

  &.list-inline-item {
    padding: 10px 0;
  }

  .search-box-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    position: relative;
    flex: 1 1 auto;
    padding: 7px 10px 5px;
    width: 1%;
    margin-bottom: 0;
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  .search-box-icon {
    padding: 8px 0 4px 10px;

    .anticon {
      padding: 0;
    }
  }

  //
  .input-bar {
    position: absolute;
    display: block;
    width: 90%;
    bottom: 0;
    left: 5%;
  }

  .input-bar:before,
  .input-bar:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 0;
    bottom: 1px;
    background: $body-color;
    transition: all 0.25s;
  }

  .input-bar:before {
    left: 50%;
  }

  .input-bar:after {
    right: 50%;
  }

  input {
    &:focus ~ .input-bar:before,
    &:focus ~ .input-bar:after {
      width: 50%;
    }
  }
}

.seach-box-right {
  .search-box-icon {
    position: relative;
    top: 2px;
  }

  input {
    padding: 9px 10px 3px;
  }
}
.ant-col{
  min-height: 0px;

}

.aboutUsMenu.ant-menu-item:active{
  background: #257CDB;
}
.ManuItem.ant-menu-item:active{
  background: #257CDB;
}

.aboutUsMenu.ant-menu-item:hover{
  color: #fff;
}

.ManuItem.ant-menu-item, .ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ManuItem.ant-menu-item:hover{
  color: #fff;
}

.avatarImgLogo {
  position : absolute;
  margin-left : 23rem;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin-left : 28rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1296px) {
    margin-left : 26rem;
  }
  @media (min-width: 2560px)and (max-width: 2576px) {
    margin-left : 30rem;
  }
  @media (min-width: 2576px) {
    margin-left : 18rem;
  }
}