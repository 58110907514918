.login-container {
  width: 100%;

  h2,
  p {
    text-align: center;
  }

  h2 {
    font-weight: $font-weight-light;
    margin-bottom: 1.5rem;
  }

  .lead {
    margin-bottom: 1.5rem;
  }

  h2+.login,
  .lead+.login {
    margin-top: 2.5rem;
  }

  .additional-info {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

}

.spacer {
  height: 1.5rem;
}

.login {
  .login__remember {
    margin-bottom: 1.25rem;

    .ant-form-item-control {
      line-height: $line-height-sm;
    }
  }
}

.login_btn {
  height: 46px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 12px;

  @media (max-width: 992px) {
    height: 40px;
  }

  @media (max-width: 576px) {
    height: 40px;

  }
}

.login_btn.ant-form-item {
  margin-bottom: 0px !important;
}


.login_box {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  padding: 24px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 65%;

  @media (max-width: 1200px) {
    padding: 12px;
    width: 95%;
  }

  @media (max-width: 992px) {
    padding: 12px;
    width: 95%;
  }

  @media (max-width: 768px) {
    padding: 12px;
    width: 95%;
  }

  @media (max-width: 576px) {
    padding: 8px;
    width: 95%;

  }

}

.forgot {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #CCCCCC;
  text-align: center;
}

.login_overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 20px;
}

.bg-video  {
  width: 100%;
  position: relative !important;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 20px !important;
  // position: absolute;
  // top: 0;
  // left: 0;

  @media screen and (min-width: 768px) and  (max-width: 992px){
    height:70vw;
    object-fit:inherit;
    border-radius: 20px !important;
  }

}
.player-wrapper {
  position: relative;
  border-radius: 20px !important;
}

.video-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 4vw 10vw;

  @media screen and (min-width:1440px) and (max-width:1500px) {
    margin: 6vw;

  }
  @media (max-width: 1200px) {
    margin:4vw 2vw;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin: 30px 30px 28px;
    top: 0;
  }

  @media (max-width: 576px) {
    margin: 10px;
    top: 0;
  }

}

.verification_signup .ant-form-item {
  margin-bottom: 0px !important;
}

.verification_signup .ant-form-item-with-help .ant-form-item-explain {
  text-align: left;
}

.login_div {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // width: 70%;
  bottom: 0;
  top: 0;
}

.go-back {

  @media (max-width: 992px) {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    margin-bottom: 0px;
  }

}

.go-back.ant-btn:active {
  border-color: white !important;
}

.go-back.ant-btn:hover {
  border-color: white !important;
}

.login_text {
  align-self: center;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 16px;

  }

  @media (max-width: 576px) {
    padding: 0px 12px;
    font-size: 14px;
  }
  @media screen and (min-width:320px) and (max-width:576px) {
    font-size: 12px;
    padding: 0px 8px;

  }
}

.login_h2{
  margin-bottom: 12px;
  @media screen and (min-width:320px) and (max-width:576px) {
    font-size: 2rem;
    margin-bottom: 8px;

  }
}

.verification_text {

  margin-bottom: 24px;
  color: #B3B3B3;
  font-weight: 400;
  text-align: start;

  //   @media screen and (min-width:1440px) and (max-width:1500px) {
  //     top: 5vw;
  //  }

  @media (max-width: 1200px) {
    margin-bottom: 12px;
  }

  @media (max-width: 992px) {
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  @media (max-width: 576px) {
    margin-bottom: 12px;

  }

}

.register_user {
  color: rgba(255, 255, 255, 1);
  margin: 0px 0px 24px 0px;
  font-weight: 500;
  font-size: 20px;


  @media (max-width: 992px) {
    margin: 0px 0px 12px 0px;

  }

  @media (max-width: 768px) {
    margin: 0px 0px 12px 0px;

  }

  @media (max-width: 576px) {
    margin: 0px 0px 12px 0px;


  }
}

.proceed_btn {
  margin-top: 12px;

  @media (max-width: 992px) {
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  @media (max-width: 576px) {
    margin: 0px 0px 12px 0px;


  }
}

.ant-form-item-explain-error {
  @media (max-width: 992px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 11px;
  }
}