$color-grey-light:#8888;
$theme-color-blue:#2663CD;
$color-white-pure:#ffff;
$color-grey-background:#EEEEEE;
$color-fyndr-blue : #257CDB;
$color-fyndr-danger-red:#f73f3f;
$color-Theme-Pink: #FF879C;
$color-Theme-LightGreen:#1DCFC2;

$color-dashboard-grey:#F5F6FA;

//views
$mobile-view:480px;
$mid-view:768px;


//new refactorvariables

$background-blue:#F5F6FA;


///temp

.exampleBorder {
    border-left: 1px solid #999999;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-top: 1px solid #999999;
    // border-radius: 10px;
    margin: 0px;
    padding: 20px;
}