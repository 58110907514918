.home {
  background: $background-blue;
}

.header__home {
  background: #fff;
  padding: 1.2rem 2rem 0 0;

  @include respond(tab-land) {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 3rem;
  }

  @include respond(phone) {
    margin-top: 0;
  }
}

.logo_white {
  width: 9rem;
  height: 40px;
  background-image: url("../../../assets/images/fyndr-logo-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0rem 0 0 0rem;
  background-position: center;
}

.logo_no_text {
  width: 50%;
  height: 40px;
  background-image: url("../../../assets/images/fyndr-logo-white.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0rem 0 0 2rem;

  @include respond(phone) {
    width: 2.5rem;
    height: 25px;
  }
}

.logo_blue {
  width: 9rem;
  height: 3.7rem;
  background-image: url("../../../assets/images/fyndr-logo-blue.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0.5rem 0 0 3rem;

  &--no_float {
    float: unset;
    width: 20rem;
    height: 7rem;
    margin: 0;
  }

  &--padded {
    @include respond(tab-mid) {
      margin-top: 2%;
    }

    @include respond(phone) {
      width: auto;
      height: 3rem;
      margin: 2rem;
      margin-top: 0rem;
      // display: none;
      float: unset;
      background-position: center;
    }
  }

  &--center {
    width: 10rem;
    float: unset;
    background-position: center;
    margin: auto;
  }
}

.bg_info_new {
  background-color: #007bff;
}

.bg-info,
.bg-danger-fyndr {
  min-height: 60px;
  position: fixed;
  z-index: 1000;
  width: 75%;
  top: 0;

  @media (min-width: 300px)and (max-width:605px) {
    width: 95%;
  }
}

.bg-info {
  background-color: $color-fyndr-blue !important;
}

.bg-danger-fyndr {
  background-color: $color-fyndr-danger-red !important;
}

div.bg-info {
  background-color: #007bff;
}

.shortHeader {
  @include respond(phone) {
    height: 6rem;
  }

  &-gone {
    @include respond(phone) {
      height: 0;
      display: none;
      transition: height 100ms linear;
    }
  }
}

.overlayYY {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.mobile_Filter {
  background: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;

  &-text {
    color: #00418a;
    font-size: 2rem;
    margin-top: 0.3rem;
  }
}

.radioGroupStyle {
  z-index: 0;
  background-color: white;
  border-radius: 10px;

  @media (max-width: 767px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 768px) and (max-width: 1000px){
    display: flex;
    width: 80%;
  }
  
  @include respond(xs) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.radioStyle {
  overflow: hidden;
  text-align: center;
  width: 150px;
  padding: 3px 36px;

  @include respond(phone) {
    width: 134px;
  }
}

.radioTextStyle {
  vertical-align: middle !important;

  @media (max-width: 767px) {
    padding-top: 1rem;
  }
}

.ant-slider-rail {
  background-color: #223369;
}

.ant-slider-dot {
  background: #223369;
  border-color: #223369;
}

.qty-button {
  background: #d1d1d1 !important;
  color: white;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.modal_invoice .ant-modal-footer {
  background-color: #1D1D1F !important;
  padding: 10px 16px;
}
.modal_invoice .ant-modal-body{
  padding:12px !important

}
.modal_invoice .ant-modal-header {
  border-radius: 10px 10px 0px 0px !important; 
  padding: 12px;
}
.qty-button .anticon {
  vertical-align: 0em !important;
  color: white;
}

.qty-button-active .anticon {
  vertical-align: 0em !important;
  color: white;
}

.qty-button-active {
  background: #223369 !important;
  color: white;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.add-new-btn {
  border: 2px solid #223369;
  border-radius: 5px;
  padding: 0 2.5rem;
  width: 100%;
  margin: 0.2rem 1rem 1rem 1rem;

  @include respond(phone) {
    margin: 3rem 1rem 3rem 1rem;
  }
}

.amount-slip {
  background-color: #f4f8fd;
  padding: 5%;
  margin-left: 30px;

  @include respond(phone) {
    padding: 5%;
    margin-left: 0px;
  }
}

.filterModal .ant-modal-body {
  padding: 20px 10px 44px 10px !important;
}

.filterCard {
  background: #f4f8fd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 24px;
  overflow-x: hidden !important;
}

.filterCardBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  height: 24px;
  background: #257cdb;
  border-radius: 5px;
  margin: 0rem 0.5rem 1rem 0rem;
}

.filterCardBoxText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.dealsBtn {
  background-color: #f8f4f4;
  border-radius: 5px;
  border: 1px solid #257cdb;
  color: #257cdb;
  font-size: 18px;
  height: 36px;
}

.rangeStyle {
  margin-top: 0.4em;
  color: #3A3A3A;
  margin-bottom: 0em;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: right;

}

.hidden-scroll {
  margin-top: 0rem;
  overflow-x: hidden !important;
  overflow-y: scroll;
  height: 90rem;

  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: transparent;
    border-radius: 10px;
  }

  @include respond(tab-gen) {
    // height: 150rem;
  }

  @include respond(xs) {
    overflow-y: hidden;
    height: 100%;
  }
}

.section__main {
  max-width: 100%;

  @include respond(tab-land) {
    // padding-left: 1rem;
    // padding-right: 1rem;
    margin-top: 1rem;
  }
}

//html
html {
  font-size: 62.5%; //1rem = 16px  10/16px

  @include respond(too-big) {
    font-size: 150%;
  }

  @include respond(mac) {
    font-size: 160%; //1rem =24px 24/16px
  }

  @include respond(small-mac) {
    font-size: 100%;
  }

  @include respond(hdpi) {
    font-size: 100%;
  }

  @include respond(big-desktop) {
    font-size: 90%;
  }

  @include respond(desktop) {
    font-size: 85%;
  }

  @include respond(tab-land) {
    font-size: 62.2%;
  }

  @include respond(tab-pro) {
    font-size: 65%;
  }

  @include respond(tab-port) {
    font-size: 60%; //1rem=8px 8/16
  }

  @include respond(phone) {
    font-size: 60%; //1rem =5px 5/16
  }
}

.map_view {
  max-height: min-content;
  height: 25vh;
  width: 30vw;
}

.map_view_new {
  max-height: min-content;
  height: 25vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal_map_view {
  max-height: min-content;
  height: 25vh;
  width: 80vw;
}

.closeIconBtn {
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 25px;
  border-radius: 100%;
}

.btn_blue {
  background-color: #2764cd !important;
  border-color: #2764cd !important;
}

.view_modal_contaier {
  height: 34rem;
  width: 50rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.view_modal_image {
  height: 50vh;
  width: 30vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background: cover;
}

.ant-menu-title-content {
  font-weight: 400 !important;

  @media (max-width: 992px) {
    font-size: 1.3rem;
  }
}

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
  color: #fff !important;
  border-bottom: 0px solid #13a3b8 !important;
  font-weight: 400;
}

.ant-card-head-title {
  font-size: 22px !important;
  padding: 24px 0;
}

.big {
  font-size: 15px !important;
  line-height: 25px;
}

.react-datetime-picker__inputGroup__input {
  min-width: 19px !important;
  font-size: 15px !important;
  text-align: right !important;
}

.react-datetime-picker__inputGroup__year {
  min-width: 40px !important;
  font-size: 15px !important;
}

.icon_container {
  width: 26;
  height: 26;
  border-radius: 23;
  margin-top: 2;
}

.list-sep {
  float: left;
  margin-right: 2rem;
}

.editbtn {
  padding-top: 3rem;
}

.storeUrl {
  &_text {
    font-size: 0.9rem;
    color: #3f9af7;
  }
}

.cc {
  font-size: 30px;
  color: #2764cd;
}

.cc-padding {
  padding-top: 5px;
}

.cc-default {
  background-color: #2dce89;
  color: white;
  padding: 5px;
}

.cc-others {
  padding: 5px;
}

.fab {
  box-shadow: -1px 10px 34px 0px rgba(0, 0, 0, 0.75);
}

.FOB {
  &-div {
    position: relative;
    width: 100%;
  }

  &-button {
    position: fixed;
    bottom: 2%;
    right: 1%;
  }
}

.text-card-appointments {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.card-head {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.card-head .ant-card-head {
  min-height: 30px;
}

.card-offer {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 20px;
  border: 1px solid #d3d6e1;
  border-Radius: 10px;
  width: 100%;
  max-height: 550px;
  overflow-y: scroll;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  margin-top: 10px;

}

.card-offer {
  .ant-card-head {
    padding: 0px 0px 0px 0px !important;
    min-height: 30px;
    border-bottom: 1px solid #D3D6E1;
  }
}

.card-offer .ant-card-head-title {
  padding: 10px 0px 10px 20px !important;
}

.card-offer .ant-card-body {
  padding: 8px 8px 8px 8px;

  @media (max-width: 768px) {}

  @media (max-width: 480px) {}
}

.text-card-appointment-innerCard {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
}

.inner-card {
  border-radius: 10px;
  margin-top: 16px;
  overflow: hidden;
  border-color: #D3D6E1;
  box-shadow: 0px 4px 4px 0px #00000014;
}

.lower-banner {
  padding-top: 3px;
  background-color: #f4f8fd;
  height: 25px;
  width: 127px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-left: 7px;
  margin-top: 0px;
  border: 1px solid #d3d6e1;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.heading-offer-summary {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #000000;
}

.heading-business-name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: #000000;
}

.invoice {
  &_item-description {
    margin-top: 1rem;
    width: 100%;
    border: 1px solid #D3D6E1;
    border-radius: 10px;
  }

  &_details {
    margin-top: 10px;
    width: 100%;
  }

  &_img {
    object-fit: contain;
    max-width: 10rem;
  }

  &_voucher-btn {
    border: 1px solid #257CDB;
    border-radius: 10px;
    height: 46px;
  }
}

.catalogpending {
  &_itemdetails {
    margin: 1rem 0 2rem 0;
  }

  &_itemdetails-data {
    margin: 0.5rem 0 0 0;
  }

  &_invoice-img {
    object-fit: contain;
    max-width: 10rem;
  }
}

.cropper-container {
  top: 100px !important;
  left: 30px !important;
  bottom: 80px !important;
  right: 30px !important;

  @media (min-width: 2560px) {
    bottom: 100px !important;
  }

  @media (max-width: 2560px) {
    bottom: 125px !important;
  }
}

.bizCard {
  padding: 12px;
  border: 1px solid #D3D6E1;
  border-radius: 10px;
}

.bizCard .ant-card-body {
  padding: 12px 12px 0px 12px !important;

}

.invoice_text {
  line-height: 20px;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 600;
}

.invoiced_to {
  color: #257CDB;
  font-size: 16px;
  font-weight: 600;
}

.invoice_value {
  line-height: 20px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;

}

.invoice_btn {
  color: white;
  background: #257CDB;
  height: 45px;
  border-radius: 10px;
  width: 100%;

}
.invoice_offerCard{
  border: 1px solid #D3D6E1;
  border-radius: 10px;


}
.invoice_offerCard .ant-card-body {
  padding: 12px 12px 0px 12px !important;

}