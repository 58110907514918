.catalogue {
  min-height: 120vh;
  background-color: #f5f5f5;
  max-width: 100%;

  @include respondM(tablet) {
    background: #f5f5f5;
  }

  &_main-heading {
    color: #223369;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 1.25rem;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &_collapse-view {
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    margin: 1rem;
  }

  &_calendar {
    border-radius: 5px;
    height: 46px;
    background-color: #257cdb;
  }

  &_msg {
    color: var(--Green-2nd, #50b85a);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    border-radius: 10px 10px 0px 0px;
    background: #e1f4e5;
    padding: 0px 24px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &_firstCol {
    width: 100%;
    border-radius: 0.8rem;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  &_bizname {
    font-weight: 400;
    font-size: 1.5rem;
    padding: 0.5rem;
    color: #223369;

    @media (max-width: 1024px) {
      font-size: 20px;
      padding: 0;
    }
  }

  &_headings {
    color: #3a3a3a;
    font-weight: 400;
    font-size: 1.125rem;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &_subheadings {
    color: #7f7f7f;
    font-size: 1rem;
    margin: 0.5rem;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  &_icons {
    margin: 0.5rem;
  }

  &_btn-icons {
    margin-right: 0.5rem;
  }

  &_btns {
    color: white;
    width: 100%;
    cursor: pointer;
    border-radius: 0.3rem;
    height: 2.875rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  // &_img-icons {
  //     height: 3rem;
  //     width: 3rem;
  //     display: flex;
  //     border-radius: 50%;
  //     justify-content: center;
  //     align-items: center;
  //     background-color: rgba(0, 0, 0, 0.3);
  // }
  &_img-icons {
    height: 4rem;
    width: 6rem;
    display: flex;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  &_img-icons-div {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0rem;
    justify-content: space-between;
    display: flex;
    padding: 0.625rem;
    cursor: pointer;
  }

  &_img-icons-div-step2-mobile {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0rem;
    justify-content: space-between;
    display: flex;
    padding: 1rem;
  }

  &_img-share-icons {
    height: 4rem;
    width: 4rem;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  &_divider {
    border: 0.0625rem solid #d3d6e1;
    width: 90%;
    margin: 12px;
  }

  &_hidden-scroll-img-mobile {
    height: 20vh !important;
    border-radius: 0.625rem;
    cursor: pointer;
    border: 1px solid #f0f2f2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 1.25rem 1.25rem 0rem 1.25rem !important;
  }

  &_img_div-mobile {
    margin: 16px;
    background-color: white;
    border-radius: 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &_company_logo {
    width: calc(min(8vw, 10rem));
    height: calc(min(8vw, 10rem));
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 1rem 0;
  }

  &_company_logo_updated {
    width: calc(min(10vw, 7.5rem));
    height: calc(min(8vw, 7.5rem));
    object-fit: cover;
    border-radius: 55%;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 0.5rem;
  }

  &_text_categoriesdescription {
    color: #454545;
    font-size: 1.4rem;
    padding-bottom: 2rem;

    @media (max-width: 991px) {
      font-size: 2.4rem;
    }

    @media (max-width: 576px) {
      font-size: 1.8rem;
    }
  }

  &_company_logo_updated_mobile {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 55%;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 12px;
  }

  &_company_logo_cat_updated {
    // width: 20rem;
    // max-width: 20rem;
    height: 10.5vw;
    object-fit: contain;
    border-radius: 0.6rem;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 1rem 0;

    // @media (min-width:1024px)  and (max-width:1280px) {
    //   height: 13vw;
    // }
    // @media (min-width:768px)  and (max-width:1024px) {
    //   height: 13vw;
    // }
    @media (min-width: 1024px) {
      height: 10vw;
    }

    @media (max-width: 1024px) {
      height: 13vw;
    }

    @media (max-width: 991px) {
      height: 13vw;
    }

    @media (max-width: 576px) {
      height: 10.5vw;
    }
  }

  &_company_logo_cat_updated_mob {
    // width: 100%;
    // max-width: 50vw;
    height: 18vw;
    object-fit: contain;
    border-radius: 0.6rem;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 1rem 0;
  }

  &_input_number_btn {
    box-sizing: border-box;
    // width: 6rem;
    // height: 3rem;
    width: 52px;
    height: 33px;
    left: 0.5rem;
    top: 0px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &_input_number_btn-mobile {
    // box-sizing: border-box;
    // width: 3.5rem;
    // height: 2.06rem;
    // left: 0.5rem;
    // top: 0px;
    // background: #ffffff;
    // border: 1px solid rgba(0, 0, 0, 0.2);
    // border-radius: 4px;
    box-sizing: border-box;

    width: 5.5rem;
    height: 28px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &_holder {
    margin-left: 9.2rem;
    margin-right: 9.2rem;
    border-radius: 10px;

    @media (max-width: 767px) {
      margin: 0rem;
    }

    @include respondM(tablet) {
      background-color: #fff;
      // margin-left: 1rem;
      // margin-right: 1rem;
      // padding: 2rem
    }

    @include respondM(desktop) {
      background-color: #fff;
      // padding: 2rem
    }
  }

  &_detail_layout {
    padding: 0 2rem;
    position: relative;

    @include respondM(tablet) {
      padding: 0;
    }
  }

  &_detail_options {
    margin-top: 2.5rem;

    @include respondM(tablet) {
      -ms-overflow-style: none;
      scrollbar-width: none;
      max-height: 55vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &_btn_save_addmore {
    // margin-right: 9rem;
    // max-width: 10rem;
    font-size: 0.9rem;
  }

  &_heading_order {
    margin-left: -2rem;
  }

  &_heading_item {
    padding-left: 3.5rem;
  }

  &_price_peritem {
    padding-left: 1.1rem;
  }

  &__available_from {
    margin-left: 1.7rem;
  }

  &_heading_active {
    margin-left: 0.2rem;
  }

  &_heading_unit {
    margin-left: 2.2rem;
  }

  &_heading_actions {
    margin-left: 2.5rem;
  }

  &_col_price_peritem {
    margin-left: 1.3125rem;
  }

  &_detail_options_modal {
    margin-top: 0.5rem;
    width: 100%;
    max-height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;

    @include respondM(tablet) {
      -ms-overflow-style: none;
      scrollbar-width: none;
      max-height: 40vh;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__title {
    @include respondM(tablet) {
      font-size: 20px;
    }
  }

  &_header {
    background-color: $color-fyndr-blue;
    min-height: 8vh;
  }

  &_body {
    // padding: 0 2rem;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  &_body_updated {
    // padding: 0 2rem;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  &_search {
    border-radius: 10px;
    border-color: $color-fyndr-blue;
  }

  &_search_updated {
    border-radius: 5;
    border-color: "#eee";
  }

  &_row {
    width: 15rem;
    max-width: 100%;
    border-radius: 5px;
    min-height: 10vh;
    max-height: 10vh;
    object-fit: cover;

    @include respondM(tablet) {
      max-width: 100%;
    }
  }

  &_row_new {
    width: 6rem;
    max-width: 6rem;
    padding: 0.5rem;
    height: 3rem;
    object-fit: contain;

    @include respondM(tablet) {
      max-width: 100%;
    }
  }

  &_row_text {
    max-block-size: 12vh;
    // height: 10rem;
    overflow: hidden;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @include respondM(tablet) {
      max-block-size: 15vh;
      //
    }
  }

  &_row_text_updated {
    max-block-size: 12vh;
    color: #454545;
    // height: 10rem;
    overflow: hidden;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @media (min-width: 1920px) {
      font-size: 1.2vh;
    }

    @include respondM(tablet) {
      max-block-size: 15vh;
      //
    }
  }

  &_price_title {
    @include respondM(tablet) {
      font-size: 18px;
    }
  }

  &_detail_page {
    min-width: 90vw;
    max-width: 90vw;
    height: 25vh;
    object-fit: cover;
    border-radius: 1rem;
    margin-bottom: 2rem;

    @include respondM(tablet) {
      max-width: 100%;
      min-width: 100%;
      height: 30vh;
    }

    &_cart_card {
      position: fixed;
      bottom: 0;
      height: 25vh;
      background: #f9f9f9;
      width: 100%;
      border-top: 0.5px solid $color-fyndr-blue;
      padding: 0 2rem;
      max-width: 100%;
      z-index: 9999999;
      overflow: scroll;

      @include respondM(tablet) {
        display: none;
      }
    }

    &_inner_image {
      max-width: 90%;
      min-width: 90%;
      // max-height: 10vh;
      min-height: 10vh;
      border-radius: 0.5rem;
      background-size: cover;

      @include respondM(tablet) {
        max-width: 50%;
      }
    }
  }

  &_category_card {
    width: 27rem;
    max-width: 27rem;
    // maxHeight:150,
    min-height: 12.5rem;
    border-radius: 0.6rem;
    padding: 1rem;
  }

  &_category_image {
    max-height: 12.5rem;
    min-height: 12.5rem;
    // minWidth:'25rem',
    max-width: 25rem;
    object-fit: cover;
  }

  &_category_skeleton_loader {
    width: 20rem;
    height: 20rem;
  }

  // &_item_name {
  //     color: #454545;
  //     font-size: 1.4rem;
  //     padding-bottom: 2rem
  // }

  // &_item_list {
  //     padding: 0 2rem 2rem 2rem;
  //     width: auto;
  //     max-width: 100vw
  // }

  &_checkout-step3-bussName {
    font-weight: 500;
    font-size: 1.5rem;
    color: #3a3a3a;
    margin-top: 1rem;
  }

  &_checkout-step3-heading {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #3a3a3a;
  }

  &_checkout-step3-itenNameDetails {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;

    color: #3a3a3a;
  }

  &_checkout-step3-itemNameDetails-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;

    color: #3a3a3a;
  }

  &_checkout-step3-bussDetails {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    display: flex;
    justify-content: end;
    margin-left: 1rem;
    color: #3a3a3a;
  }

  &_checkout-step3-productDetails {
    width: 93%;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    border-radius: 10px;
  }

  &_checkout-step3-data {
    width: 100%;
    min-height: 4.375rem;
    left: 0px;
    top: 0px;
    vertical-align: middle;
    border-radius: 10px;
    background: #f4f8fd;
    padding: 10px 0px 10px 24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 12px;

    @media (max-width: 576px) {
      padding: 0px 0px 0px 12px;
    }
  }

  &_checkout-step3-appoint {
    width: 100%;
    left: 0px;
    top: 0px;
    border-radius: 10px;
    padding: 0px 24px 0px 24px;
    margin-bottom: 12px;
  }

  &_checkout-step3-productDetails-mobile {
    width: 92%;

    background: #f4f8fd;
    border-radius: 10px;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &_step3-calculation {
    font-weight: 400;
    font-size: 1.25rem;
    color: #3a3a3a;
  }

  &_step3-calculation-service {
    font-weight: 400;
    font-size: 1.25rem;
    color: #3a3a3a;
    margin-bottom: 1.5rem;
  }

  &_step3-calculation-mobile {
    font-weight: 400;
    font-size: 1.5rem;
    color: #3a3a3a;
    margin-bottom: 1.5rem;
  }

  &_step3-amountCalc {
    display: flex;
    justify-content: end;
    margin-left: 0.5rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;

    color: #3a3a3a;
  }

  &_step3-amountCalc-mobile {
    display: flex;
    justify-content: end;
    margin-left: 0.5rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;

    color: #3a3a3a;
  }

  &_step3-deleviryDay {
    width: 4.562rem;
    height: 1.625rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;

    color: #3a3a3a;
  }

  &_ste3-buttonTip {
    position: relative;
    display: inline-block;
    height: 10px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 30px;
    background: #7e1b1b;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left-width: 0;
    cursor: pointer;
  }

  &_step3-serviceDropdown {
    background: #f4f8fd;
    border-radius: 10px;
    width: 70%;
  }

  &_step3-verticalDivider {
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 6.5rem;
    margin-bottom: 2rem;
  }

  &_step3-verticalDivider-mobile {
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 10rem;
    margin-bottom: 2rem;
  }

  &_step3-selectDeleveryOption
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #f4f8fd;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 3.125rem;
    align-items: center;
  }

  &_step3-selectDeleveryOption-mobile
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-sizing: border-box;

    width: 13rem;
    height: 3.125rem;
    left: 0px;
    top: 0px;

    background: #f4f8fd;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  &_step3-deleviryTime
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #f4f8fd;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    align-items: center;
    width: 23rem;
    height: 3.125rem;
  }

  &_step3-deleviryTime-mobile
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-sizing: border-box;
    width: 17rem;
    height: 32px;
    background: #f4f8fd;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  &_step3-abc {
    background: #f4f8fd;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 3.125rem;
  }

  &_step3-instruction-mobile {
    box-sizing: border-box;

    width: 351px;
    height: 32px;

    background: #f4f8fd;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &_step3-payBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    width: 28.75rem;
    height: 2.875rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: #257cdb;
    border-radius: 5px;
  }

  &_step3-payBtn-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: #257cdb;
    border-radius: 5px;
    width: 261px;
    height: 36px;
  }

  &_step3-minusBtn {
    font-weight: 400;
    text-align: center;
    background-image: none;
    border: 0px solid transparent;

    cursor: pointer;
  }

  &_step3-minusBtn-mobile {
    font-weight: 400;
    background-image: none;
    border: 0px solid transparent;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  &_step3-addBtn {
    font-weight: 400;
    text-align: center;
    background-image: none;
    border: 0px solid transparent;
    margin-left: 1rem;
    cursor: pointer;
  }

  &_step3-addBtn-mobile {
    margin-bottom: 1rem;
    background-image: none;
    border: 0px solid transparent;
    margin-left: 0.5rem;

    cursor: pointer;
  }

  @media screen and (min-width: 539px) and (max-width: 767px) {
    &_step3-deleviryTime
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #f4f8fd;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      align-items: center;
      width: 15rem;
      height: 3.125rem;
    }

    &_step3-verticalDivider {
      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 6.5rem;
      margin-bottom: 2rem;
      margin-left: 3rem;
      margin-right: 3rem;
    }

    &_step3-selectDeleveryOption
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #f4f8fd;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      height: 3.125rem;
      align-items: center;
    }

    &_step3-calculation-service {
      font-weight: 400;
      font-size: 1.25rem;
      color: #3a3a3a;

      margin-left: 0rem;
      margin-bottom: 1.5rem;
    }

    &_step3-serviceDropdown {
      background: #f4f8fd;
      border-radius: 10px;
      width: 100%;
    }

    &_step3-deleviryDay {
      width: 4.562rem;
      height: 1.625rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.625rem;

      color: #3a3a3a;
    }
  }
}

.unitInput {
  @media (min-width: 1024px) {
    padding-left: 1.7vw;
  }
}

.texttext {
  max-block-size: 4;
}

.mainInput {
  ::before {
    opacity: 1;
    color: $color-fyndr-blue;
  }

  ::after {
    opacity: 1;
    color: $color-fyndr-blue;
  }
}

.editUrl_modal .ant-modal-content {
  border-radius: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .editUrl_modal .ant-modal-content {
    border-radius: 0.5rem;
  }
}

.editUrl_modal .ant-modal-header {
  border-radius: 0.5rem;
}

.textOver {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.businessHome {
  background-image: linear-gradient(125deg, #ffffff 0%, #eaf9ff 100%);

  height: 100vh;
  padding: 2rem;

  @include respondM(tablet) {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }

  &_headermain {
    &-title {
      letter-spacing: 0.8rem;
      text-transform: uppercase;
      font-style: italic;
      font-weight: 400;
      color: #3a3a3a;
      font-size: 1.8rem;

      &-sub {
        @extend .businessHome_headermain-title;
        font-size: 1.4rem;
      }

      &-small {
        @extend .businessHome_headermain-title;
        font-size: 1rem;
      }
    }

    border-bottom: 0.5px solid #b8b2b2;
  }

  &_offerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 2rem;
    min-height: 6rem;
    margin-top: 2rem;
    background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);

    h3 {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;
    }

    &:hover {
      transform: translateY(-2px) scaleX(1.02);

      transition: all 0.1s ease-in;
    }

    &:active {
      transform: translateY(2px) scaleX(0.99);
      transition: all 0.1s ease-in;
    }
  }

  &_header {
    background-image: radial-gradient(
      circle 350px at -50% -2%,
      rgba(17, 130, 193, 1) 0%,
      rgb(80, 177, 245) 49%,
      rgb(255, 255, 255) 90.2%
    );

    margin-top: 4rem;
    height: 25vh;
    position: relative;
    -webkit-box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(235, 231, 231);
    -moz-box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(240, 236, 236);
    box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(233, 231, 231);
    border-radius: 2rem;

    color: #3a3a3a;

    @include respondM(tablet) {
    }

    &-img {
      // position: absolute;
      // bottom: 3%;
      // left: 1%;
      width: 100%;
      max-height: 25vh;

      border-radius: 10px;
    }

    &-heading {
      color: #1f2021;
    }
  }
}

.bizHome {
  position: relative;
  min-height: 100vh;
  background-color: rgb(186, 186, 186);

  &-layer {
    background: #232526;
    // /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #232526, #414345);
    /* Chrome 10-25, Safari 5.1-6 */

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    max-height: 50vw;
    width: 100%;
    max-width: 100%;

    &-title {
      position: absolute;
      top: 10%;
      left: 50%;
      color: #fff;
      font-size: 3rem;
      font-weight: 800;
      transform: translate(-50%, -50%);
    }
  }

  &-dpimage {
    transform: translate(10%, -50%);
  }
}

.nuro {
  -webkit-box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  -moz-box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  border-radius: 2rem;
}

.addonDisplay {
  h6 {
    color: #8c8b8b;
  }
}

.image-container {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 34.37% 0 0 0;
  align-items: center;
  justify-content: center;
}

.image-con {
  display: block;
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  margin: 1rem;
}

// &_subheadings {
//   color: #7f7f7f;
//   font-size: 1rem;
//   margin: 0.5rem;
//   font-weight: 400;
// }

// &_img-icons {
//   height: 4rem;
//   width: 6rem;
//   display: flex;
//   border-radius: 30px;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.3);
// }
.catalogue_img-icons-step2 {
  height: 4rem;
  width: 6rem;
  display: flex;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.catalogue_img-icons-step2-mobile {
  height: 6rem;
  width: 6rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

// &_img-share-icons {
//   height: 4rem;
//   width: 4rem;
//   display: flex;
//   border-radius: 50%;
//   justify-content: center;
//   align-items: center;
//   background-color: rgba(0, 0, 0, 0.3);
// }
.catalogue_img-share-icons-step2 {
  height: 4rem;
  width: 4rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.catalogue_banner_btn-step2 {
  border-radius: 0.3125rem;
  height: 3.25rem;
  // padding-top: .5rem;
}

.catalogue_banner_btn-step2:hover {
  color: #257cdb;
  border: 0px solid white;
}

// &_img-icons-div {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   top: 0rem;
//   justify-content: space-between;
//   display: flex;
//   padding: 0.625rem;
// }
.catalogue_img-icons-div-step2 {
  position: absolute;
  bottom: 0;
  right: 11rem;
  top: 7rem;
  justify-content: space-between !important;
  display: flex;
  padding: 0.625rem;
}

.catalogue_banner-img-step2 {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

// &_divider {
//   border: 0.0625rem solid #d3d6e1;
//   width: 90%;
//   margin: 1.5rem;
// }

// &_hidden-scroll-img-mobile {
//   height: 20vh !important;
//   border-radius: 0.625rem;
//   cursor: pointer;
//   border: 1px solid #f0f2f2;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   margin: 1.25rem 1.25rem 0rem 1.25rem !important;
// }

// &_img_div-mobile {
//   margin: 1rem;
//   background-color: white;
//   border-radius: 0.8rem;
//   border: 1px solid rgba(0, 0, 0, 0.1);
// }

// &_company_logo {
//   width: calc(min(8vw, 10rem));
//   height: calc(min(8vw, 10rem));
//   object-fit: contain;
//   border-radius: 50%;
//   border: 1px solid #eee;
//   background-color: #fff;
//   margin: 1rem 0;
// }

// &_company_logo_updated {
//   width: calc(min(10vw, 7.5rem));
//   height: calc(min(8vw, 7.5rem));
//   object-fit: contain;
//   border-radius: 55%;
//   border: 1px solid #eee;
//   background-color: #fff;
//   margin: 0.5rem;
// }

// &_company_logo_updated_mobile {
//   width: 7.5rem;
//   height: 7.5rem;
//   object-fit: contain;
//   border-radius: 55%;
//   border: 1px solid #eee;
//   background-color: #fff;
//   margin: 0.5rem;
// }

// &_company_logo_cat_updated {
//   width: 20rem;
//   max-width: 20rem;
//   height: 10rem;
//   object-fit: contain;
//   border-radius: 0.6rem;
//   border: 1px solid #eee;
//   background-color: #fff;
//   margin: 1rem 0;

//   // // width: 15rem;
//   // max-width: 100%;
//   // border-radius: 5px;
//   // min-height: 10vh;
//   // max-height: 10vh;
//   // object-fit: cover;
// }

// &_company_logo_cat_updated_mob {
//   width: 9rem;
//   max-width: 9rem;
//   height: 9rem;
//   object-fit: contain;
//   border-radius: 0.6rem;
//   border: 1px solid #eee;
//   background-color: #fff;
//   margin: 1rem 0;

//   // max-width: 100%;
//   // border-radius: 5px;
//   // min-height: 10vh;
//   // max-height: 10vh;
//   // object-fit: contain;
// }

// &_input_number_btn {
//   max-height: 3rem;
//   width: 6rem;
// }

// &_holder {
//   @include respondM(tablet) {
//     background-color: #fff;
//     // margin-left: 1rem;
//     // margin-right: 1rem;
//     // padding: 2rem
//   }

//   @include respondM(desktop) {
//     background-color: #fff;
//     margin-left: 10rem;
//     margin-right: 10rem;
//     // padding: 2rem
//   }
// }

// &_detail_layout {
//   padding: 0 2rem;
//   position: relative;

//   @include respondM(tablet) {
//     padding: 0;
//   }
// }

// &_detail_options {
//   margin-top: 2.5rem;

//   @include respondM(tablet) {
//     -ms-overflow-style: none;
//     scrollbar-width: none;
//     max-height: 55vh;
//     overflow-y: scroll;

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }
// }

// &_btn_save_addmore {
//   margin-right: 9rem;
//   max-width: 10rem;
//   font-size: 0.9rem;
// }

// &_heading_order {
//   margin-left: -2rem;
// }

// &_heading_item {
//   padding-left: 3.5rem;
// }

// &_price_peritem {
//   padding-left: 1.1rem;
// }

// &__available_from {
//   margin-left: 1.7rem;
// }

// &_heading_active {
//   margin-left: 0.2rem;
// }

// &_heading_unit {
//   margin-left: 2.2rem;
// }

// &_heading_actions {
//   margin-left: 2.5rem;
// }

// &_col_price_peritem {
//   margin-left: 1.3125rem;
// }

//  &_detail_options_modal {
//    margin-top: 0.5rem;

//    @include respondM(tablet) {
//      -ms-overflow-style: none;
//      scrollbar-width: none;
//      max-height: 55vh;
//      overflow-y: scroll;

//      &::-webkit-scrollbar {
//        display: none;
//      }
//    }
//  }

// &__title {
//   @include respondM(tablet) {
//     font-size: 20px;
//   }
// }

// &_header {
//   background-color: $color-fyndr-blue;
//   min-height: 8vh;
// }

// &_body {
//   // padding: 0 2rem;
//   max-width: 100%;
//   width: 100%;
//   min-width: 100%;
// }

// &_body_updated {
//   // padding: 0 2rem;
//   max-width: 100%;
//   width: 100%;
//   min-width: 100%;
// }

// &_search {
//   border-radius: 10px;
//   border-color: $color-fyndr-blue;
// }

// &_search_updated {
//   border-radius: 5;
//   border-color: "#eee";
// }

// &_row {
//   width: 15rem;
//   max-width: 100%;
//   border-radius: 5px;
//   min-height: 10vh;
//   max-height: 10vh;
//   object-fit: cover;

//   @include respondM(tablet) {
//     max-width: 100%;
//   }
// }

// &_row_new {
//   width: 6rem;
//   max-width: 6rem;
//   padding: 0.5rem;
//   height: 3rem;
//   object-fit: contain;

//   @include respondM(tablet) {
//     max-width: 100%;
//   }
// }

// &_row_text {
//   max-block-size: 12vh;
//   // height: 10rem;
//   overflow: hidden;
//   margin-bottom: 1rem;
//   display: -webkit-box;
//   -webkit-line-clamp: 4;
//   -webkit-box-orient: vertical;

//   @include respondM(tablet) {
//     max-block-size: 15vh;
//     //
//   }
// }

// &_row_text_updated {
//   max-block-size: 12vh;
//   color: #454545;
//   // height: 10rem;
//   overflow: hidden;
//   margin-bottom: 1rem;
//   display: -webkit-box;
//   -webkit-line-clamp: 4;
//   -webkit-box-orient: vertical;

//   @include respondM(tablet) {
//     max-block-size: 15vh;
//     //
//   }
// }

// &_price_title {
//   @include respondM(tablet) {
//     font-size: 18px;
//   }
// }

// &_detail_page {
//   min-width: 90vw;
//   max-width: 90vw;
//   height: 25vh;
//   object-fit: cover;
//   border-radius: 1rem;
//   margin-bottom: 2rem;

//   @include respondM(tablet) {
//     max-width: 100%;
//     min-width: 100%;
//     height: 30vh;
//   }

//   &_cart_card {
//     position: fixed;
//     bottom: 0;
//     height: 25vh;
//     background: #f9f9f9;
//     width: 100%;
//     border-top: 0.5px solid $color-fyndr-blue;
//     padding: 0 2rem;
//     max-width: 100%;
//     z-index: 9999999;
//     overflow: scroll;

//     @include respondM(tablet) {
//       display: none;
//     }
//   }

//   &_inner_image {
//     max-width: 90%;
//     min-width: 90%;
//     // max-height: 10vh;
//     min-height: 10vh;
//     border-radius: 0.5rem;
//     background-size: cover;

//     @include respondM(tablet) {
//       max-width: 50%;
//     }
//   }
// }

// &_category_card {
//   width: 27rem;
//   max-width: 27rem;
//   // maxHeight:150,
//   min-height: 12.5rem;
//   border-radius: 0.6rem;
//   padding: 1rem;
// }

// &_category_image {
//   max-height: 12.5rem;
//   min-height: 12.5rem;
//   // minWidth:'25rem',
//   max-width: 25rem;
//   object-fit: cover;
// }

// &_category_skeleton_loader {
//   width: 20rem;
//   height: 20rem;
// }

// &_item_name {
//     color: #454545;
//     font-size: 1.4rem;
//     padding-bottom: 2rem
// }

// &_item_list {
//     padding: 0 2rem 2rem 2rem;
//     width: auto;
//     max-width: 100vw
// }

// &_row_item_list {
//     cursor: pointer;
//     margin: 1rem 0 1rem 0;
//     min-width: 100%
// }
// }

.texttext {
  max-block-size: 4;
}

.mainInput {
  ::before {
    opacity: 1;
    color: $color-fyndr-blue;
  }

  ::after {
    opacity: 1;
    color: $color-fyndr-blue;
  }
}

.editUrl_modal .ant-modal-content {
  border-radius: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .editUrl_modal .ant-modal-content {
    border-radius: 0.5rem;
  }
}

.editUrl_modal .ant-modal-header {
  border-radius: 0.5rem;
}

.textOver {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.businessHome {
  background-image: linear-gradient(125deg, #ffffff 0%, #eaf9ff 100%);

  height: 100vh;
  padding: 2rem;

  @include respondM(tablet) {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }

  &_headermain {
    &-title {
      letter-spacing: 0.8rem;
      text-transform: uppercase;
      font-style: italic;
      font-weight: 400;
      color: #3a3a3a;
      font-size: 1.8rem;

      &-sub {
        @extend .businessHome_headermain-title;
        font-size: 1.4rem;
      }

      &-small {
        @extend .businessHome_headermain-title;
        font-size: 1rem;
      }
    }

    border-bottom: 0.5px solid #b8b2b2;
  }

  &_offerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 2rem;
    min-height: 6rem;
    margin-top: 2rem;
    background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);

    h3 {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;
    }

    &:hover {
      transform: translateY(-2px) scaleX(1.02);

      transition: all 0.1s ease-in;
    }

    &:active {
      transform: translateY(2px) scaleX(0.99);
      transition: all 0.1s ease-in;
    }
  }

  &_header {
    background-image: radial-gradient(
      circle 350px at -50% -2%,
      rgba(17, 130, 193, 1) 0%,
      rgb(80, 177, 245) 49%,
      rgb(255, 255, 255) 90.2%
    );

    margin-top: 4rem;
    height: 25vh;
    position: relative;
    -webkit-box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(235, 231, 231);
    -moz-box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(240, 236, 236);
    box-shadow: 16px 18px 32px -24px rgba(112, 111, 112, 0.45),
      -61px -58px 32px -60px rgb(233, 231, 231);
    border-radius: 2rem;

    color: #3a3a3a;

    @include respondM(tablet) {
    }

    &-img {
      // position: absolute;
      // bottom: 3%;
      // left: 1%;
      width: 100%;
      max-height: 25vh;

      border-radius: 10px;
    }

    &-heading {
      color: #1f2021;
    }
  }
}

.bizHome {
  position: relative;
  min-height: 100vh;
  background-color: rgb(186, 186, 186);

  &-layer {
    background: #232526;
    // /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #232526, #414345);
    /* Chrome 10-25, Safari 5.1-6 */

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    max-height: 50vw;
    width: 100%;
    max-width: 100%;

    &-title {
      position: absolute;
      top: 10%;
      left: 50%;
      color: #fff;
      font-size: 3rem;
      font-weight: 800;
      transform: translate(-50%, -50%);
    }
  }

  &-dpimage {
    transform: translate(10%, -50%);
  }
}

.getDirectionBtn {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  height: auto;
  font-size: 1rem;
  padding: 8px 16px;
  color: #fff;
  align-items: center;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.getDirectionBtn:hover {
  color: #fff !important;
}

.nuro {
  -webkit-box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  -moz-box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  box-shadow: 10px 9px 10px 3px rgb(164, 164, 164),
    -15px -7px 10px 3px rgba(255, 255, 255, 1);
  border-radius: 2rem;
}

.addonDisplay {
  h6 {
    color: #8c8b8b;
  }
}

.image-container {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 34.37% 0 0 0;
  align-items: center;
  justify-content: center;
}

.image-con {
  display: block;
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.blueclass {
  background-color: #257cdb !important;
}

.image_overlay {
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  position: absolute;
  width: 100%;
  justify-content: center;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  color: #ffffff;
  opacity: 1;
  bottom: 0;
  left: 0;
}

.catalogue_hidden-ScrollDiv {
  overflow: hidden;
  padding: 0rem 0rem 1.25rem 1.25rem;
  width: 100%;
}

.image_overlay-text {
  color: white;
  font-weight: 400;
  font-size: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: block;
}

.catalogue_hidden-scroll {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  // height: 30rem;
  margin-bottom: 1.25rem;
  overflow: auto;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.catalogue_image-desk {
  height: 15vw;
  object-fit: cover;
  border-radius: 0.625rem;
  cursor: pointer;
}

.catalogue_card {
  width: 100%;
  border-radius: 0.625rem;
}

.catalogue_card .ant-card-meta-description {
  color: white;
  font-weight: 400;
  font-size: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.catalogue_hidden-scroll-mobile {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  // max-height: 43rem;
  margin-bottom: 1.25rem;
  overflow: auto;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.catalogue_btn-mobile {
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 0.3rem;
  height: 2.875rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.image-container {
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  width: 100%;
  justify-content: center;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.imageText {
  // height: 12vw;
  margin: 5% !important;
  // border: 1px solid #f0f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  aspect-ratio: 3/2;
  // width: 24.2rem;
  // height: 13.6rem;
  // @media (max-width: 900px) {
  //   width: 21rem;
  //   height: 14rem;
  // }
}

.imageText-step2 {
  aspect-ratio: 3/2;
  margin: 1.5% 2% 1.5% 2% !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  // @media (max-width: 990px) {
  //   height: 18rem;
  //   width: 27rem;
  // }
}

.imageText-step2-mobile {
  aspect-ratio: 3/2;
  margin: 8px 12px !important;
  border: 1px solid #f0f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  // @media (max-width: 425px) {
  //   height: 16rem;
  //   width: 24rem;
  // }
}

.imageTextDiv1 {
  height: 8.6vw;
}

.imageTextDiv1-step2 {
  height: 9.5vw;
}

.imageTextDiv1-step2-mobile {
  height: 21.3vw;
}

.btn-step2 {
  background-color: #257cdb;
  border: 0px solid #257cdb;
  color: white;
  border-radius: 5px;
  // height: 2.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  height: auto;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.btn-step2:hover {
  background-color: #4998ed;
  color: white;
}

.btn-step2:active {
  background-color: #223369;
}

.imageTextDiv2 {
  height: 4.3vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.imageTextDiv2-step2 {
  // height: 4.5vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.imageTextDiv2-step2-mobile {
  // height: 6rem;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.catalogue_bannerMaindiv-step2 {
  box-shadow: inset 0 0 0 2000px rgba(255, 0, 150, 0.3);
  border: 3px solid red;
  height: 100vh;
}

.hoverTextDiv2 {
  height: 2.3vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
}

.imageTextDivText {
  color: white;
  font-weight: 400;
  font-size: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.imageTextDivText-step2 {
  color: white;
  text-align: left;
  font-weight: 400;
  font-size: 1.25rem;
}

.imageTextDivText-step2-mobile {
  color: white;
  text-align: left;
  font-weight: 400;
  font-size: 1.25rem;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.imageTextDivText-mobile {
  color: white;
  font-weight: 400;
  font-size: 18px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.imageTextDiv2-mobile {
  height: 12vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.imageText-mobile {
  // width: 348px;
  // height: 196px;
  margin: 8px 12px !important;
  border: 1px solid #f0f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  aspect-ratio: 3/2;
  // @media (max-width: 425px) {
  //   width: 24rem;
  //   height: 16rem;
  // }
}

.imageTextDiv1-mobile {
  height: 27vw;
}

.cat_head {
  // height: 35rem;
  aspect-ratio: 21/9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cat_head-step2-mobile {
  height: 50vh;
  // aspect-ratio: 16/9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cat_head_overLay {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cat_head_overLay-step2-mobile {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  // border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 800px;
}

.catalogue_summaryRow.ant-row {
  // display: flex;
  // flex-flow: row wrap;
  min-width: 0;
  padding-bottom: 5px;
  // background-color: #7e1b1b !important;
}

.appointmentDetails {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 40vw;
  display: flex;
  height: auto;
  flex-direction: row;
}
.horizontal-scroll-view {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent items from wrapping */
}

.scroll-container {
  display: inline-block; /* Ensure items are laid out in a row */
}

.item {
  display: inline-block;
  width: 200px; /* Set width for each item */
  height: 200px; /* Set height for each item */
  margin-right: 20px; /* Adjust margin between items */
  background-color: #ccc; /* Example background color */
}
