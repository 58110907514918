.fyndrLogo {
  padding-left: 5vh;
  @media (min-width: 768px) and (max-width: 1000px) {
    padding-left: 2.3vh;
  }
}
.bizHome {
  &_store-options {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: #223369;
    width: 20px;
  }

  &_logo {
    padding-top: 0.645rem;
    padding-bottom: 0.645rem;

    @media (min-width: 1920px) {
      padding-bottom: 0rem;
    }
  }

  &_bg-topMenu {
    padding-right: 2.5rem;
    @media (min-width: 3400px) {
      padding-right: 0rem;
    }
    @media (min-width: 1920px) and (max-width:2560px) {
      padding-right: 4rem;
    
    }
    // @media (min-width: 1366px) and (max-width:1440px) {
    //   padding-right: 2rem;
    // }
    @media (min-width: 1280px) and (max-width:1366px) {
      padding-right: 4rem;
    }

    @media (min-width: 991px)and (max-width:1280px) {
      padding-right: 5.5rem;
    }

    @media (min-width: 798px)and (max-width:991px) {
      padding-right: 10.5rem;
    }
    @media (min-width: 768px)and (max-width:798px) {
      padding-right: 12.5rem;
    }
    
  }

  &_bg-topMenu2 {
    padding-right: 3.5rem;

    @media (min-width: 1920px) {
      padding-right: 3rem;
    }
    @media (min-width: 1024px)and (max-width:1280px) {
      // padding-left: 2rem;
    }
    @media (min-width: 991px)and (max-width:1024px) {
      padding-right: 3rem;
    }
    @media (min-width: 768px)and (max-width:991px) {
      padding-right: 2rem;
    }

  }



  &_search-input ,&_search-input2 {
    border: 1px solid #d3d6e1;
    font-weight: 400;
    font-size: 1.125rem;
    border-radius: 5px;
    border-radius: 0.3125rem;
    padding: 1rem 1.25rem;
  }
  &_search-input{
    animation: fade-out linear;
    animation-timeline: view();
    animation-range: exit -150px;
  }

  &_search-input2 {
    @media (min-width: 2200px) {
      padding: 0px 0px 0px 14.5px;
      width: 95% !important;
    }
  }

  @keyframes fade-out {
    to {opacity: 0.5;
     width: 50%;}
  }

  &_search-input input::placeholder {
    font-weight: 400;
    font-size: 1.125rem;
    color: #bdc2d2;
  }

  &_store_options_mobile {
    padding: 1rem;
    max-width: 100%;
    min-width: 50%;
  }

  &_search-btn.ant-btn-primary , &_search-btn2.ant-btn-primary {
    background: #223369;
    border-radius: 5px;
    padding: 0.625rem 1.25rem;
    width: 5.625rem;
    height: 2.875rem;
    font-size: 1rem;
    font-weight: 400;
    border: none;
  }

  &_search-btn2.ant-btn-primary {
    @media (min-width: 2200px) {
      padding: 0;
      height: 1.65rem;
    }
  }


  &_appstore-icon {
    font-size: 2rem;
    color: #fff;
  }

  &_offers-list {
    // min-height: 18rem;
    // max-height: 18rem;
    min-height: 22rem;
    max-height: 22rem;
    cursor: pointer;
    background-color: #f4f8fd;
    border-radius: 5px;
    padding-bottom: 12px;

    // @include respond(mac) {
    //     min-height: 15rem;
    //     // max-width: 19rem;
    //     // min-width: 19rem;
    //     width: 23.5rem;
    //     margin: 1rem;
    //     cursor: pointer;
    //     background-color: rgba(146, 139, 139, 0.132);
    //     border-radius: 5px;

    // }
    @include respond(too-big) {
      // min-height: 21.5rem;
      // max-height: 21.5rem;
      min-height: 25.5rem;
      max-height: 25.5rem;
    }

    @include respond(mac) {
      // min-height: 18rem;
      // max-height: 17rem;

      min-height: 22.5rem;
      max-height: 21.5rem;
    }

    @include respond(small-mac) {
      // min-height: 17.5rem;
      // max-height: 17.5rem;

      min-height: 22rem;
      max-height: 22rem;
    }

    @media (max-width: 1200px) {
      // min-height: 20.5rem;
      // max-height: 20.5rem;

      min-height: 24.5rem;
      max-height: 24.5rem;
    }

    @media (max-width: 992px) {
      // min-height: 21.5rem;
      // max-height: 21.5rem;

      min-height: 25.5rem;
      max-height: 25.5rem;
    }

    @media screen and (min-width: 769px) and (max-width: 850px) {
      // min-height: 25rem;
      // max-height: 25rem;

      min-height: 29rem;
      max-height: 29rem;
    }

    @media (max-width: 768px) {
      // min-height: 25.5rem;
      // max-height: 25.5rem;

      min-height: 29.5rem;
      max-height: 29.5rem;
    }

    @media (max-width: 576px) {
      // min-height: 28rem;
      // max-height: 28rem;

      min-height: 33rem;
      max-height: 33rem;
      border-radius: 7.795px;
    }
  }

  &_offers-list-new {
    min-height: 14rem;
    cursor: pointer;
    border-radius: 5px;
  }

  &_offers-img {
    // max-width: 100%;
    object-fit: cover;

    border-radius: 5px 5px 0px 0px;
    width: 100%;
    min-height: 10.9rem;
    max-height: 10.9rem;

    @include respond(too-big) {
      min-height: 14rem;
      max-height: 14rem;
    }

    @media (max-width: 992px) {
      min-height: 15rem;
      max-height: 15rem;
    }

    @media (max-width: 768px) {
      min-height: 15.5rem;
      max-height: 15.5rem;
    }

    @media (max-width: 576px) {
      min-height: 18rem;
      max-height: 18rem;
      border-radius: 7.795px 7.795px 0px 0px;
    }
  }

  &_selection-row {
    max-width: 100%;
    padding-left: 1.5rem;
  }

  &_selection_col {
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #fff;
  }

  &_offerBtns {
    color: #257cdb;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 24px;
    border-radius: 5px;
    border: 1px solid #257cdb;
    background: transparent;
  }
  &_offerBtns2 {
    height: 48px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 24px;
    border-radius: 5px;
    border: 1px solid #257cdb;
    color:#257cdb ;
  }

  &_display-flex-mob {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5rem;
    border-radius: 3.75px;
    border: 1px solid #257cdb;
    background: transparent;
    color: #257cdb;
  }
  &_display-flex-mob.ant-btn {
    height: 35px;
    padding: 7.5px 15px;
  }

  &_offerBtns.ant-btn {
    height: 46px;
    padding: 10px 20px;
  }

  &_map {
    padding-top: 1rem;
    align-items: center;
  }

  &_location-modal {
    height: auto;
    width: 90%;
  }

  &_footer {
    padding: 1rem;
    background: #fff;
    width: 100%;
    padding-top: 0;
    font-size: 12px;
    align-items: center;
  }

  &_footer-modal {
    color: #1891ff;
  }

  &_footer-contactus-options {
    background: #fff;
    overflow: hidden;
    border-radius: 5;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
  }

  &_range {
    padding-bottom: 0rem;
    margin-top: 1rem;
  }

  &_header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &_render-nodata {
    text-align: center;
    padding: 5rem;
  }

  &_buttons {
    margin-top: 1rem;
    width: 100%;
  }
  &_activebtn {
    @include respond(phone) {
      margin-bottom: 1rem;
    }
  }

  &_search-store {
    border: none;
    padding: 0.438rem;
  }

  &_container {
    min-height: 100vh;
  }

  &_flex_container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/images/test_background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; /* Optionally add this to center the image */
    height: 25rem;
    width: 100%;
  }

&_flex_container_bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
    width: 100%;
  }

  &_content {
    max-width: 100%;
    background-color: #fff;
    min-height: 40rem;
    padding: 1rem;
    border: 1px solid #d3d6e1;

    @include respond(phone) {
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &-display-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
  }

  &-card-image {
    max-width: 100%;
    background-size: cover;
    margin-bottom: 1rem;
    max-height: 10.3rem;
    min-height: 10.3rem;
  }

  &_appstore-icon {
    font-size: 2rem;
    color: #fff;
  }

  &_selection-row {
    max-width: 100%;
    padding-left: 1.5rem;
  }

  &_selection_col {
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #fff;
  }

  &_map {
    padding-top: 1rem;
    align-items: center;
  }

  &_location-modal {
    height: auto;
    width: 90%;
  }

  &_footer {
    padding: 1rem;
    background: #fff;
    width: 100%;
    padding-top: 0;
    font-size: 12px;
    align-items: center;
  }

  &_footer-modal {
    color: #1891ff;
  }

  &_footer-contactus-options {
    background: #fff;
    overflow: hidden;
    border-radius: 5;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
  }

  &_range {
    padding-bottom: 0rem;
    margin-top: 1rem;
  }

  &_header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &_render-nodata {
    text-align: center;
    padding: 5rem;
  }

  &_buttons {
    margin-top: 1rem;
    width: 100%;
  }

  &_search-store {
    border: none;
    padding: 0.438rem;
    @media screen and (min-width: 994px) and (max-width: 1200px){
      right: 23px;
    }
    @media screen and (min-width: 1600px) {
      right: 2vw;
    }
  }

  &_container {
    min-height: 100vh;
  }

  &_content {
    max-width: 100%;
    background-color: #fff;
    min-height: 40rem;
    padding: 1rem;

    border: 1px solid #d3d6e1;
    border-radius: 10px;

    @include respond(phone) {
      padding: 1rem;
    }
  }

  &-display-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &-card-image {
    max-width: 100%;
    background-size: cover;
    margin-bottom: 1rem;
    max-height: 10.3rem;
    min-height: 10.3rem;
  }

  &-display-border {
    margin-top: 1rem;
    border-bottom: solid #f5f6fa;
  }

  &-img-icons {
    object-fit: contain;
    height: auto;
    max-height: 1.5rem;
    width: auto;
    max-width: 1.5rem;
    margin: 1rem;
  }

  &-img-icons {
    object-fit: contain;
    height: auto;
    max-height: 1.5rem;
    width: auto;
    max-width: 1.5rem;
    margin: 1rem;
  }

  &_footer-row {
    text-align: center;
    padding-bottom: 5vh;
    display: flex;
    justify-content: center;
  }
}


.bizHomeDrop_input2 {
 .css-1okebmr-indicatorSeparator {
  width: 0px !important;
}
}

.bizHomeDrop_input{
  .css-1okebmr-indicatorSeparator {
    width: 0px !important;
  }
  @media (min-width: 1600px)  {
    width: 42vw;
    margin-left: -2vw;
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    width: 41vw;
    margin-left: 1.2vw;
  }
  @media (min-width: 994px) and (max-width: 1200px) {
    width: 40vw;
    margin-left: -2vw;
  }
}

.typo {
  &-row {
    // font-family: Helvetica;
    // font-size: 1.5rem;
    // font-style: normal;
    // font-weight: 400;
    // line-height: .9rem;
    letter-spacing: 0em;
    text-align: left;
    color: #223369;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 100%;

    @include respond(too-big) {
      font-size: 28px;
    }

    @include respond(mac) {
      font-size: 27px;
    }

    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  &-card-header {
    display: -webkit-box;
    // max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Helvetica;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0em;
    text-align: left;
    padding: 12px 10px 0px 10px;
    color: #333333;
    display: -webkit-box;
    // max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include respond(too-big) {
      font-size: 35px;
    }

    @media (max-width: 1200px) {
      font-size: 1.4rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 18px;
    }

    @media (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 18px;
    }
  }

  &-card-subheader {
    
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: left;
    color: #666666;
    padding: 0px 10px 0px 10px;

    @include respond(too-big) {
      font-size: 1.1rem;
    }

    @media (max-width: 1200px) {
      font-size: 1.2rem;
    }

    @media (max-width: 786px) {
      font-size: 1.2rem;
      line-height: 18px;
    }

    @media (max-width: 576px) {
      font-size: 1.5rem;
      line-height: 18px;
    }
  }
}

.overFlowHidden::-webkit-scrollbar {
  display: none;
}

.searchBIZ {
  &_iconheart {
    font-size: 1.2rem;
    margin-right: 0.2rem;

    @include respond(phone) {
      font-size: 1.5rem;
      margin-right: 0.2rem;
      margin-top: 12px;
    }
  }

  &_img {
    aspect-ratio: 2/1;
    width: 100%;
    max-width: 100%;
    border-radius: 1rem;
  }

  &_title {
    font-family: Helvetica;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    // line-height: 2.8rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: #454545;

    @include respond(phone) {
      font-size: 1.5rem;
      padding-top: 12px;
      line-height: 20px;
    }
  }

  &_add {
    font-family: Helvetica;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #838383;
    margin-top: 1rem;

    @include respond(phone) {
      font-size: 1.2rem;
    }
  }

  &_distance {
    font-family: Helvetica;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    // line-height: 1.4rem;
    // letter-spacing: 0.02em;
    text-align: left;
    margin-top: 1rem;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }

  &_Faicons {
    font-size: 0.8rem;
    margin-right: 0.5rem;

    @include respond(phone) {
      font-size: 1.5rem;
    }
  }

  &_links {
    font-family: Helvetica;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: #5999f1;
  }
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

.dot {
  height: 15px;
  width: 15px;
  // background-color: #05ff1e;

  border-radius: 50%;
  display: inline-block;
  cursor: pointer;

  &-green {
    background-image: radial-gradient(#0bac18 60%, #0bac18 40%);
  }

  &-red {
    background-image: radial-gradient(#ff2b27 60%, #ac0b0b 40%);
  }
}

.status {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  &-red {
    @extend .status;
    color: #ff2b27;
  }

  &-green {
    @extend .status;

    color: #0bac18;
  }
}

.phoneNumber {
  color: #223369;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;

  @media (max-width: 576px) {
    font-size: 12.8px;
  }
}

.app-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after {
  border-bottom: 3px solid white;
}

.lazy-load-image-background {
  display: flex !important;}

.topBottomScroll {
  position: fixed;
  right: 3vw;
  cursor: pointer;
  @media (min-width: 1300px) and (max-width: 1440px) {
    right:3.4vw;
  }
  @media (min-width: 1600px) and (max-width: 1760px) {
    right:3.4vw;
  }
}