//MEDIA QUERY MANAGER
/*
0-600px - phone
600-900px - tablet
900-1200px- landscape tablet
1200-1800px - normal
1800+ px - big desktop
breakpoint content :
phone
tab-port
tab-land
big-desktop
1em = 16 px
*/
@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint==tab-gen {
    @media (max-width: 48.1em) {
      //747px
      @content;
    }
  }

  @if $breakpoint==tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint==tab-mid {
    @media (max-width: 59.3em) {
      //1069px
      @content;
    }
  }

  @if $breakpoint==tab-large {
    @media (max-width: 66.81em) {
      //1069px
      @content;
    }
  }

  @if $breakpoint==tab-pro {
    @media (max-width: 65em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint==tab-land {
    @media (max-width: 81em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (max-width: 89em) {
      //1450px
      @content;
    }
  }

  @if $breakpoint==big-desktop {
    @media (max-width: 112.5em) {
      //1800px
      @content;
    }
  }

  @if $breakpoint==hdpi {
    @media (max-width: 121em) {
      //1800px
      @content;
    }
  }

  @if $breakpoint==small-mac {
    @media (max-width: 137.5em) {
      @content;
    }
  }

  @if $breakpoint==mac {
    @media (max-width: 160em) {
      //2500x 137.5 187.5

      @content;
    }
  }

  @if $breakpoint==too-big {
    @media (min-width: 161em) {
      //2500x 137.5 187.5

      @content;
    }
  }

  @if $breakpoint==sm {
    @media (min-width: 576px) {
      @content;
    }
  }

  @if $breakpoint==xs {
    @media (max-width: 576px) {
      @content;
    }
  }
}

@mixin respondM($breakpoint) {
  @if $breakpoint==mac {
    @media (min-width: 160em) {
      //2500x 137.5 187.5

      @content;
    }
  }

  @if $breakpoint==desktop {
    @media (min-width: 89em) {
      //1450px
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media (min-width: 768px) {
      //1450px
      @content;
    }
  }
}

// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;

  @each $key, $num in $map {
    @if $prev-num==null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num>=$num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }

    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);

  @if $first-value !=0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// Color contrast
@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >=$yiq-contrasted-threshold) {
    @return $yiq-text-dark;
  } @else {
    @return $yiq-text-light;
  }
}

// Retrieve color Sass maps
@function color($key: "blue") {
  @return map-get($colors, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

@function gray($key: "100") {
  @return map-get($grays, $key);
}

// Request a theme color level
@function theme-color-level($color-name: "primary", $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}
