.promotion {
  &_LoCcard {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  &_LoCcard .ant-card {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #d3d6e1;
  }
  &_locBtn {
    width: 100% !important;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #d3d6e1;
  }
  &_totalVisitors {
    width: 130px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d3d6e1;
  }
  &_totalDelete {
    width: 140px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #153dce;
  }
  &_locAdd {
    width: 165px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #1943dd;
  }
  &_addressText {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
  }
  &_miles {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #7f7f7f;
  }
  &_visitedCustomer {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  &_checkbox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #d3d6e1;
  }
  &_vCount {
    width: 154px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d3d6e1;
  }
  &_deleteVcount {
    width: 154px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    background: #257cdb;
    border: 1px solid #cccccc;
  }
  &_proceedPay {
    width: 154px;
    height: 46px;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    background: #257cdb;
    border: 1px solid #cccccc;
  }
  &_countBtn {
    @media (max-width: 1024px) {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  &_del {
    @media (max-width: 1024px) {
      display: flex;
      justify-content: center;
    }
  }

  &_loc {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1280px) {
      display: flex;
      justify-content: center;
      
    }
  }
  &_countAll {
    @media (max-width: 1280px) {
      display: flex;
      justify-content: center;
      padding-bottom: 5px;
    }
  }

  &_modalPop {
    border: 1px solid #d3d6e1;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
  }
  &_recipients {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;

    color: #4d4d4d;
  }
  &_target {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #4d4d4d;
  }
  &_fyndrCheck {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #4d4d4d;
  }
  &_fyndrcharge {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #257cdb;
  }
  &_featuredCharges {
    border: 1px solid #d3d6e1;
    width: 100%;
    border-radius: 10px;
    padding: 12px;
    margin-top: 12px;
  }
  &_monthsSubs {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: #999999;
  }
  &_priceSubs {
    //styleName: UI/B3/Reg-400;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: #257cdb;
  }
  &_EmailSender {
    width: 118px;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #257cdb;
    color: #257cdb;
  }
  &_mobileNoti {
    width: 202px;
    height: 40px;
    padding: 10px 24px 10px 24px;
    border-radius: 8px;
    border: 1px solid #257cdb;
    color: #257cdb;
  }
  &_payBtn {
    width: 140px;
    height: 40px;
    // padding: 10px 23px 10px 23px;
    border-radius: 8px;
    background: #257cdb;
    border: 1px solid #257cdb;
  }
  &_datePicker {
    width: 220px;
    height: 46px;
    padding: 11px 12px 11px 12px;
    border-radius: 10px;
    border: 1px 0px 0px 0px;
  }
  &_calendar{
    width: 24px;
    height: 24px;
  }
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-label {
  position: absolute;
  top: -13px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  font-size: 12px;
  color: #999;
}
.admin-confirm-modal .ant-modal-close-x{
  width: 40px;
}
.admin-confirm-modal .ant-modal-footer {
  padding: 10px 16px;
  border-top: 0px solid black;
}
