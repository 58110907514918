.app-layout {
  &.ant-layout {
    // make sidenav full height
    align-items: stretch;
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
}