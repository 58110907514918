.searchboxinput{
    font-family: 'Roboto';
    font-style: 'normal';
   // width:78.125rem;
    width:82rem;
    margin-left:13.2rem;
    padding-left:1.7rem;


    // marginLeft:"-52rem"
    
}