.redeem {
    padding-top: .25rem;
    padding-bottom: 1.5rem;
    font-size: 14px;
    font-weight: 400;

    &_span {
        border-radius: 50%;
        height: 1rem;
        width: 1rem;
        align-self: center;
        margin-right: .5rem;
    }

    &_sold {

        @include respond(tab-port) {
            margin-top: 12px;
        }
    }
}

.redeem_table {
    background-color: #fff;
    width: 100%;

    @include respond(phone) {
        overflow: auto;
    }

    @include respond(tab-port) {
        overflow: auto;
    }

    @include respond(desktop) {
        overflow: auto;
    }
}

.redeem_table .ant-table-title {
    background-color: #3f9af8;
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
}

.redeem_table ::-webkit-scrollbar {
    background: none;
}

.redeem_table ::-webkit-scrollbar-thumb {
    background: #3f9af8;
}

.redeem_table .ant-table-thead .ant-table-cell {
    background-color: #3f9af8;
    color: white;
    text-align: left;
}

.redeem_table .ant-table-column-sorters::after {
    background-color: #3f9af8;
}

.redeem_table .ant-table-column-sorter-inner {
    display: none;
}

.redeem_table .ant-table-cell {
    background-color: #F4F8FD;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

.notfound {
    &_col {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: start;
        // align-self: center;
        // padding-left: 6rem;
        // height: 100vh;
        // background-image: url("../../../assets/images/imgggg.svg");
        // background-repeat: no-repeat;
        // background-size: contain;

        @include respond(tab-pro) {
            display: none;
        }

        @include respond (phone) {
            display: none;
        }
    }

    &_colPhone {
        display: none;
        width: 100%;

        @include respond(tab-land) {
            display: flex;
            // justify-content: center;
            // flex-direction: column;
            // align-items: center;
            // text-align: center;
            height: 50vh;
            // background-image: url("../../../assets/images/lsidemob.svg");
            // background-repeat: no-repeat;
            // background-size: contain;
        }

        @include respond(tab-pro) {
            display: flex;
            // justify-content: center;
            // flex-direction: column;
            // align-items: center;
            // text-align: center;
            height: 50vh;
        //     background-image: url("../../../assets/images/lsidemob.svg");
        //     background-repeat: no-repeat;
        //     background-size: contain;
        }

        @include respond(tab-port) {
            display: flex;
            // justify-content: center;
            // flex-direction: column;
            // align-items: center;
            // text-align: center;
            height: 60vh;
            // background-image: url("../../../assets/images/lsidemob.svg");
            // background-repeat: no-repeat;
            // background-size: contain;
        }

        @include respond(phone) {
            display: flex;
            // justify-content: center;
            // justify-content: center;
            // flex-direction: column;
            // align-items: center;
            // text-align: center;
            height: 55vh;
            // background-image: url("../../../assets/images/imgggg.svg");
            // background-repeat: no-repeat;
            // background-size: contain;

        }
    }

    &_para {
        font-size: 1.5rem;
        font-weight: 500;
        color: #333333;

        @include respond(tab-land) {
            font-size: 24px;
            line-height: 1.5rem;
        }

        @include respond(tab-pro) {
            line-height: 2.5rem;
            font-size: 24px;

        }

        @include respond(tab-port) {
            line-height: .8rem;
            font-size: 24px;

        }

        @include respond (phone) {
            font-weight: 400;
            font-size: 16px;
        }
    }

    &_para1 {
        font-weight: 500;
        color: #333333;

        @include respond(tab-land) {
            margin-bottom: 2.5rem;
            line-height: .8rem;
            font-size: 2rem;
        }

        @include respond(tab-pro) {
            margin-bottom: 2.5rem;
            line-height: .8rem;
            font-size: 24px;
        }

        @include respond(tab-port) {
            margin-bottom: 2.5rem;
            line-height: .8rem;
            font-size: 24px;
        }

        @include respond (phone) {
            margin-bottom: 1.2rem;
            line-height: 1rem;
            font-weight: 400;
            font-size: 16px;
        }
    }

    &_para2 {
        font-weight: 500;
        color: #333333;


        @include respond(tab-land) {
            margin-bottom: 3.3rem;

            line-height: 2rem;
            font-size: 2rem;
        }

        @include respond(tab-pro) {
            margin-bottom: 3.3rem;

            line-height: 2rem;
            font-size: 24px;
        }

        @include respond(tab-port) {
            margin-bottom: 3.3rem;

            line-height: 2rem;
            font-size: 24px;
        }

        @include respond (phone) {
            margin-bottom: 2rem;
            line-height: 2.5rem;
            font-weight: 400;
            font-size: 16px;
        }
    }

    &_strong {

        @include respond(tab-land) {
            font-size: 30px;
        }

        @include respond(tab-pro) {
            font-size: 30px;
        }

        @include respond(tab-port) {
            font-size: 30px;
        }

        @include respond (phone) {
            font-weight: 600;
            font-size: 24px;
        }

    }

    &_btn {
        font-size: 30px;
        width: 200px;
        border-radius: 10px;

        @include respond(tab-land) {
            font-size: 20px;
            width: 200px;
        }

        @include respond(tab-pro) {
            font-size: 20px;
            width: 200px;
        }

        @include respond(tab-port) {
            font-size: 20px;
            width: 200px;
        }

        @include respond (phone) {
            width: 180px;
            font-size: 16px;
        }
    }

    &_row {
        display: flex;
        background-size: cover;
        align-items: center;

        @media (max-width: 1200px) {
            // background-size: contain;
            display: flex;
        }
        @media (max-width: 992px) {
            display: none;
        }
        @media (max-width: 768px) {
            display: none;
        }
        @media (max-width: 576px) {
            display: none;
        }

    }

    &_rightcol {
        // background-image: url("../../../assets/images/rside.svg");
        // background-repeat: no-repeat;
        // background-size: contain;
        // background-position: right;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: end;

        @include respond(tab-pro) {
            display: none;
        }

        @include respond (phone) {
            display: none;
        }
    }

    &_img {
        display: none;

        @include respond(tab-land) {
            padding-top: 25rem;
            display: flex;
        }

        @include respond(tab-pro) {
            padding-top: 25rem;
            display: flex;
        }

        @include respond(tab-port) {
            padding-top: 25rem;
            display: flex;
        }

        @include respond(phone) {
            padding-top: 10rem;
            display: flex;

        }

    }

    &_rightcolPhone {
        display: none;
        // @include respond (desktop){
        //     display: none;
        // }

        @include respond(tab-land) {
            // background-image: url("../../../assets/images/rside.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
            height: 50vh;
        }

        @include respond(tab-pro) {
            // background-image: url("../../../assets/images/rside.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
            height: 50vh;
        }

        @include respond(tab-port) {
            // background-image: url("../../../assets/images/rside.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
            height: 50vh;
        }

        @include respond (phone) {
            // background-image: url("../../../assets/images/rside.svg");
            // background-repeat: no-repeat;
            // background-size: contain;
            background-position: right;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: end;
            height: 50vh;
        }
    }
}