.wallet {
  &_content {
    width: 100%;
    background-color: #fff;
    min-height: 40rem;
    border: 1px solid #d3d6e1;
    border-radius: 10px;

    @include respond(phone) {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &_row {
    background: #223369;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 1.5rem;
  }

  &_text {
    font-size: 20px;
    font-weight: 400;
    line-height: 100%;
    align-self: center;
    margin-left: 0.75rem;
  }

  &_copy {
    border-radius: 100px;
    width: auto;
    padding: 0.75rem 2rem 0.75rem 1.25rem !important;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    background: var(--secondary-90, #394878);
    margin: 1rem 0rem;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      padding: 1rem 3rem 1rem 3rem !important;
      text-align: center;
    }

    @media (max-width: 576px) {
      padding: 1rem 2.5rem 1rem 2.5rem !important;
    }
  }

  &_copy-success {
    border-radius: 100px;
    padding: 1.25rem;
    border: 1px dotted #32871e;
    background: green;
    margin: 1rem 0rem;
    align-items: center;
    justify-content: center;
  }

  &_heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding: 1.8rem 1.5rem 0rem 1.5rem;
  }

  &_amount {
    font-size: 6vh;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 1rem;
    @media (min-width: 1680px) {
      font-size: 4.5vh;
    }
    @media (max-width: 1000px) {
      font-size: 4.5vh;
    }
    @media (max-width: 576px) {
      font-size: 5vh;
    }
  }

  &_refer {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 5px;
    color: #fff;
  }

  &_workhow {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  &_workunder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_activity {
    color: var(--Back-100, #1d1d1f);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 0rem 0rem 1rem 1.5rem;
  }
}

.wallet_divider.ant-divider {
  border-top: 1px solid #ddd !important;
}

.walletRow {
  height: auto;
  display: flex;
  padding: 1.7vh;
  align-items: center;
  background-color: var(--blue-05, #f4f8fd);
  width: 100%;
  border-radius: 5px;
  // margin-top: 5px;
  // margin-bottom: 16px;
  border-bottom: 1px dashed #d3d6e1;
  cursor: pointer;
}
.walletRow2 {
  height: auto;
  display: flex;
  padding: 1.7vh;
  align-items: center;
  background-color: var(--blue-05, #fff);
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  // margin-bottom: 16px;
  border-bottom: 1px dashed #d3d6e1;
}
.walletDate {
  color: var(--Black-80, #333);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.walletText {
  color: var(--Black-80, #333);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.walletMoney {
  color: var(--Red, #ed0c10);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mainEnterpriseChallengeDottedLine {
  padding: 0.3rem 0rem;
  padding-left: 0.7rem;
}

.previewUrlDivider.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 12px 0;
}
.offer_list_contactList.ant-btn {
  padding: 0px 1px;
  cursor: pointer;
}
.offer_list_contactList{
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding: 4px 15px 4px 0;

  @include respond(phone){
    font-size:1.3rem;
    border-right-color: #D3D6E1;
    border-right-width: 1px;
  }
}

.likeIcon_Mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width:576px) {
    
    display: block;
    align-items: center;
    justify-content: center;
  }

}
.heart_length{
  margin-left: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  
  @include respond(phone) {
    font-size: 1.5rem;
  display: block;
  align-items: center;
    margin-left: 0rem;
  }
}
.heart_nolength{
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width:576px) {
    margin-left: 0rem;
    font-size: 1.2rem;
  }
}
