.fixed-header {
  .ant-layout { // apply to all .ant-layout Class
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
   
  }
}
