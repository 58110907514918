.footer-new {
  padding: 12px 24px 12px 24px;
  background-color: #1d1d1f;
  margin-top: 1.5rem;
  @media (max-width: 500px) {
    padding: 8px 12px 8px 12px;
  }
}
.footer-fyndr-logo{
    height: 50px;
    width: 40px;
    @media (max-width: 768px) {
        height: 40px;
        width: 35px;
    }
    @media (max-width: 500px) {
        height: 30px;
        width: 20px;
    }
}
.footer-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  color: #d3d6e1;
  margin-left: 5px;
  @media (max-width: 1000px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    font-size: 9px;
  }
  @media (max-width: 500px) {
    line-height: 10px;
    font-size: 7px;
  }
}

.outer-container-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.inner-icon-text-div{
  display: flex;
  align-items: end;
  @media (max-width: 700px) {
    align-items: center;
  }
}
.rights-reserved-span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #d3d6e1;
  @media (max-width: 768px){
    font-size: 8px;
  }
}


.insta-facebook{
    height: 20px;
    width: 20px;
    @media (max-width: 768px){
        height: 15px;
        width: 15px;
    }
}
.footer-icons{
    height: 20px;
    width: 20px;
    @media (max-width: 768px) {
        height: 19px;
        width: 19px;
    }
    @media (max-width: 500px) {
      height: 0;
      width: 0;
    }
}

