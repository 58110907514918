.transaction {
    &_info {
        margin-top: .5rem;
    }

    &_offer-sold {
        margin-bottom: 2rem;
    }

    &_by-month {
        margin-left: 1rem;
    }

    &_details {
        margin-top: 1rem;
    }

    &_pending {
        margin-bottom: 2rem;
    }

    &_paid {
        margin-bottom: 2rem;
    }

    &_page {
        margin-top: 1rem;
    }

    &_receivables {
        cursor: pointer;
    }

    &_selection-row {
        margin-bottom: 1rem;
    }

    &_invoice-details {
        width: 100%;
        border: 1px solid #D3D6E1;
        border-radius: 10px;
        padding: 24px;
    }

    &_invoice-item-heading {
        margin: 1rem 0 0 0
    }

    &_invoice-item-details {
        margin: .5rem 0 0 0;
    }

    &_invoice-img {
        object-fit: contain;
        max-width: 10rem;
    }
}

.invoice {
    &_print {
        border: 1px solid #257CDB;
        border-radius: 10px;
        height: 46px;
        color: #257CDB;
        width: 100%;
        background-color: #F4F8FD
    }

    &_dispute {
        border: 1px solid #ED0C10;
        border-radius: 10px;
        height: 46px;
        color: #ED0C10;
        width: 100%;
        background: white;

        &:hover {
            border: 1px solid #ED0C10;
            color: #ED0C10;
            background: white;


        }

        &:active {
            border: 1px solid #ED0C10;
            color: #ED0C10;
            background: white;

        }

    }
}

.order-tablebtn {
    border-radius: 1.2rem;
    color: #3F9AF8;
    width: 6rem;
    text-align: center;
    padding: .3rem .5rem;
}

.order-btn {
    color: white;
    background-color: #3F9AF8;
}

.order-plusicon {
    align-items: center;
}

.orders-table .ant-table-thead .ant-table-cell {
    background-color: #3F9AF8;
    color: white;
    text-align: center;
}

.orders-table {
    width: 100%;

    @include respond(phone) {
        overflow: auto;
    }

    @include respond(tab-port) {
        overflow: auto;
    }

    @include respond(desktop) {
        overflow: auto;
    }
}

.orders-table .ant-table-cell {
    background-color: white;
    text-align: center;
}

.orders-input {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
}

.orders-actiondrpdwn {
    color: #3F9AF8;
}

.dropdownRed .ant-select-selection-item {
    color: red
}

.dropdown .ant-select-selection-item {
    color: green
}

.dropdown {
    width: 100%;
    border-radius: 1rem
}

.pay-btn {
    display: flex;
    justify-content: center;
}

.transaction_raiseDisputeModal .ant-modal-title {
    font-size: 20px;
}