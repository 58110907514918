@import 'antd/dist/antd.css';

.card {
    width: 85%;
    overflow-y: hidden;
    max-height: 20rem;
    height: 17.2rem;
    margin-bottom: 2rem;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.17);
    border-radius: 2rem;
    background-color: rgb(255, 255, 255);
}

.card-revenue{
    width: 85%;
    overflow-y: hidden;
    max-height: 20.5rem;
    height: 20.5rem;
    margin-bottom: 2rem;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.17);
    border-radius: 2rem;
    background-color: rgb(255, 255, 255);
}

.card-revenue .ant-card-head {
border-bottom: none;
}

.card-row-text {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    color: black;
}
.card .ant-card-body {
    background-color: #fff;
}

.card-divider {
    background: #3F9AF8;
    margin-top: -2.5rem;
}

.card-divider-revenue{
    margin-top: -3rem;
    background: #3F9AF8;
}
.card-divider .ant-divider-horizontal {
    margin: 24px 0 !important;
}

.card-divider-revenue .ant-divider-horizontal {
    margin: 24px 0 !important;
}

.card-title {
    color: #3F9AF7;
    font-family: Helvetica;
    font-style: normal
}
.card .ant-card-head-title{
    padding :16px 0
}
.dropdown-country .ant-select .ant-select-selector {
    border-radius: 5rem;
}