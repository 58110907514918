.purchase-customize_modal {
  position: relative;

  .horizontal-bar {
    width: 110%;
    height: 38px; 
    background-color: #f0f0f0;
    position: absolute;
    top: -17px;
    left: -31px;
    z-index: -1;
    border-radius: 100px;
    @media (max-width: 560px) {
      height: 32px;
      left: -17px;
    }
  }
    .ant-modal-body{
        padding: 0;
    }
    .header {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: 0 0;
  
      .header-image {
        max-width: 100%;
        height: auto;
      }
    }
  
    .detail-row {
      padding: 20px;
    }
  
    .detail {
      text-align: center;
    }
  
    .campaign-container {
      padding: 20px;
    }
  
    .offer {
      display: flex;
      align-items: center;
      padding: 10px 0;
  
      .offer-image {
        border-radius: 10px;
        margin-right: 20px;
      }
  
      .purchase-offer-details {
        padding-left: 20px;
        font-family: Roboto;
        font-weight: 400;
      }
    }
  
    .summary-container {
      padding:2px 20px;
  
      .summary-item {
        padding: 5px 0;
      }
  
      .total-amount {
        padding: 10px 0;
        font-size: 16px;
        color: #000000;
        font-weight: 500;
      }
    }
  
    .purchase-button {
      margin: 20px;
      width: 144px;
      height: 46px;
      border-radius: 10px;
    }
  }

  .bold-span {
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .header-span {
    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .zigzag-border {
    position: relative;
    background: #F7F7F7;
    padding-bottom: 57px;
    border-radius: 100px 100px 0px 0px ;
}

.zigzag-border:after {
    background: linear-gradient(-45deg, grey 16px, transparent 0), linear-gradient(45deg, grey 16px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 32px;
}
