// social buttons
.btn-social {
  @include social-btn-variant($gray-600);
}

.btn-twitter {
  @include social-btn-variant(#00c7f7);
}

.btn-google {
  @include social-btn-variant(#00c7f7);
}

.btn-facebook {
  @include social-btn-variant(#335397);
}

.btn-gplus,
.btn-google-plus {
  @include social-btn-variant(#dd4a38);
}

.btn-instagram {
  @include social-btn-variant(#82685a);
}

.btn-vimeo {
  @include social-btn-variant(#63879c);
}

.btn-flickr {
  @include social-btn-variant(#0061db);
}

.btn-github {
  @include social-btn-variant(#3b3b3b);
}

.btn-pinterest {
  @include social-btn-variant(#d73532);
}

.btn-tumblr {
  @include social-btn-variant(#586980);
}

.btn-linkedin {
  @include social-btn-variant(#018faf);
}

.btn-dribbble {
  @include social-btn-variant(#ea73a0);
}

.btn-stumbleupon {
  @include social-btn-variant(#ea4b24);
}

.btn-lastfm {
  @include social-btn-variant(#b80638);
}

.btn-evernote {
  @include social-btn-variant(#3bab27);
}

.btn-skype {
  @include social-btn-variant(#00b0f6);
}

.btn-soundcloud {
  @include social-btn-variant(#0066ff);
}

.btn-behance {
  @include social-btn-variant(#0057ff);
}

.btn-rss {
  @include social-btn-variant(#f79638);
}

.btn-youtube {
  @include social-btn-variant(#cc181e);
}

.btn-wechat {
  @include social-btn-variant(#4caf50);
}

.bg-facebook {
  background-color: #335397;
  border: 1px solid #335397;
}

.bg-twitter {
  background-color: #00c7f7;
  border: 1px solid #00c7f7;
}

.bg-instagram {
  background-color: #82685a;
  border: 1px solid #82685a;
}

.bg-google {
  background-color: #00c7f7;
  border: 1px solid #00c7f7;
}

.social {
  font-size: 1.6rem;
}

.act {
  font-size: 1.4rem;
  color: #63879c;
  padding: 0.5rem 0.5rem 0.5rem 0;
  cursor: pointer;
}

.offer-det-act {
  font-size: 1.4rem;
  color: #63879c;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-right: 1rem;
  cursor: pointer;
}
.offer-det-act-share {
  font-size: 1.4rem;
  color: #63879c;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-right: 1rem;
  cursor: pointer;
  // commenting this code for bigscreen testing purpose

  // @include respond(too-big) {
  //   width: 4vh;
  //   height: 4vh;
  //   margin-bottom: 1.5rem;
  // }
}
.offer-det-act-icon {
  font-size: 1.4rem;
  color: #63879c;
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin-right: 1rem;
  cursor: pointer;
}

.act-new {
  font-size: 2.2rem;
  color: #63879c;
  padding: 0.5rem 0.5rem 0.5rem 0;
  color: #000;
}

.prefix {
  font-size: 1.2rem;
  color: #ccc;
  margin-top: 2px;
}

.transaction {
  font-size: 1.7rem;
  color: #fff;
}
