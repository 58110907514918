.offer_cards {
  //** commenting @include respond(too-big) this code for bigscreen testing purpose **//

  &--offer-bizName {
    font-weight: 400;
    font-size: 24px;
    color: #223369;
    // @include respond(too-big) {
    //   font-size: 50px;
    // }
  }

  // &--offer--starRating {
  //   @include respond(too-big) {
  //     transform: translate(2px, -21px);
  //   }
  // }
  &--offer--address {
    padding-top: 0.888rem;
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 400;
    // @include respond(too-big) {
    //   font-size: 40px;
    //   padding-top: 0.5rem;
    // }
  }

  &--offer--phoneNumber {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 400;
    // @include respond(too-big) {
    //   font-size: 40px;
    // }
  }

  &--offer--formatedDate {
    margin-top: 12px;
  }

  &--offer--formatedDateText {
    font-size: 14px;
    font-weight: 400;
    color: #ff0101;
    // @include respond(too-big) {
    //   font-size: 40px;
    // }
  }

  &--offer--validAtText {
    font-weight: 500;
    font-size: 20px;
    color: #223369;
    // @include respond(too-big) {
    //   font-size: 50px;
    //   margin-top: 1.5rem;
    // }
  }

  &--offer--addressValidAt {
    font-size: 14px;
    font-weight: 400;
    color: #7f7f7f;
    // @include respond(too-big) {
    //   font-size: 40px;
    // }
  }

  &--offer-finePrintText {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 138.19%;
    color: #223369;

    // @include respond(too-big){
    //   font-size: 60px ;
    //   color: #223369;
    // }
  }

  // &--offer-finePrintTextComment {

  //   @include respond(too-big){
  //     font-size: 50px ;
  //   }
  // }
  &--offer-itemTitle {
    color: #3a3a3a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    // @include respond (too-big){
    //   font-size: 60px;

    // }
  }

  &--img {
    height: 4rem;
    width: 8rem;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 1rem;

    @include respond(phone) {
      height: 8rem;
      width: 10rem;
      object-fit: contain;
    }
  }

  &--details-left-pad {
    padding-left: 24px;

    @include respond(xs) {
      padding-left: 0px;
    }
  }

  &--cmpn-type {
    padding-left: 1rem;
    text-align: left;

    @include respond(tab-land) {
      margin-top: 4.5rem;
    }

    @include respond(phone) {
      margin-top: 5.5rem;
    }
  }

  &--view-style {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #257cdb;
  }

  &--offer-discount {
    color: #257cdb;
    font-size: 14px;
    font-weight: 500;

    @media screen and (min-width: 1260px) and (max-width: 1366px) {
      font-size: 11px;
    }

    @include respond(tab-land) {
      font-weight: 400;
      font-size: 12px;
    }

    @include respond(phone) {
      font-weight: 400;
    }
  }

  &--offer-discountper {
    @media screen and (min-width: 1260px) and (max-width: 1366px) {
      font-size: 11px;
    }
  }

  &--offer-discount-text {
    font-size: 14px;
    color: #257cdb;
  }

  &--mob-rating-star {
    height: 35px;
    padding: 5px;
    background: #999999;
    border-radius: 5px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &--mob-rating-star-offer {
    height: 35px;
    // width: 35px;
    padding: 3px;
    background: #999999;
    border-radius: 5px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &--mob-rating-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    vertical-align: middle;
    margin-right: 7px;
    line-height: 0;

    @include respond(too-big) {
      border: 2px solid red;
    }
  }

  &--coupon-code {
    color: #223369;
    font-size: 14px;
    font-weight: 500;

    @include respond(tab-land) {
      font-weight: 400;
    }

    @include respond(phone) {
      font-weight: 400;
    }
  }

  &--offer-original-price {
    color: #a1a1a1;
    font-size: 14px;

    @media screen and (min-width: 1260px) and (max-width: 1440px) {
      font-size: 11px;
    }

    @media screen and (min-width: 1020px) and (max-width: 1260px) {
      font-size: 12px;
    }
  }

  &--offer-from {
    font-size: 14px;

    @media screen and (min-width: 1260px) and (max-width: 1366px) {
      font-size: 11px;
    }

    @media screen and (min-width: 1020px) and (max-width: 1260px) {
      font-size: 12px;
    }
  }

  &--offer-divider {
    height: 1px;
    width: 100%;
    background-color: #d3d6e1;
    margin: 18px 0 12px 0 !important;

    @include respond(xs) {
      display: none;
    }
  }

  &--offer-from {
    font-size: 14px;

    @media screen and (min-width: 1260px) and (max-width: 1366px) {
      font-size: 11px;
    }
  }

  &--offer-card-title {
    font-size: 14px;
    width: 100%;
    // overflow: hidden;
    position: relative;

    @include respond(tab-gen) {
      padding-top: 12px;
    }

    @include respond(phone) {
      padding-top: 12px;
    }
  }

  &--offer-card-padding {
    padding-left: 10px;

    @include respond(tab-port) {
      padding-left: 10px;
    }

    @include respond(xs) {
      padding-left: 0px;
    }
  }

  &--offer-cardNew-padding {
    padding-left: 10px;

    @include respond(tab-port) {
      padding-left: 10px;
    }

    @include respond(xs) {
      padding-left: 0px;
    }
  }

  &--qty-input {
    border-radius: 5px;
  }

  &--space {
    width: 100%;
    padding-left: 24px;
    margin-bottom: 24px;

    @include respond(xs) {
      padding-left: 0%;
    }
  }

  &--offer-filter {
    overflow-y: scroll;
    white-space: nowrap;
    height: 25vw;
  }

  &--no-x-scroll {
    overflow-x: hidden !important;
  }

  &--offer-panel {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &--offer-main-div {
    overflow-x: hidden;
    white-space: nowrap;
    width: 53vw;

    &::-webkit-scrollbar {
      height: 10px;
      background: transparent;
      margin-right: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(58, 41, 41, 0.148);
      border-radius: 10px;
    }

    @include respond(tab-land) {
      width: 82vw;
    }

    @include respond(tab-gen) {
      display: flex;
      justify-content: left;
      padding: 0 0 1rem 1rem;
    }

    @include respond(xs) {
      display: flex;
      justify-content: left;
      width: 85vw;
    }
    &.show-scrollbar {
      overflow-x: scroll;
    }
  }
  // &--offer-main-div .show-scrollbar {
  //   overflow-x: scroll;
  // }

  &--offer-one-div {
    display: flex;
    justify-content: left;
    width: 53vw;

    @include respond(tab-land) {
      width: 60vw;
    }

    @include respond(tab-gen) {
      display: flex;
      justify-content: left;
      padding: 0 0 1rem 1rem;
    }

    @include respond(xs) {
      display: flex;
      justify-content: left;
      width: 85vw;
    }
  }

  &--offer-main {
    margin: 0 0.4rem 0rem 0.4rem;
    display: inline-block;
    vertical-align: top;
    width: calc(55% - 0.4rem);
    cursor: pointer;
    background: #eaf2fc;
    border: none;
    border-radius: 10px;
    // height: 150px;

    @include respond(too-big) {
      height: auto;
    }

    @include respond(tab-gen) {
      width: calc(55% - 0.4rem);
      min-width: 200px;
      height: auto;
    }

    @include respond(tab-land) {
      width: 55%;
      height: auto;
    }

    @include respond(xs) {
      width: 100%;
      padding: 10px;
      height: auto;
    }
  }

  &--offer-main .ant-card-body {
    padding: 10px;
  }

  &--offer-main-one {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    background: #eaf2fc;
    border-radius: 10px;
    border: none;
    width: 55%;
    // height: 150px;

    @include respond(too-big) {
      height: auto;
    }

    @include respond(tab-gen) {
      width: 55%;
      height: auto;
    }

    @include respond(tab-land) {
      width: 55%;
      height: auto;
    }

    @include respond(xs) {
      width: 100%;
      padding: 10px;
      height: auto;
    }
  }

  // &--offer-main-one .ant-card-body {
  //   padding: 10px;
  // }

  &--brochure-main {
    cursor: pointer;
    background: #eaf2fc;
    border-radius: 10px;
    border: none;
    width: 200px;

    @include respond(tab-land) {
      padding: 10px;
      margin: 0 0 1rem 1rem;
    }

    @include respond(phone) {
      padding: 10px;
    }
  }

  // &--brochure-main .ant-card-body {
  //   padding: 10px;
  // }

  &--offer-main .ant-card-body {
    padding: 10px;
  }

  &--buy-btn-row {
    display: flex;
    justify-content: end;

    @include respond(tab-land) {
      justify-content: center;
      margin-top: 1rem;
    }

    @include respond(phone) {
      justify-content: center;
      margin-top: 1rem;
    }
  }

  &--buy-btn {
    background-color: #257cdb;
    border-radius: 5px;
    padding: 0rem 1.5rem;
  }

  &--brochure-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem 2rem 0 0;
    z-index: 2;

    @include respond(phone) {
      top: 100;
      right: -10;
      margin: 0;
    }
  }

  &--ribbon {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--ribbon-text {
    position: absolute;
    top: 0;
    right: 4px;
    width: 34px;
    z-index: 2;
    text-align: center;
  }

  &--offer-padding {
    padding-left: 10px;
  }

  &--offer-details {
    width: 100%;
    min-width: 100%;
    border-radius: 10px;
    margin-bottom: 24px !important;
  }

  @include respond(phone) {
    margin-bottom: 12px !important;
  }

  &--offer-detailsNew {
    width: 100%;
    min-width: 100%;
    border-radius: 10px;

    @include respond(phone) {
      margin-bottom: 12px !important;
    }
  }

  &--offer-details .ant-card-body {
    padding: 12px;
  }

  &--offer-detailsNew .ant-card-body {
    padding: 12px;
  }

  &--offer-details-border {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &--active {
    background-color: #223369;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    height: 38px;
    width: 170px;
    border: none;
    margin-bottom: 1.5rem;
  }

  &--brochure-btn {
    background-color: #223369;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    height: 38px;
    width: 170px;
    border: none;
    margin-bottom: 1.5rem;
  }

  &--active:hover {
    background-color: #39509e;
    color: #fff;
  }

  &--offersoldout {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightpink;
    padding: 0 0.7rem 0 0.7rem;
    height: 2.2rem;
    max-width: 15rem;
    border-radius: 0.2rem;
  }

  &--offer-title {
    @include respond(phone) {
      font-size: 1.7rem;
    }
  }

  &--main-card {
    background-color: #f5f5f5;
    border: none;
    padding: 0px 32px 0px 32px;
    padding-top: 60px;

    @include respond(tab-land) {
      padding: 0px 16px 0px 16px;
      padding-top: 60px;
    }

    @include respond(phone) {
      padding: 0px 16px 0px 16px;
      padding-top: 60px;
    }
  }

  &--main-offer-card {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #d3d6e1;
  }

  &--main-offer-card .ant-card-body {
    padding: 10px;
  }

  &--offer-subtitle {
    font-size: 12px;
  }

  &--img_big {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover !important;
    border-radius: 13px;
    min-height: 100%;
    border-radius: 5px;

    @include respond(too-big) {
      height: auto;
    }

    @include respond(tab-land) {
      height: auto;
    }

    @include respond(phone) {
      height: auto;
    }
  }

  &--cmpn-img {
    width: 100%;
    max-width: 100%;
    // aspect-ratio: 2/1;
    aspect-ratio: 8/5;
    object-fit: cover;
    border-radius: 10px;

    @media (max-width: 500px) {
      display: block;
    }
  }

  &--offer-icons-div {
    margin: 1rem 0 1rem 0;
    width: 100%;
  }

  &--icon-border {
    @include respond(phone) {
      border-left: 1px solid #d3d6e1;
    }
  }

  &--cmpn-img_big {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 2/1;
    max-width: 100%;
    border-radius: 10px;
  }

  &--cmpn-liked {
    margin-top: -2px;
    color: #2764cd;
    font-size: 14.5px;
    margin-left: -5px;
  }

  &--cmpn-liked_logo {
    color: #2764cd;
    font-size: 18px;
  }

  &--cmpn-liked_logo_act {
    color: #2764cd;
    font-size: 22px;
  }

  &--doc {
    height: 10% !important;
    width: 5% !important;
  }

  &--mobile-width {
    @include respond(tab-gen) {
      width: 100%;
    }

    @include respond(xs) {
      overflow-x: scroll;
    }
  }

  &--map {
    height: 50vh;

    @include respond(tab-gen) {
      height: 25vh;
    }
  }

  &--responsive-card {
    width: 49%;

    @include respond(tab-gen) {
      width: 100%;
    }
  }

  :active {
    // box-shadow: 0 .5rem 1rem rgba($color: #000000, $alpha: .25);
    transform: translateY(0);
  }

  &--title {
    @include respond(tab-gen) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 2.5rem;
    }
  }

  &--offer-name {
    font-size: 18px;
    color: #3a3a3a;
    margin-bottom: 0.313rem;
    font-weight: 500;

    @include respond(tab-land) {
      font-size: 20px;
      font-weight: 400;
    }

    @include respond(xs) {
      padding-left: 12px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  &--offer-name-title {
    @include respond(tab-land) {
      margin-top: 10px;
    }

    @include respond(phone) {
      margin-top: 10px;
    }
  }

  &--address {
    color: #7c7c7c;
    font-weight: 400;
    font-size: 12px;

    @include respond(phone) {
      font-size: 1.6rem;
    }
  }

  &--address-div {
    margin-top: 0.313rem;
  }

  &--visiting-link {
    width: 24rem;
  }

  &--loader {
    margin: 0;
    position: absolute;
  }

  &--pulseLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42rem;
  }

  &--go-back-btn {
    // font-size: 16px;
    color: #3a3a3a;
    // padding: 0%;
    // vertical-align: middle;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }

  // &--go-back-btn .anticon {
  //   vertical-align: 0;
  //   font-size: 22px;
  //   font-weight: 900;
  // }

  &--offer-and-icons {
    width: 100%;
    padding: 0px 22px;
  }

  &--offer-deatilsrow {
    min-width: 100%;
  }

  &--offer-details {
    min-width: 10rem;

    @include respond(phone) {
      width: 50vw;
    }
  }

  &--offer-detailsNew {
    min-width: 10rem;

    @include respond(phone) {
      width: 50vw;
    }
  }

  &--offer-row-details {
    min-width: 10rem;
    margin-top: 0.625rem;

    @include respond(phone) {
      width: 50vw;
    }
  }

  &--details-row {
    min-width: 100%;
    margin-top: 2rem;
  }

  &--brochure-img {
    max-width: 25rem;
  }

  &--brochure-btn-row {
    margin-top: 2rem;
  }

  &--brochure-btn-icon {
    font-size: 20px;
    vertical-align: 0;
    line-height: 0;
  }

  &--offerpage-data {
    min-width: 100%;
    margin-top: 0.313rem;
  }

  &--cards {
    cursor: pointer;
  }

  &--cards-offer-details {
    min-width: 100%;
  }

  &--offers-name-col {
    width: 50%;
  }

  &--offer-discription {
    min-width: 10rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      margin-left: 4rem;
    }
  }

  &--aftr-qty-inc {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &--pay-btn {
    margin-top: 1rem;
  }

  &--btn {
    min-width: 16rem;
  }

  &--paymnt-add {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

.offer-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 18px 0px 12px 0px !important;

  @include respond(tab-land) {
    display: none;
  }

  @include respond(phone) {
    display: none;
  }
}

@media (max-width: 769px) {
  .offer_cards--offer-row-details {
    width: 50vw;
  }
}

@media (min-width: 770px) and (max-width: 821px) {
  .offer_cards--offer-row-details {
    width: 10vw;
  }
}

@media (min-width: 822px) and (max-width: 913px) {
  .offer_cards--offer-row-details {
    width: 5vw;
  }
}

@media (min-width: 1024px) {
  .offer_cards--offer-row-details {
    right: 2rem;
  }
}

// @media (min-width: 375px) and (max-width: 425px) {
//   .offer_cards--offer-details-border .ant-card-body {
//     padding: 0px;
//   }

//   .offer_cards--offer-details-border {
//     border: none;
//   }
// }

@media (max-width: 575px) {
  .offer_cards--main-offer-card .ant-card-body {
    padding: 0rem;
  }
}

.offer {
  &--section__main {
    padding: 0 6% 0 6%;

    @include respond(tab-land) {
      padding: 0%;
    }

    @include respond(phone) {
      padding: 0%;
    }
  }

  &--location-input .css-yk16xz-control {
    // border-width: 0px !important;
    height: 44px;
    border-radius: 10px;
  }

  &--location-input .css-tj5bde-Svg {
    display: none !important;
  }

  &--location-input .css-1wa3eu0-placeholder {
    color: #4e5c87 !important;
  }

  &--home {
    background-color: #f8f4f4;
  }

  &--main-page {
    max-width: 100%;
    padding-left: 1.5rem;
    overflow: hidden;
  }

  &--main-page-col {
    background-color: #fff;
    border-radius: 10px;
    height: fit-content;
    // overflow: hidden;

    // height: 100rem;
  }

  &--render-nodata {
    text-align: center;
    padding: 5rem;
  }

  &--header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &--header-row {
    padding: 0 1rem;
  }

  &--search-input {
    height: 46px;
    border-radius: 10px;

    @include respond(phone) {
      border: 1px solid #d3d6e1;
    }
  }

  &--search-input input::placeholder {
    color: #4e5c87 !important;
    font-size: 16px;
    // text-align: center;

    @include respond(phone) {
      padding-left: 24px;
      text-align: left;
    }
  }

  &--search-deals {
    margin-bottom: 2rem;
  }

  &--search-col {
    padding-top: 12px;
  }

  &--range {
    margin-top: 24px;

    @include respond(xs) {
      margin-top: 0px;
    }
  }

  &--buttons {
    margin: 1rem;
    text-align: left;
  }

  &--end-msg {
    margin-top: 1rem;
  }

  &--deals-option {
    margin-top: 1rem;
  }

  &--deals-category {
    margin-top: 1.5rem;
  }

  &--categories {
    width: 100%;
  }

  &--category-options {
    margin-top: 1rem;
  }

  &--location-modal {
    height: auto;
    width: 90%;
  }

  &--smallbox-icon {
    font-size: 2rem;
    color: #fff;
  }

  &--miles {
    padding-top: 10px;
    padding-left: 50px;
    white-space: nowrap;
  }
}

.itemview {
  &--img {
    object-fit: contain;
    width: 25rem;
    cursor: pointer;
    max-width: 100%;
    border-radius: 10px;
  }

  &--close-btn {
    color: #3a3a3a;
    font-size: 30px;
  }

  &--useful-btn {
    border-radius: 5px;
    padding: 1 2rem;
    background-color: #fff;
    color: #44c651;
    border: 2px solid #44c651;
    margin-top: 1rem;
  }

  &--useful-btn:hover {
    border-radius: 5px;
    color: #fff;
    background-color: #44c651;
    border: 2px solid #44c651;
  }

  &--useful-btn:focus {
    border-radius: 5px;
    color: #fff;
    background-color: #44c651;
    border: 2px solid #44c651;
  }

  &--not-useful-btn {
    border-radius: 5px;
    padding: 1 2rem;
    background-color: #fff;
    color: #f69158;
    border: 2px solid #f69158;
    margin-top: 1rem;
  }

  &--not-useful-btn:hover {
    border-radius: 5px;
    color: #fff;
    background-color: #f69158;
    border: 2px solid #f69158;
  }

  &--not-useful-btn:focus {
    border-radius: 5px;
    color: #fff;
    background-color: #f69158;
    border: 2px solid #f69158;
  }

  &--title {
    margin-bottom: 1.25rem;
    font-weight: 500;
    font-size: 18px;
  }

  &--div-text-and-buttons {
    margin-top: 1.5rem;
  }

  &--dislike-icon {
    font-size: 1rem;
    vertical-align: 0;
    line-height: 0;
  }

  &--like-icon {
    font-size: 1rem;
    vertical-align: 0;
    line-height: 0;
  }
}

.offers-labels {
  margin-right: 5rem;
}

.offerFeedback_modal .ant-modal-header {
  border-radius: 0.625rem 0.625rem 0rem 0rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.offerFeedback_modal .ant-modal-content {
  border-radius: 0.625rem;
}

.offerFeedback_modal .ant-modal-title {
  color: #223369;
  font-size: 1.25rem;
  font-weight: 500;
}

.reviewCommentsPopup .ant-modal-content {
  border-radius: 10px;
}

.offer--checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #223369;
  border: none;
}

.brochure_img {
  aspect-ratio: 3/2;
}

.fine-print-details {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 138.19%;
  color: #223369;
}

.fine-print-details-text {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: #333333;
  margin-top: 12px !important;
  overflow-wrap: break-word;
}

.reviewrating_filterBTN {
  font-weight: 400;
  font-size: 18px;
  color: #223369;
  background: #ffffff;
  border: "1px solid #D3D6E1";
  border-radius: 5px;
  height: 46px !important;
  width: 15vw !important;

  @include respond(xs) {
    font-weight: 400;
    font-size: 18px;
    color: #223369;
    background: #ffffff;
    border: "1px solid #D3D6E1";
    border-radius: 5px;
    height: 46px !important;
    width: 45vw !important;
  }
}

.reviewrating_filterBTN.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 44px;
  padding: 0 24px;
  border: none;
  border-radius: 5px;
  align-items: center;

  @include respond(xs) {
    padding: 0 5px;
  }
}

.reviewrating_filterBTN .ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #223369;
}

.reviewrating_filterBTN2.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 42px;
  padding: 0 24px;
  border: none;
  border-radius: 10px;
  align-items: center;

  @include respond(xs) {
    padding: 0 12px;
  }
}

.reviewrating_filterBTN2 .ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 14px;
  // line-height: 27px;
  color: #4e5c87;
}

.reviewrating {
  &_ratingReviewDivider {
    border: 1px solid #cccccc;
    height: 117px;
    top: 1em;
    margin-right: 2rem;

    @media (min-width: 3455px) {
      border: 2px solid #cccccc;
      height: 280px;
      top: 1em;
      margin-right: 2rem;
    }
  }

  &_accordionRow {
    @include respond(too-big) {
      margin-bottom: 1.5rem;
    }
  }

  &_avgChart {
    @include respond(too-big) {
      margin-bottom: 5rem;
    }
  }

  &_ReviewInputBtn {
    @include respond(too-big) {
      margin-bottom: 3.5rem;
    }
  }

  &_filterBtnRow {
    @include respond(too-big) {
      margin-bottom: 3.5rem;
    }
  }

  &_filterBTN {
    width: 195px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d3d6e1;
    border-radius: 5px;

    // @include respond(too-big) {
    //   width: 25rem;
    //   height: 5rem;
    //   // border: 2px solid #1336b6;
    //   font-size: 2rem;
    //   // text-align: center;
    //   vertical-align: middle;

    // }
  }

  &_filterBTN2 {
    width: calc(100% - 12px);
    height: 40px;
    background: #ffffff;
    border-radius: 10px;

    @media (max-width: 767px) {
      border: 1px solid #d3d6e1;
      width: 100%;
    }
  }

  &_mainratingRow {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &_mainratingCol {
    padding: 25px 24px 25px 24px;
    // margin-left: 24px;
  }

  &_mainratingCol-mobile {
    padding: 16px 12px 12px 12px;
  }

  &_headerText {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #223369;
  }

  &_headerText-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 1.7rem;
    color: #223369;
  }

  &_rateTheStore-mobile {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 35px;
    color: #223369;
  }

  &_headerText-mobile {
    padding-top: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 21px;
    text-transform: capitalize;
    color: #223369;
  }

  @media screen and (min-width: 324px) and (max-width: 400px) {
    &_rateTheStore-mobile {
      font-style: normal;
      font-weight: 500;
      font-size: 1.7rem;
      line-height: 35px;
      color: #223369;
    }
  }

  @media screen and (min-width: 319px) and (max-width: 324px) {
    &_rateTheStore-mobile {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 35px;
      color: #223369;
    }

    &_shareExp-mobile {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 27px;
      color: #223369;
      // margin-left: 1rem;
    }
  }

  &_writeReview {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #223369;

    // margin-left: 1rem;
    @media (min-width: 3455px) {
      font-size: 2.7rem;
      font-weight: 400;
    }
  }

  &_writeReview-mobile {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 27px;
    color: #223369;
    // margin-left: 1rem;
  }

  &_shareExp-mobile {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 27px;
    color: #223369;
    // margin-left: 1rem;
  }

  &_writeReviewRateUs {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #223369;
    margin-left: 1.5rem;

    @media (min-width: 3455px) {
      font-size: 2.7rem;
    }
  }

  &_writeReviewStars {
    margin-left: 1.5rem;
  }

  &_writeReview-sideBar {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 15px;
    text-align: right;
    color: #223369;
    // for testting purpose commented this code

    // @include respond(too-big) {
    //   font-size: 50px;
    //   margin-top: 1rem;
    //   line-height: 73px;
    //   text-align: right;
    // }
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  &_inputBox {
    box-sizing: border-box;
    width: 18.688rem;
    // height: 2.95rem;
    // width: 299px;
    height: 46px;
    margin-top: 6px;
    background: #ffffff;
    border: 1px solid #d3d6e1;
    border-radius: 5px;
    border-color: #d3d6e1;
    display: flex;
    cursor: pointer;

    @media (min-width: 3455px) {
      width: 27.5rem;
      height: 5rem;
      margin-top: 2rem;
      border-radius: 5px;
      border: 3px solid #d3d6e1;
    }
  }

  &_inputBox-mobile {
    width: 90%;
    height: 4rem;
    background: #ffffff;
    border: 1px solid #d3d6e1;
    border-radius: 5px;
    margin-top: 12px;
    display: flex;
    cursor: pointer;
  }

  &_formOutLine {
    color: #a5a5a5;
    font-size: 1.5rem;
    padding-top: 0.5rem;

    @media (min-width: 3455px) {
      font-size: 2.5rem;
      padding-top: 1rem;
    }
  }

  &_formOutLine-mobile {
    font-size: 2rem;
    color: #a5a5a5;
    padding-top: 0.5rem;
  }

  &_rateStars {
    font-size: 2rem;
  }

  &_histo-star {
    float: left;
    font-style: normal;
    font-weight: 600;
    font-size: 1.12rem;
    line-height: 20px;
    color: #4d4d4d;
  }

  @media (min-width: 3455px) {
    &_mainratingRow {
      box-sizing: border-box;
      background: #ffffff;

      border-radius: 10px;
    }

    &_mainratingCol {
      padding: 50px 48px 50px 50px;
      // margin-left: 24px;
    }

    &_headerText {
      font-style: normal;
      font-weight: 400;
      font-size: 2.7rem;
      line-height: 1.7rem;
      color: #223369;
    }

    &_accordionImg {
      width: 5vw;
      height: 4vh;
    }
  }

  @media screen and (min-width: 426px) and (max-width: 600px) {
    &_histo-star {
      float: left;
      padding: 0px 0px 0px 7px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.12rem;
      line-height: 23px;
      color: #4d4d4d;
    }

    &_formOutLine {
      color: #a5a5a5;
      font-size: 2.5rem;
      padding-top: 0.5rem;
    }
  }

  @media screen and (min-width: 700px) and (max-width: 830px) {
    &_inputBox {
      box-sizing: border-box;
      width: 18rem;
      // height: 2.95rem;
      // width: 299px;
      height: 46px;
      margin-top: 6px;
      background: #ffffff;
      border: 1px solid #d3d6e1;
      border-radius: 5px;
      border-color: #d3d6e1;
      display: flex;
      cursor: pointer;
    }

    &_formOutLine {
      color: #a5a5a5;
      font-size: 2.5rem;
      padding-top: 0.5rem;
    }

    &_histo-star {
      float: left;
      padding: 3px 0px 3px 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.12rem;
      line-height: 20px;
      color: #4d4d4d;
    }

    &_writeReviewRateUs {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #223369;
      margin-left: -0.5rem;
    }

    &_writeReviewStars {
      margin-left: -0.5rem;
    }
  }

  @media screen and (min-width: 830px) and (max-width: 1000px) {
    &_inputBox {
      box-sizing: border-box;
      width: 20rem;
      // height: 2.95rem;
      // width: 299px;
      height: 46px;
      margin-top: 6px;
      background: #ffffff;
      border: 1px solid #d3d6e1;
      border-radius: 5px;
      border-color: #d3d6e1;
      display: flex;
      cursor: pointer;
    }

    &_formOutLine {
      color: #a5a5a5;
      font-size: 2.5rem;
      padding-top: 0.5rem;
    }

    &_histo-star {
      float: left;
      padding: 3px 0px 3px 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.12rem;
      line-height: 20px;
      color: #4d4d4d;
    }

    &_writeReviewRateUs {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #223369;
      margin-left: -0.5rem;
    }

    &_writeReviewStars {
      margin-left: -0.5rem;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    &_formOutLine {
      color: #a5a5a5;
      font-size: 2.2rem;
      padding-top: 0.5rem;
    }
  }

  @include respond(too-big) {
    &_histo-star {
      float: left;
      padding: 3px 0px 3px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 20px;
      color: #4d4d4d;
    }
  }
}

.allReviewComments_table .ant-table-thead > tr > th,
.allReviewComments_table .ant-table-tbody > tr > td,
.allReviewComments_table .ant-table tfoot > tr > th,
.allReviewComments_table .ant-table tfoot > tr > td {
  position: relative;
  padding: 0px 16px;
  overflow-wrap: break-word;
}

@media screen and (min-width: 3455px) {
  .ant-progress-bg {
    height: 1vh !important;
  }
}

.modelUploadImg {
  &_seeAllModal {
    min-width: 51.25rem;
    min-height: 58.688rem;

    @include respond(too-big) {
      min-width: 75rem;
      min-height: 75rem;
    }
  }

  &_mainCol {
    background: #ffffff;
    border-radius: 10px;
    padding-top: 25px;

    @include respond(too-big) {
      height: 87vh;
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }

  &_mainCol-mobile {
    border-radius: 10px;
    padding-top: 16px;
  }

  &_ratingReviewText {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.75rem;
    text-align: right;
    color: #223369;

    @include respond(too-big) {
      font-size: 65px;
      margin-bottom: 50px;
      margin-top: 10px;
    }
  }

  &_modalCloseBtn {
    @include respond(too-big) {
      width: 2.7vh;
      transform: translate(-24px, 19px);
    }
  }

  &_ratingReviewText-mobile {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.75rem;
    text-align: right;
    color: #223369;

    @include respond(too-big) {
      font-style: 40px;
    }
  }

  &_closeModel {
    display: flex;
    justify-content: flex-end;
  }

  &_modelRateUs {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;

    color: #223369;
  }

  &_starRow {
    @include respond(too-big) {
      margin-bottom: 50px;
    }
  }

  &_modelRateProduct {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.75rem;

    color: #223369;

    @include respond(too-big) {
      font-size: 60px;
      margin-top: 50px;
    }
  }

  &_modelShareExperience {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.75rem;
    color: #223369;

    @include respond(too-big) {
      font-size: 60px;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  &_modelRateUs-mobile {
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    padding-bottom: 0.8rem;
  }

  &_modelShareExperience-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    padding-bottom: 0.8rem;
  }

  &_modelAddImgtext {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.75rem;
    padding-left: 26px;
    margin-bottom: 2rem;
    color: #223369;
    margin-top: 24px;

    @include respond(too-big) {
      font-size: 60px;
    }
  }

  &_modelAddImgtext-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
    padding-left: 12px;
    padding-top: 12px;
  }

  &_textArea.ant-input {
    background: #f4f8fd;
    border: 1px solid #d3d6e1;
    border-radius: 10px;
    width: 80vw;
    margin-top: 1.063rem;
    margin-bottom: 1.563rem;
    padding: 1.5rem;
    line-height: 150%;

    @include respond(too-big) {
      margin-bottom: 50px;
    }
  }

  &_textArea-mobile {
    border: 1px solid #d3d6e1;
    border-radius: 10px;
    min-width: 76vw;
    margin-top: 1.063rem;
    margin-bottom: 1.563rem;
  }

  &_imgUpload {
    border: 1px dashed #d3d6e1;
    border-radius: 10px;
  }

  &_experienceBtn {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    border-radius: 5px;
    width: 10vw;
    height: 100%;
    color: #44c651;
    border: 1px solid #44c651;
    display: flex;
    justify-content: center;
    padding-top: 0.3rem;
  }

  &_clickToUploadImg {
    width: 2rem;
  }

  &_uploadImgContainer {
    width: 100%;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: #ffffff;
    border: 1px solid #3f9af7;
    border-radius: 10px;
  }

  &_addImage {
    display: flex;
    justify-content: center;
  }

  &_uploadeImg {
    width: 38.01px;
    height: 38.34px;

    @include respond(too-big) {
      width: 168.01px;
      height: 161.34px;
    }
  }

  &_uploadeImg-mobile {
    width: 26px;
    height: 27px;
  }

  &_uploadImgRow {
    border: 1px dashed #d3d6e1;
    border-radius: 10px;
    padding-top: 24px;
    // padding-bottom: 10px;
    // // padding-left: 25px;
    margin-left: 24px;
    margin-right: 24px;

    @include respond(too-big) {
      border: 5px dashed #d3d6e1;
      height: 42vh;
      padding-top: 49px;
      padding-left: 60px;
    }
  }

  &_uploadImgRow-mobile {
    width: 100%;
    // height: 176px;
    border: 1px dashed #257cdb;
    border-radius: 10px;
  }

  &_uploadeImgDiv {
    display: flex;
    justify-content: center;
  }

  &_uploadeImgDiv-mobile {
    display: flex;
    justify-content: center;
    padding-top: 7px;
  }

  &_uploadedImgDisplay {
    width: 196px;
    height: 133px;
    margin-bottom: 1rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    @include respond(too-big) {
      width: 498px;
      height: 394px;
    }
  }

  &_uploadedImgDisplayMob {
    width: 107px;
    height: 72px;
    margin-bottom: 1rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  &_cancelButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 0;

    @include respond(too-big) {
      top: 10px;

      padding-left: 200px;
      background-color: transparent;
      border: none;
      border-radius: 100%;
      width: 200px;
      height: 20px;
      line-height: 0;
    }
  }

  &_cancelImgUploadBtn {
    @include respond(too-big) {
      width: 2vh;
      margin-left: 11rem;
      margin-top: 0.2rem;
    }
  }

  &_cancelButtonMob {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 0;
  }

  &_addImgBtn {
    width: 196px;
    height: 133px;
    margin-bottom: 24px;
    background: #ffffff;
  }

  &_addImgBtnMob {
    width: 130px;
    height: 80px;
    margin-bottom: 1rem;
    background: #ffffff;
  }

  &_uploadedImgDivMob {
    position: relative;
    width: 130px;
    display: flex;
    justify-content: center;
    padding: 4px;
  }

  &_uploadeImgBtn {
    width: 196px;
    height: 133px;
    background: #ffffff;
    border: 1px solid #3f9af7;
    border-radius: 10px;
    padding-top: 32px;

    @include respond(too-big) {
      width: 498px;
      height: 394px;
      background: #ffffff;
      border: 3px solid #3f9af7;
      border-radius: 10px;
      padding-top: 70px;
    }
  }

  &_uploadeImgBtn-mobile {
    width: 107px;
    height: 72px;
    border: 1px solid #257cdb;
    border-radius: 10px;
  }

  &_btnText {
    color: #3f9af7;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    text-align: center;

    @include respond(too-big) {
      font-size: 50px !important;
    }
  }

  &_btnText {
    color: #3f9af7;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &_btnText-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3f9af7;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &_submitBtn {
    width: 147px;
    height: 43px;
    margin-top: 20px;
    margin-bottom: 1.5rem;
    background: #3f9af7;
    border-radius: 5px;

    @include respond(xs) {
      width: 100px;
    }

    @include respond(too-big) {
      width: 344px;
      height: 107px;
      margin-top: 0px;
    }
  }

  &_submitBtnText {
    color: white;
    font-size: 16px;
    font-weight: 400;
    padding-top: 5px;

    @include respond(too-big) {
      font-size: 2.1rem;
    }
  }

  &_replySubmitBtn {
    width: 147px;
    height: 43px;
    margin-top: 20px;
    background: #3f9af7;
    border-radius: 10px;

    @include respond(xs) {
      width: 100px;
    }
  }

  &_submitBtn-mobile {
    width: 91px;
    height: 46px;
    background: #257cdb;
    border-radius: 5px;
    margin-top: 24px;
  }

  &_submitBtnTxt {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding-top: 0.7rem;
    padding-left: 0.2rem;
  }

  &_modalInputBox {
    background: #f4f8fd;
    max-width: 100%;
    border-radius: 10px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.1475);
      border-radius: 10px;
    }
  }

  &_modalInputBox .ant-input {
    background: #f4f8fd;
  }

  @media screen and (min-width: 400px) and (max-width: 500px) {
    &_textArea-mobile {
      border: 1px solid #d3d6e1;
      border-radius: 10px;
      min-width: 70vw;
      margin-top: 1.063rem;
      margin-bottom: 1.563rem;
    }
  }

  @media screen and (min-width: 430px) and (max-width: 600px) {
    // &_uploadeImgBtn {
    //   width: 128px;
    //   height: 133px;
    //   background: #ffffff;
    //   border: 1px solid #3f9af7;
    //   border-radius: 10px;
    //   padding-top: 32px;
    // }
    &_uploadImgRow {
      border: 1px dashed #d3d6e1;
      border-radius: 10px;
      padding-top: 24px;
      padding-bottom: 10px;
      padding-right: 23px;
      margin-left: 26px;
      margin-right: 24px;
      justify-content: space-around;
    }

    // &_addImgBtn {
    //   width: 113px;
    //   height: 133px;
    //   margin-bottom: 1rem;
    //   background: #ffffff;
    // }
    &_textArea {
      background: #f4f8fd;
      border: 1px solid #d3d6e1;
      border-radius: 10px;
      width: 88vw;
      margin-top: 1.063rem;
      margin-bottom: 1.563rem;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    // &_uploadImgRow {
    //   border: 2px dashed #d3d6e1;
    //   border-radius: 10px;
    //   padding-top: 24px;
    //   padding-bottom: 10px;
    //   padding-left: 24px;
    //   margin-left: 26px;
    //   margin-right: 24px;
    // }
    &_textArea {
      background: #f4f8fd;
      border: 1px solid #d3d6e1;
      border-radius: 10px;
      width: 90vw;
      margin-top: 1.063rem;
      margin-bottom: 1.563rem;
    }
  }
}

// @media (min-width: 3455px) {
//   .count_star {
//     font-style: normal;
//     font-weight: 400;
//     font-size: 1rem;
//     color: #4d4d4d;
//     // padding-right: 2rem;
//     text-align: right;
//   }
// }
.reviewrating_chartProgressBar .ant-progress-inner {
  background-color: #cccccc;
  border-radius: 100px;
}

.modelUploadImg_seeAllModal .ant-modal-content {
  border-radius: 0.625rem;
}

.modelUploadImg_seeAllModal-mobile .ant-modal-content {
  border-radius: 0.625rem;
}

// .allReviewComments .ant-modal-content {
//   @include respond(too-big){
//     border-radius: 0.625rem;
//     width: 50vw;
//     height: auto;
//   }

// }
.bigScreenStar {
  line-height: 10px;
  margin-top: 0.5rem;

  @include respond(too-big) {
    margin-top: 1rem;
  }
}

.allReviewComments {
  width: 100%;

  // @include respond(too-big) {
  //   //  min-width: 50vw;
  //   //   min-height: 60vw;
  //   min-width: 75rem;
  //   min-height: 75rem;
  // }
}

.allReviewComments .ant-modal-title {
  @include respond(too-big) {
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    // line-height: 28px;
    color: #223369;
    padding-top: 30px;
  }
}

.allReviewComments .ant-modal-body {
  padding: 0px 0px;
}

.modalMainCol {
  max-height: 80vh;
  overflow-y: scroll;

  @include respond(too-big) {
    max-height: 90vh;
    overflow-y: scroll;
  }
}

.closeIconBtnAllReviewPopUp {
  @include respond(too-big) {
    vertical-align: text-top;
    border-style: none;
    width: -webkit-fill-available;
    padding-right: 0.5rem;
  }
}

.allReviewComments_table .ant-table-content {
  border-radius: 10px;
}

.allReviewComments_table .ant-table-tbody > tr > td {
  padding: 0px 24px;
  border-bottom: 0px solid #f0f0f0;
}

.allReviewComments_scroll {
  overflow-y: scroll;
  width: 100%;
  border-radius: 10px;

  &::-webkit-scrollbar {
    height: 10px;
    width: 7px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d6e1;
    border: 1px solid rgba(255, 255, 255, 0.1475);
    border-radius: 3.5px;
  }
}

@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400);

body {
  font-family: "Roboto", Helvetica;

  font-weight: 300;
}

.rating-mobile {
  float: left;
  width: 45%;
  margin-right: 5%;
  text-align: center;
  margin-top: 1.563rem;
}

.rating-num {
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 75px;
  color: #333333;
  margin-top: 2rem;
  text-align: center;
  // margin-left: 2rem;
}

@include respond(too-big) {
  .rating-num {
    font-style: normal;
    font-weight: 600;
    font-size: 6rem;
    line-height: 75px;
    color: #333333;
    margin-top: 3rem;
    text-align: center;
    // margin-left: 2rem;
  }
}

.rating-num-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 75px;
  color: #333333;
  text-align: center;
}

.rating-stars {
  font-size: 20px;
  color: #e3e3e3;
  margin-bottom: 0.5em;
}

.rating-stars .active {
  color: #737373;
}

// .rating-users {
//   // font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 1.37rem;
//   line-height: 1rem;
//   color: #333333;
//   margin-left: 0.5rem;
// }
// .rating-users-mobile {
//   // font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 1.3rem;
//   line-height: 1px;
//   color: #333333;
// }
.rating-users {
  // font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.37rem;
  line-height: 1rem;
  color: #333333;
  margin-left: 0.5rem;
  text-align: center;

  @include respond(too-big) {
    font-size: 2.37rem;
    line-height: 6rem;
  }
}

.rating-users-mobile {
  // font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 0;
  color: #333333;
  text-align: center;
}

.histo-star {
  float: left;
  padding: 3px 0px 3px 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.12rem;
  line-height: 20px;
  color: #4d4d4d;
}

.histo-star-mobileView {
  float: left;
  padding: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  color: #7f7f7f;
}

@media screen and (min-width: 320px) and (max-width: 415px) {
  .histo-star-mobileView {
    float: left;
    padding: 5px 0px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #7f7f7f;
  }
}

.histo-star-mobile {
  float: left;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #7f7f7f;
  padding-right: 0.3rem;
}

.histo-rate {
  width: 100%;
  clear: both;
}

.count_star {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #4d4d4d;
  padding-top: 0.2rem;
  text-align: right;

  @include respond(too-big) {
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    color: #4d4d4d;
    transform: translate(10px, -15px);
    text-align: right;
    display: flex;
    vertical-align: text-top;
  }
}

@media screen and (min-width: 800px) and (max-width: 830px) {
  .count_star {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #4d4d4d;
    padding-top: 0.5rem;
    text-align: right;
  }
}

.count_star-mobileView {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  // line-height: 23px;
  color: #7f7f7f;
  display: flex;
  // justify-content: center;
  text-align: right;
  padding-left: 0.8rem;
  padding-top: 0.6rem;
  justify-content: right;
}

.bar-block {
  margin-left: 5px;
  color: black;
  display: block;
  float: left;
  width: 80%;
  position: relative;
  margin-top: 0.7rem;
  background: #cccccc;
  border-radius: 10px;
}

.bar-block-mobileView {
  margin-left: 5px;
  align-self: center;
  justify-content: center;
  display: block;
  float: left;
  width: 70%;
  position: relative;
  border-radius: 10px;
}

.bar-block-mobile {
  color: black;
  display: block;
  float: left;
  width: 80%;
  position: relative;
  margin-top: 0.4rem;

  border-radius: 10px;
}

.bar {
  padding: 2.5px;
  display: block;
  border-radius: 10px;
}

.bar-mobile {
  padding: 4px;
  display: block;
  border-radius: 10px;
}

.divider-mobile {
  border: 1px solid #d3d6e1;
}

.divider-mobile-shareExperience {
  border: 1px solid #d3d6e1;
}

.divider-mobile .ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #eeeff1;
  border-right-width: 1px;
  z-index: 1;
}

.callUs_modal .ant-modal-content {
  border-radius: 0.625rem;
}

.callUs_modal .ant-modal-header {
  border-radius: 0.625rem;
}

.callUs_modal .ant-modal-title {
  font-size: 20px;
  font-weight: 400;
}

.totalReviews {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #257cdb;
  padding-top: 0.5rem;
  padding-left: 0.3rem;
  cursor: pointer;

  @media (max-width: 1290px) {
    font-size: 14px;
  }

  // @include respond(too-big) {
  //   font-size: 40px;
  //   line-height: 35px;
  // }
}

.ratingAvg {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #4d4d4d;
  padding-top: 0.5rem;
  // padding-left: 0.8rem;

  @media (max-width: 1290px) {
    font-size: 14px;
  }
}

.ratingAvg2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  // padding-left: 0.888rem;
  vertical-align: middle;
}

.starAvg {
  @media (max-width: 576px) {
    width: 100%;
    padding-left: 1rem;
  }
}

.modelUploadImg_seeAllModal .ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

// .modelUploadImg_seeAllModal .ant-modal-close-x {
//   display: block;
//   width: 54px;
//   height: 54px;
//   font-size: 16px;
//   font-style: normal;
//   line-height: 54px;
//   text-align: center;
//   text-transform: none;
//   text-rendering: auto;
//   padding-right: 1.5rem;
//   padding-top: 0.8rem;
// }

textarea.ant-input {
  min-height: 125px;
  line-height: 2.5715;
  border: 1px solid #d3d6e1;
  border-radius: 10px;
  transition: all 0.3s, height 0s;
}

.star {
  @include respond(too-big) {
    font-size: 2.5rem;
  }
}

.promotion-card-div {
  margin-left: 1rem;

  @media (max-width: 425px) {
    margin-left: 0rem;
  }
}

.promotion-modal {
  width: 35% !important;

  @media (max-width: 1440px) {
    width: 550px !important;
  }
}

.promotion-modal .ant-card-body {
  @media (max-width: 576px) {
    padding: 10px !important;
  }
}

.promotion-modal .ant-modal-body {
  @media (max-width: 576px) {
    padding: 10px !important;
  }
}

.ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  // border-bottom: 1px solid #D3D6E1;
  padding: 24px;
}

.ant-modal-footer {
  // border-top: none;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
}

.ant-modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #223369;
}

.ant-modal-close-x {
  // margin-top: 8px;
  // margin-right: 12px;

  @media (max-width: 576px) {
    margin-top: 0px;
    margin-right: 0px;
  }
}

.ant-modal-content {
  border-radius: 0.625rem;
}

.read-more {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px;
  cursor: pointer;
  z-index: 1000;
}

.titleoffline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border: none;
  padding: 24px 165px 24px 166px;

  @include respond(tab-land) {
    padding: 24px 16px 24px 16px;
  }

  @include respond(phone) {
    padding: 24px 16px 24px 16px;
  }
}

.titleText {
  color: #223369;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.kwpAUB {
  opacity: 1 !important;
}

.fKlxay {
  width: 25rem !important;
}

.businessUserSLot {
  border-radius: 5px;
  border: 1px solid var(--SECONDRY-20, #d3d6e1);
  background: #fff;
  width: 48vw;
  flex-shrink: 0;
  padding: 24px;

  @media (min-width: 3840px) {
    width: 19vw;
  }

  @media screen and (min-width: 2560px) and (max-width: 3839px) {
    width: 28vw;
  }

  @media screen and (min-width: 1920px) and (max-width: 2550px) {
    width: 39vw;
  }

  @media screen and (min-width: 1680px) and (max-width: 1918px) {
    width: 43vw;
  }

  @media (max-width: 1440px) {
    width: 51vw;
  }

  @media (max-width: 1024px) {
    width: auto;
    padding: 12px;
  }

  @media (max-width: 991px) {
    width: 65vw;
    padding: 12px;
  }
}

.slotDruration {
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}

.slotDruration2 {
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
}

.slotIndector {
  position: relative;
  width: 251px;

  @media (min-width: 2560px) {
    width: 255px;
  }
}

.weekday_dropdown {
  width: 314px;
}

.weekDayTable {
  min-height: 200px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--SECONDRY-20, #d3d6e1);
  background: #fff;
}

.slotTime {
  padding: 4px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--SECONDRY-20, #d3d6e1);
}

.oneSlot {
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.workSched {
  color: var(--Black-60, #666);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sewtWorkHours {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--Primary, #257cdb);
  background: #fff;
}

.dropDown {
  color: var(--Bakc-30, #b3b3b3);

  /* UI/B3/Reg-400 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: none;
  /* 142.857% */
}

.openSlot {
  display: flex;
  width: 250px;
  height: 46px;
  padding: 10px 24px;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--SECONDRY-20, #d3d6e1);
  background: #fff;
}

.weekNames {
  display: flex;
  justify-content: center;
  color: var(--Primary, #257cdb);
  padding-top: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.productDetails {
  width: 93%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  border-radius: 10px;
  // border: 1px solid var(--SECONDRY-20, #D3D6E1);
}

.productDetailsTime {
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  border-radius: 10px;
  // border: 1px solid var(--SECONDRY-20, #D3D6E1);
}

.ant-modal-content {
  z-index: 1050;
}

.container {
  position: relative;
  min-height: 100vh;
  /* Ensure the container covers the full viewport height */
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  filter: blur(25px);
  /* Apply blur filter */
  z-index: -1;
  /* Send the background behind other content */
}

.tour-element {
  border: 2px solid greenyellow;
}

.button-container {
  position: fixed;
  bottom: 150px;
  right: 150px;
}

.selected-tooltip {
  z-index: 9999;
  /* Ensures the tooltip appears above other elements */
  transform: translate(-50%, -20px);
  /* Adjust as needed */
  background-color: rgba(255, 255, 255, 0.9);
  /* Adjust opacity as needed */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Add shadow effect */
}

.blur-background {
  /* Add CSS for background blur effect */
}

.adminCmpn {
  &--search-input {
    padding: 12px;
    height: 46px;
    border-radius: 10px;
    border: 1px solid #d3d6e1;
    margin-bottom: 12px;

    @include respond(phone) {
      border: 1px solid #d3d6e1;
    }
  }

  &--search-input input::placeholder {
    color: #4e5c87 !important;
    font-size: 16px;
    text-align: start;

    @include respond(phone) {
      padding-left: 24px;
      text-align: left;
    }
  }

  &--countrySelect {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-radius: 10px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 46px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 0px;
      display: flex;
      align-items: center;
    }
  }

  &--statusSelect {
    margin-bottom: 12px;

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border: 1px solid #d9d9d9;
      background-color: #fff;
      min-height: 46px;
      height: auto;
      border-radius: 10px;
    }
  }

  &--startEndDate {
    border: 1px solid #d3d6e1;
    background: #ffffff;
    width: 100%;
    height: 96px;
    padding: 12px;
    border-radius: 10px;
  }

  &--datePicker {
    background: #f4f5f7;
    width: 100%;
    height: 32px;
    padding: 6px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  &--pieDiv {
    width: 22vw;
    min-height: 18vh;
    // padding: 12px 12px 0px 12px;
    border-radius: 10px;
    background: #f4f8fd;
    box-shadow: 0px 4px 4px 0px #0000001a;

    @media (max-width: 1024px) {
      width: 40vw;
    }

    @media (max-width: 605px) {
      width: 56vw;
    }
  }

  &--pieStatusDiv {
    width: 22vw;
    // min-height: 18vh;
    // padding: 12px 12px 0px 12px;
    border-radius: 10px;
    background: #f4f8fd;
    box-shadow: 0px 4px 4px 0px #0000001a;

    @media (max-width: 1024px) {
      width: 40vw;
    }

    @media (max-width: 605px) {
      width: 56vw;
      // padding-bottom: 24px;
    }
  }

  &--pieCol {
    display: flex;
    justify-content: center;
    padding-left: 24px;

    @media (max-width: 800px) {
      padding-left: 0px;
    }

    @media (max-width: 605px) {
      padding-left: 0px;
      padding-top: 24px;
    }
  }

  &--indecatorsPieChart {
    box-shadow: 0px -4px 9px -1px #00000014;
    // width: 304px;
    // height: 60px;
    background: #ffffff;

    border-radius: 0px 0px 10px 10px;
  }

  &--status {
    color: #666666;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &--statusText {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: #333333;
    padding-top: 12px;
  }

  &--inputBars {
    @media (max-width: 1024px) {
      padding-bottom: 24px;
    }
  }
}

.barImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  padding-top: 9px;
}

.barText {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  color: black;
  padding: 2px;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  font-weight: 500;
  font-size: 12px;
}

.barImage:hover {
  transform: translateY(-10px);
}

.barText:hover {
  transform: translateY(0);
}

.locations-table {
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 10px;
    background: transparent;
    margin-right: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1475);
    border-radius: 10px;
  }
}
.locations-table .ant-pagination {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding-top: 10px;
  z-index: 1;
}
.left-button {
  left: 0;
  z-index: 999;
  border-color: transparent;
}
.left-button:hover,
.left-button:focus {
  border-color: transparent;
  color: inherit;
  background-color: transparent;
  box-shadow: none;
  outline: none;
}
.button-div-scroll {
  display: flex;
  align-items: center;
  z-index: 999;
  opacity: 0.5;
  width: 15px;
}
.image-gallery-bullets {
  bottom: 5px !important;
}

.offer-new-card {
  border-radius: 10px;
  border-color: #d3d6e1;
}
.offer-new-card .ant-card-body {
  padding: 12px;
  border-color: #d3d6e1;
  // border: 1px solid #d3d6e1;
  border-radius: 10px;
  background: #fff;
}
.offer-new-card .ant-card-bordered {
  border-radius: 10px;
  border-color: #d3d6e1;
}
.offer-new-card .ant-card .ant-card-bordered {
  border-radius: 10px;
  border-color: #d3d6e1;
}
.offer-card-heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #257cdb;
  text-transform: capitalize;
}
.offer-card-top-heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #333333;
}
.offer-secondary-card {
  // display: flex;
  min-height: 184px;
  border-radius: 5px;
  // min-width: 264px;
  // width: fit-content;
}
.offer-secondary-card .ant-card-body {
  min-height: 184px;
  width: 264px;
  padding: 0px;
  border-radius: 5px;
}
.offer-secondary-card .ant-card-bordered {
  border-radius: 5px;
  height: 184px;
  width: 264px;
}
.lower-offer-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.inner-scroll-offer-card {
  scrollbar-width: none;
  height: 88rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
}
.arrow-button-div {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 222px;
}
.arrow-button-left {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important;
  color: #fff !important;
  border-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important ;
  z-index: 1000;
  position: absolute;
  left: 2%;
  top: 30%;
}
.arrow-button-right {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important;
  color: #fff !important;
  border-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important ;
  z-index: 1000;
  position: absolute;
  right: 2%;
  top: 30%;
}

.arrow-button-left .ant-btn:hover .ant-btn:focus {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important;
  color: #fff !important;
  border-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important ;
}
.arrow-button-right .ant-btn:hover .ant-btn:focus {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important;
  color: #fff !important;
  border-color: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) !important ;
}
.offer-horizontal-scroll-div {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;
  align-items: center;
  scrollbar-color: transparent transparent;
}
.show-scrollbar {
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
}
.overlay-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px; 
  height: 25px;
}
.toggleCollapse {
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  color: #257cdb;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}
.offer-details-main-card{
  background-color: #f5f5f5;
    border: none;
    padding: 0px 165px 0px 166px;
    // padding-top: 60px;

    @include respond(tab-land) {
      padding: 0px 16px 0px 16px;
      padding-top: 60px;
    }

    @include respond(phone) {
      padding: 0px 16px 0px 16px;
      padding-top: 60px;
    }
}