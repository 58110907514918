.fixed-sidenav {
  .app-sidenav-container, // when #app-sidenav is wrapped in a container
  #app-sidenav {
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }

    & + .ant-layout {
      height: 100vh;
      overflow-y: scroll;
    }
  }
}