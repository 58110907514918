.richform {
    &_img {
        font-size: 3.125rem;
        color: #CCC;
        margin-right: 1.5625rem;
    }

    &_bussiness-img {
        width: 100%;
        height: auto;
    }

    &_uploading-img {
        width: 9rem;
        object-fit: contain;
        max-height: 4.5rem;
        margin-right: 2rem;
        cursor: pointer;
    }

    &_img-uploading {
        width: 3.125rem;
        object-fit: contain;
        max-height: 3.125rem;
        margin-right: 1.25rem;
    }

    &_placeholder-img {
        font-size: 3.125rem;
        color: #CCC;
        margin-right: 1.25rem;
    }
}