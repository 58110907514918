.email-card {
    width: 962px;
    height: 758px;
    top: 20px;
    margin: 0 auto !important;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;

    @media screen and (min-width: 768px) and (max-width: 1440px) {
      width: 600px;
      height: 510px;
      top: 9px;
    }

    @media screen and (max-width: 600px) {
      width: 340px;
      height: 530px;
      top: 9px;
    }

    .email-image-wrapper {
      position: relative;
      width: 556px;
      height: 426px;
      background-image: url('../../../assets/images/emailBackgroundImage.svg');
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 768px) and (max-width: 1440px) {
        width: 290px;
        height: 220px;
      }

      @media screen and (max-width: 600px) {
        width: 270px;
        height: 200px;
      }
    }

    .email-image {
      width: 282;
      height: 300;

      @media screen and (min-width: 768px) and (max-width: 1440px) {
        height: 150px;
        width: 150px;
      }

      @media screen and (max-width: 600px) {
        width: 125px;
        height: 125px;
      }      

    }

    .email-description {
      font-size: 36px;
      margin-top: 20px;
      margin-bottom: 24px;
      color: #4D4D4D;
      font-weight: 700;
      line-height: 36px;

      @media screen and (min-width: 768px) and (max-width: 1440px) {
        font-size: 22px;
        margin-top: 12px;
        margin-bottom: 12px;
        line-height: 22px;
      }

      @media screen and (max-width: 600px) {
        font-size: 17px;
        margin-top: 20px;
        margin-bottom: 10px;
        line-height: 17px;
      }
    }
  
    .email-subdetails {
      font-size: 18px;
      color: #666666;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 18px;

      @media screen and (min-width: 768px) and (max-width: 1440px) {
        font-size: 16px;
        line-height: 16px;
      }

      @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 16px;
        line-height: 18px;
      }
    }

    .email-subdetails2{
      font-family: Roboto;
      font-size: 18px;
      color: #666666;
      font-weight: 400;
      line-height: 27px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  
    .ant-btn-primary {
      background-color: #223369; 
      color: #fff;
      border-radius: 10px;
      height: 46px;
      width: 435px;

      @media screen and (max-width: 600px) {
        height: 40px;
        width: 270px;
      }
    }
  
    .email-btn {
      border: 1px solid #223369;
      color: #223369;
      border-radius: 10px;
      height: 40px;

      @media screen and (max-width: 600px) {
        height: 40px;
        width: 270px;
      }
    }

    .email-unsubscribe-input {
      height: 46px;
      width: 435px;
      border-radius: 10px;
      margin-bottom : 12px;

      @media screen and (max-width: 600px) {
        height: 40px;
        width: 270px;
      }
    }
  }
  