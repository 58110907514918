// Add vertical divider to .list-inline
.list-inline-split {
  li {
    position: relative;
    &:not(:last-child) {
      padding-right: $list-inline-padding;
      &:after {
        content: '';
        position: absolute;
        background-color: rgba($dark, .2);
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        margin-top: -6px; // height/2
        height: 12px;
      }
    }
  }
}
.list-style-v1 {
  width: 100%;
  .list-item {
    display: flex;
    align-items: flex-start;
    &--offer-discount {
      font-size: small;
      font-weight: bold;
      color: forestGreen;
    }
    &--offer-discountdiv {
      display: flex;
      height: 1.4rem;
      align-items: center;
      background-color: paleGreen;
      padding: 0 0.5rem 0 0.5rem
    }
    &--offersoldout {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: lightpink;
      padding: 0 0.7rem 0 0.7rem;
      height: 2.2rem;
      border-radius: 0.2rem;
    }
    &--editbutton {
      background-color: white;
      color: black;
      border-color: grey
    }
    // &--totalofferssold {
    //   margin-top: 1rem;
    //   margin-left: 1rem;
    // }
    // &--campaignpromo {
    //   overflow-y: scroll;
    //   height: 50rem
    // }
  }
  .list-item__body {
    flex: 1;
  }
  .list-item__title {
    text-transform: capitalize;
    .ant-tag {
      float: right;
    }
  }
  .list-item__desc {
    margin-top: .4rem;
    opacity: $text-secondary;
    font-size: $font-size-sm;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .list-item__datetime {
    margin-top: 3px;
    opacity: $text-secondary;
    font-size: $font-size-sm;
  }
  .ticket {
    position: absolute;
    // overflow: hidden;
    // transform: matrix(1rem, 0, 0, 1rem, 0, 0);
    // width: 5rem;
    margin-top: -2rem;
    right: 10px;
    // border: 2px solid white;
    background: red;
    color: white;
    // text-transform: uppercase;
    text-align: center;
    display: inline-block;
    padding: 0.6rem 1.5rem;
  }
  .ticket:before,
  .ticket:after {
    height: 90px;
    width: 45px;
    content: '';
    position: absolute;
    top: 0.9em;
    top: calc(0.9em - 1px);
    height: 1rem;
    width: 0.6em;
    border: 2px;
    background-color: white;
  }
  .ticket:before {
    left: -2px;
    border-radius: 0 1em 1em 0;
    border-left-color: white;
  }
  .ticket:after {
    right: -2px;
    border-radius: 1em 0 0 1em;
    border-right-color: white;
  }
}
