.verifymobilemodal {
    font-size: medium;


    &_verification_done {
        text-align: center;
    }

    &_input {
        margin-top: 3rem;
        padding: 0.5rem
    }

    &_resend_button {
        margin-top: 3rem;
        padding: 0.5rem
    }

    &_icon {
        font-size: 9rem;
        color: #007EFF;
    }

    &_button {
        padding: 0.5rem 12rem 2.5rem;
        border-radius: 1rem;
        color: white;
        font-size: medium;
        background-color: #007EFF
    }


}