.ribbon-container {
  position: relative;
  display: block; // when used on inline elements like `a`

  .ribbon-wrapper {
    position: absolute;
    overflow: hidden;
    width: 85px;
    height: 88px;
    top: -3px;
    right: -3px;
  }
 
  @keyframes bg {
    0% { background-color: orangered }
    50% { background-color: #FFE606 }
    100% { background-color: orangered }
  }
    
  $bg : #FFE606;
  $bgGreen : #FFE606;
  $hole : .3em;
  .ribbon-wrapper_updated {
    position: absolute;
    overflow: hidden;
    // transform: matrix(1rem, 0, 0, 1rem, 0, 0);

    // width: 85px;
    margin-top: 10px;
    right: 10px;
    // // &:before,
    // // &:after {
    // //   .dot {
    // //     height: 25px;
    // //     width: 25px;
    // //     background-color: #bbb;
    // //     border-radius: 50%;
    // //     display: inline-block;
    // //   }
    // // }
    // // &:before {
    // //   .dot {
    // //     height: 25px;
    // //     width: 25px;
    // //     background-color: #bbb;
    // //     border-radius: 50%;
    // //     display: inline-block;
    // //   }
    // // }
    // // &:after {
    // //   right: 0;
    // // }

    // display: inline-block;
    box-sizing :content-box;
    // filter: drop-shadow(0 2px 5px rgba(black,.5));
    // position :absolute;
    height :0.5rem;
    width: 5rem;
    padding :0.8rem;
    justify-content: center;
    // color: black;
    // font-size: 16px;
    border-radius: 5%;
    background-size: 51% 100% ;
    background-repeat: no-repeat;
    background-image :radial-gradient(circle at 0 50%, rgba($bg,0) $hole, $bg ($hole + .1em)), radial-gradient(circle at 100% 50%, rgba($bg,0) $hole, $bg ($hole + .1em));
    background-position :top left, top right;
  }
  
  .ticket {
    position: absolute;
    // overflow: hidden;
    // transform: matrix(1rem, 0, 0, 1rem, 0, 0);

    // width: 5rem;
    margin-top: 15px;
    right: 10px;
    // border: 2px solid white;
    background: $bg;
    color: white;
    // text-transform: uppercase;
    text-align: center;
    display: inline-block;
    padding: 0.6rem 1.5rem;
}
// @media (min-width:320px) and (max-width:376px) {
//   .ticket {
//     position: relative;
//     left: 10.5rem;
//   }
// }
// @media (max-width: 321px){
//   .ticket {
//     position: relative;
//     left: 7rem;
//   }
// }
@media (max-width: 768px) {
  .ticket {
    // position: relative;
    // left: 0rem;
    display: none;
  }
}

.ticket:before,
.ticket:after{
    height:90px;
    width:45px;
    content: '';
    position: absolute;
    top: 0.9em;
    top: calc(0.9em - 1px);
    height:1rem;
    width: 0.6em;
    border: 2px ;
    background-color: #F4F8FD;
}
.ticket:before {
    left: -2px;
    border-radius: 0 1em 1em 0;
    border-left-color: #F4F8FD;
}
.ticket:after {
    right: -2px;
    border-radius: 1em 0 0 1em;
    border-right-color: #F4F8FD;
}
.ticketGreen {
  position: absolute;
  // overflow: hidden;
  // transform: matrix(1rem, 0, 0, 1rem, 0, 0);

  // width: 5rem;
  margin-top: 15px;
  right: 10px;
  // border: 2px solid white;
  background: $bgGreen;
  color: white;
  
  // text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 0.6rem 1.5rem;
}
// @media (min-width:320px) and (max-width:376px) {
//   .ticketGreen {
//     position: relative;
//     left: 0rem;
//   }
// }
// @media (max-width: 321px){
//   .ticketGreen {
//     position: relative;
//     left: 0rem;
//   }
// }
@media (max-width:768px) {
  .ticketGreen {
    // position: relative;
    // left: 0rem;
    display: none;
  }
}

.ticketGreen:before,
.ticketGreen:after{
  height:90px;
  width:45px;
  content: '';
  position: absolute;
  top: 0.9em;
  top: calc(0.9em - 1px);
  height:1rem;
  width: 0.6em;
  border: 2px ;
  background-color: #F4F8FD;
}
.ticketGreen:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: #F4F8FD;
}
.ticketGreen:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: #F4F8FD;
}

.ticketRed {
  position: absolute;
  // overflow: hidden;
  // transform: matrix(1rem, 0, 0, 1rem, 0, 0);

  // width: 5rem;
  margin-top: -15px;
  // right: 10px;
  // border: 2px solid white;
  background: red;
  color: white;
  // text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding: 0.6rem 1.5rem;
}
@media (min-width:320px) and (max-width:376px) {
  .ticketRed {
    position: relative;
    left: 10.5rem;
  }
}
@media (max-width: 321px){
  .ticketRed {
    position: relative;
    left: 7rem;
  }
}
@media (min-width:376px) and (max-width:426px) {
  .ticketRed {
    position: relative;
    left: 11rem;
  }
}
.ticket:before,
.ticket:after{
  height:90px;
  width:45px;
  content: '';
  position: absolute;
  top: 0.9em;
  top: calc(0.9em - 1px);
  height:1rem;
  width: 0.6em;
  border: 2px ;
  background-color: #F4F8FD;
}
.ticket:before {
  left: -2px;
  border-radius: 0 1em 1em 0;
  border-left-color: #F4F8FD;
}
.ticket:after {
  right: -2px;
  border-radius: 1em 0 0 1em;
  border-right-color: #F4F8FD;
}
  .ribbon {
    // position: relative;
    // display: block;
    text-align: center;
    font-size: 13px;
    color: black;
    // transform: rotate(45deg);
    // padding: 4px 0;
    border-radius: 4px;
    // left: -5px;
    // top: 15px;
    // width: 120px;
    // line-height: 20px;
    // background-color: $gray-700;
    // box-shadow: 0 0 3px rgba(0,0,0,0.3);
    // &:before,
    // &:after {
    //   position: absolute;
    //   content: '';
    //   line-height: 0;
    //   border-top: 2px solid $gray-700;
    //   border-left: 2px solid transparent;
    //   border-right: 2px solid transparent;
    //   bottom: -2px;
    // }
    // &:before {
    //   left: 0;
    //   bottom: -1px;
    // }
    // &:after {
    //   right: 0;
    // }
  }

  // color
  &.ribbon-primary { @include ribbon-variant ($primary); }
// @media (min-width:375px)and (max-width:426px){
// &.ribbon-success .ant-card-body {
//   width:90vw;
// }
// }
// @media (min-width:375px)and (max-width:426px){
// &.ribbon-info {
//   width:90vw
// }
// }

  &.ribbon-warning { @include ribbon-variant ($warning); }
  &.ribbon-danger { @include ribbon-variant ($danger); }
}