/* Calendar.css */
.calendar-container {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 20px;
}
.calButton {
  width: 100px;
  height: 70px;
  border-radius: 10px, 0px, 0px, 0px;
  box-shadow: 4px 0px 4px 0px #0000000d;

  align-items: center;
  display: flex;
  justify-content: center;
}
.btnCal {
  // border: 1px solid #d3d6e1;
  align-items: center;
  border-radius: 10px, 0px, 0px, 0px;
}
.CalendarBackIcon{
width: 46px;
height: 46px;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.daysWeek {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  // padding-left: 50px;
  padding-bottom: 24px;
}

.dayName {
  color: #223369;

  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.days {
  // padding-left: 16px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}



.selected {
  background-color: #3498db;
  color: #fff;
}

.blank {
  visibility: hidden;
}

/* Calendar.css */
/* ... existing styles ... */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.month {
  cursor: pointer;
  width: 90px !important;
  height: 46px !important;
  //   top: 24px;
  //   left: 118px;
  // padding: 9.5px, 24px, 9.5px, 24px;
  border-radius: 5px;

  //   gap: 10px;
  border: 1px solid #d3d6e1;
}
.overflow{
  overflow-x: scroll;
}
.monthName {
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

// .month:hover {
//   background-color:#223369;
// }

.selected {
  background-color: #223369;
  color: #fff;
}

.calendarView {
  padding: 16px 0px 0px 0px;
  // width: 1028px;
  height: auto;
  // top: 106px;
  // left: 12px;
  border-radius: 10px;
 
  background: #ffffff;
  border: 1px solid #d3d6e1;
}
.cal {
  padding-bottom: 24px;
  padding-left: 16px;
}
.dropDown {
  width: 261px;
  height: 46px;
  border: 1px solid #d3d6e1;
}
.day {
  width: 104px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  // border: 1px solid #d3d6e1;
  position: relative;
  @media screen and (min-width:991px) and (max-width:1024px) {
    width: 82px;
  }
  @media screen and (min-width:600px) and (max-width:991px) {
    width: 54px;
  }
  @media (max-width:576px) {
    width: 25px;
  }
}
.dayMobile {
  width: 25px;
  height: 60px;
  border-radius: 5px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // cursor: pointer;
  // border: 1px solid #d3d6e1;
  // position: relative;
  
}
.countAppointment {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  right: 8px;
  @media screen and (min-width:991px) and (max-width:1024px) {
    width: 25px;
    height: 25px;
  }

  @media screen and (min-width:600px) and (max-width:991px) {
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 100%;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 4px;
    right: 8px;
  }
}
.slotInstruction{
  width: 100%;
margin-top: 12px;
height: 60px;
border-radius: 10px;
border:1px solid #D3D6E1;
background: #FFFFFF;
z-index: 1001;
padding: 15px ;
// padding: 12px 0px 0px 12px ;

@media screen and (min-width:600px) and (max-width:768px) {
  height: auto;
}

}