.featured {
  &_timeLimit {
    font-size: 14px !important;
    line-height: 25px;
    font-weight: 600;
  }

  &_mainConatainer {
    width: 100%;
    border-radius: 10px;
    padding: 20px 0px 24px 8px;
  }

  &_textFeatured {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #333333;
    padding-bottom: 20px;
    padding-left: 12px;
  }
  &_retailPriceText{
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
  }

  &_featuredCards {
    height: 284px;
    border-radius: 5px;
    background: #ffffff;
    width: 300px;
    display: table-cell;
  }

  &_address {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px;

    @media (max-width: 768px) {
      padding-top: 4px;
    }
  }

  &_featured-list {
    padding: 12px;
     min-height: 26rem;
    max-height: 26rem;

    // min-height: 22rem;
    // max-height: 30rem;
    cursor: pointer;
    border-radius: 5px;
    padding-bottom: 12px;
    // @include respond(too-big) {
    //   min-height: 50rem;
    //   max-height: 50rem;
    //   border: 5px solid red;
    // }
    @media (min-width: 3840px) {
      min-height: 24rem;
      max-height: 24rem;
    }
    @media screen and (min-width: 2560px) and (max-width: 3800px)
   {
      min-height: 20rem;
      max-height: 20rem;
    }
   

    // @include respond(mac) {
    //   min-height: 25rem;
    //   max-height: 25rem;
    // }
    @media screen and (min-width: 1366px) and (max-width: 1400px) {
      min-height: 28rem;
      max-height: 28rem;
    }

    @media screen and (min-width: 1280px) and (max-width: 1365px) {
      min-height: 34rem;
      max-height: 34rem;
    }

    @media (max-width: 1200px) {
      min-height: 34rem;
      max-height: 34rem;
    }

    @media (max-width: 992px) {
      min-height: 36rem;
      max-height: 36rem;
    }

    @media screen and (min-width: 769px) and (max-width: 850px) {
      min-height: 38rem;
      max-height: 38rem;
    }

    @media (max-width: 768px) {
      min-height: 38rem;
      max-height: 38rem;
    }

    @media (max-width: 576px) {
      min-height: 39rem;
      max-height: 39rem;
      border-radius: 7.795px;
    }
  }

  &_cmpnName {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: flex-start;
    text-align: -webkit-left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }

  &_crownIcon {
    width: 28px;
    height: 28px;
  }

  &_chips {
    @include respond(small-mac) {
      display: flex;
      justify-content: flex-start;
    }
  }

  &_types {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #257cdb;
    // display: contents;
    display: flex;
    justify-content: flex-start;
    text-align: -webkit-left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_typesCmpn{
    font-size: 14px;
    font-weight: 400;
    // line-height: 5px;
    color: #257cdb;
    display: contents;
    
  }

  &_subscriptionLimit {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
    display: flex;
    justify-content: flex-start;
    text-align: -webkit-left;


    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      font-size: 12px;
    }
  }

  &_ribbon {
    width: 118px;
    height: 23px;

    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 100px;
    }

    @media (max-width: 576px) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &_chipsText {
    position: absolute;
    top: 0;
    color: black;
    width: 118px;

    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 100px;
    }
  }

  &_addresCmpn {
    display: flex;
    justify-content: flex-start;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: -webkit-left;
    color: #666666;
  }

  &_featured-img {
    object-fit: cover;

    border-radius: 5px;
    width: 100%;
    min-height: 10.9rem;
    max-height: 10.9rem;

    @include respond(too-big) {
      min-height: 14rem;
      max-height: 14rem;
    }

    @media (max-width: 992px) {
      min-height: 15rem;
      max-height: 15rem;
    }

    @media (max-width: 768px) {
      min-height: 15.5rem;
      max-height: 15.5rem;
    }

    @media (max-width: 576px) {
      min-height: 18rem;
      max-height: 18rem;
      border-radius: 7.795px;
    }
  }

  &_disc {
    width: 67px;
    height: 24px;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    background: #50b85a;

    @media screen and (min-width: 1366px) and (max-width: 1440px) {
      padding: 4px 10px 4px 6px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 4px 4px 4px 6px;
    }
  }

  &_cmpType {
    padding-top: 12px;
  }

  &_offerPrice {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  &_retailPrice {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #b3b3b3;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.featuredSubs {
  &_table {
    background-color: white;
    width: 100%;


    @include respond(tab-port) {
      overflow: auto;
    }

    @include respond(desktop) {
      overflow: auto;
    }

    &::-webkit-scrollbar {
      height: 10px;
      background: transparent;
      margin-right: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.1475);
      border-radius: 10px;
    }
  }

  &_btn {
    height: 46px;
    padding: 3px 12px;
    border-radius: 8px;
    margin-right: 12px;
    border: 1px solid #257CDB;
    color: #257CDB;
  }

  &_btn-subs {
    height: 46px;
    padding: 3px 12px;
    border-radius: 8px;
    margin-right: 12px;
    border: 1px solid #32871E;
    color: #32871E;

    &:hover {
      border: 1px solid #32871E;
      color: #32871E;
    }
    &:active{
      border: 1px solid #32871E;
      color: #32871E;
    }
    &:focus{
      border: 1px solid #32871E;
      color: #32871E;
    }
  }

  &_text {
    padding: 0px 24px;
    color: #223369;
    font-weight: 400;
    font-size: 20px;
  }

  &_div {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    border: 1px solid #D3D6E1;
    padding: 12px;
    border-radius: 10px;
  }

  &_card {
    padding: 20px 12px;
    border-radius: 8px;
    border: 1px solid #D3D6E1;
    width: 100%;
    margin: 12px 0px 0px 0px;


    &_btn {
      padding: 10px 24px 10px 24px;
      border-radius: 10px;
    }
  }

  &_pay-btn {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #257CDB;
    padding: 0px;
  }

  &_divider {
    background-color: #D3D6E1;
    margin: 12px 0px !important
  }

  &_row {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #4D4D4D;
  }

}


.featuredSubs_table .ant-table-title {
  color: #257CDB;
  text-align: left;
  border: none;
}

.featuredSubs_table ::-webkit-scrollbar {
  height: 10px;
  background: transparent;
  margin-right: 4px;
}

.featuredSubs_table ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1475);
  border-radius: 10px;
}


.featuredSubs_table .ant-table-cell {
  background-color: white;
  color: #666666;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.featuredSubs_table .ant-table-thead .ant-table-cell {
  color: #257CDB;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.featuredSubs_table .ant-table-container table>thead>tr {
  border-top-left-radius: 0%
}

.featuredSubs_table .ant-table {
  border: none;
  // overflow-x: scroll;
  scrollbar-width: thin;
}

.featuredSubs_table .ant-table-container {
  border: none;
}

.featuredSubs_table.ant-table table {
  border-collapse: collapse;
}

.featuredSubs_table .ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  border: none;
}

.featuredSubs_table .ant-table-container::before,
.ant-table-container::after,
.ant-table-content::before,
.ant-table-content::after {
  display: none;
}

.featuredSubs_customtabs .ant-tabs-nav .ant-tabs-tab {
  border-radius: 10px;
  padding: 10px 36px;
  transition: all 0.3s;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.featuredSubs_customtabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #257CDB;
  border: 1px solid #257CDB;
  color: white;
}

.featuredSubs_customtabs .ant-tabs-nav .ant-tabs-tab {
  // border: 1px solid #D3D6E1;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
}

.featuredSubs_customtabs .ant-tabs-nav-wrap {
  border: 1px solid #D3D6E1;
  border-radius: 10px;
  width: 100%;

}

.featuredSubs_customtabs .ant-tabs-nav-operations {
  display: none;
}

.featuredSubs_customtabs .ant-tabs-nav::before {
  border: none;
}

.featuredSubs_customtabs .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

.featuredSubs_customtabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.featuredSubs_customtabs .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.featuredSubs_customtabs .ant-tabs-tab {
  flex: 1;
  text-align: center;
}

.featuredSubs_customtabs .ant-tabs-content-holder {
  width: 100%;
}

.featuredSubs_customtabs .ant-tabs-tabpane {
  width: 100%;
}

.featuredSubs_customtabs .ant-tabs-nav-operations {
  display: none;
}

.featuredSubs_customtabs .ant-tabs-nav-wrap {
  @include respond(tab-port) {
    overflow: scroll !important;
  }
}

.featuredSubs_cancelPop {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.featuredSubs_cancelPara {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px
}

.updateSubspopup {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-bottom: 12px;

  &_note {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 24px;
    color: #7F7F7F;
  }
  &_note2{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 12px;
    color: #7F7F7F;

  }
  &_confirm{
    margin:12px 0px;
    text-align:center;
    font-size:20px;
    font-weight:400;
    line-height:20px;
    transition:ease-in;
    color:#257CDB
  }
}