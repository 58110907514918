.card-receiver {
    .ant-card-head-title {
      padding: 12px 0px 12px 6px;
    }
    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }

    .card-receiver {
      width: 100%;
      cursor: pointer;
      border-radius: 10px;
    
    
      .form-container {
        width: 384px;
        height: 450px;
        padding: 20px 15px 5px 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px 0px #0000001A;
        border-radius: 10px;
      }
    
      .form-input {
        position: relative;
      }

      .submit-button {
        width: 232px;
        height: 36px;
        padding: 5px 8px 5px 8px;
        gap: 8px;
        border-radius: 5px;
        border: 1px 0px 0px 0px;
        background: #223369;
      }
    }
    .custom-input.ant-input:focus{
      box-shadow: none;
    }


      .read-more-modal {
        .ant-modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: none;
        }
        .close-icon {
          cursor: pointer;
        }
      }
      .read-more-body{
        text-align: left;
        padding: 15px; 
        margin: 0;
        font-weight: 400;
       }
      

.form-details-container { 
        // background-color: #FFF;
        color: #257CDB;
        width: 100%;
        margin-top: 5px;
}

.country-code-dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 5px;
  height: 40px ;
  padding : 6px 6px;
}

.country-code-dropdown .ant-select-arrow {
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 65%;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}