
.headingorder{
margin-left: -2rem;
}


.headingitem{
    padding-left:3.5rem;
    // 4
}

.headingpriceperitem{
    padding-left:1.1rem;
}

.headingavailablefrom{
    margin-left:1.7rem
}

.headingactive{
    margin-left:0.2rem;
    // 0
}

.headingunit{
    margin-left: 2.2rem;
}

.headingactions{
    margin-left:2.5rem;
}

.colpriceperitem{
    margin-left:1.3125rem;
}

.btnsaveandaddmore{
    margin-right:9rem;
    max-width:10rem;
    font-size: 0.9rem;; 
   
   
}