.indv-campaign-list {
  background: linear-gradient(90deg, #deecff 0%, #ffffff 100%);
}

.row-outer {
  width: 100%;
  border: 1px solid #d3d6e1;
  height: auto;
  border-radius: 9px;
  @media (max-width: 767px) {
    height: 8rem;
  }
}
.expired-image {
  width: 8vw;
  height: 8vw;
  padding-top: 8px;
  @media (max-width: 768px) {
    width: 10vw;
  }
}

.offerImg{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-left: 8px;
  }
}
.offerTitle-container{
  padding: 12px;
  @media (max-width:991px) {
    padding: 8px;
  }
}
.offerImg-img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.offerTitle{
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  @media (max-width:770px) {
    font-size: 15px;
    font-weight: 500;
    
  }
}
.item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-top: 16px;
  @media (max-width: 1024px) {
    // height: 8rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    margin-top: 5px;
  }
  @media (max-width: 991px) {
    // height: 8rem;
    font-size: 10px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    margin-top: 5px;
  }
  @media (max-width: 770px) {
    // height: 8rem;
    font-size: 9px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    margin-top: 8px;
  }
}

.button-col-end {
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
}
.title-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }
}
.featured-image {
  height: 80%;
  width: 80%;
}
.dots-filter {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.outer-card .ant-card-body {
  // padding: 12px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.sticky-div{
  background: rgba(255, 255, 255, 0.85);
  position: sticky;
  top: 0;
  opacity: 1;
  padding: 7px 0px;
  z-index: 100;
  padding: 0px;
  height: 24px;
}

.featuredBtn{
  display: flex !important;
  flex-direction: column;
  justify-content:flex-end;
  align-items: flex-end;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
}

.modal-past-promotions .ant-modal-body {
  // background-color: aqua;
  padding: 8px;
}

.blurred {
  opacity: 0.5;
  pointer-events: none;
}

.terms-and-conditions .ant-steps-item  {
  flex: 1 1 auto !important;
}