.reviewcomments {
  &_ratingCards {
    width: 15.563rem;
    height: 10.563rem;
    border-radius: 10px;
  }

  &_ratingCards-mobile {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
  }

  &_mainContainerRow-comment {
    background: #f4f8fd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding: 16px 24px 24px 23px;
  }

  &_mainContainerRow-mobile {
    background: #f4f8fd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding-top: 14px;
    margin-bottom: 1.5rem;
    min-width: 100%;
  }

  &_replyCommentModal {
    width: 600px;
  }

  &_replyCommentModal .ant-modal-content {
    border-radius: 10px;

    @include respond(too-big) {
      width: 35vw;
      height: auto;
    }
  }

  &_replyCommentModal .ant-modal-header {
    border-radius: 10px 10px 0 0;
    padding: 19px 24px;
    border-bottom: 2px solid #d3d6e1;

    @include respond(xs) {
      padding: 17px 24px;
    }
  }

  &_scrollControler {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    cursor: grab;
    margin-top: 12.59px;
    max-width: 68vw;

    &::-webkit-scrollbar {
      height: 7px;
      background: transparent;
      margin-top: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d6e1;
      border: 1px solid rgba(211, 214, 225, 0.1475);
      border-radius: 10px;
      margin-top: 1rem;
    }
  }

  &_comments {
    height: auto;
    background: #eaf2fc;
    border-radius: 0.625rem;
    margin-right: 1.5rem;
    padding-bottom: 2rem;
    width: 100%;
  }

  &_replyCommnetButton {
    font-size: 1rem;
    // height: 2.87rem;
    align-items: center;
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    color: #257CDB;
    padding: 0rem 4rem;


    @include respond(too-big) {
      font-size: 2rem;
      height: 4rem;
      display: flex;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin-top: 1.5rem;

      .reviewcomments_commentBtnIcon {
        padding-left: 0.6rem;
        width: 3rem;
        padding-top: 0.4rem;
      }

      .reviewcomments_commentBtnIconDisabled {
        padding-left: 0.6rem;
        width: 3rem;
        padding-top: 0.4rem;
      }
    }
    @media (max-width: 1200px) {
      font-size: 1.5rem;
      width: 15rem;
    }

    @include respond(xs) {
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8.5px 24px;
      gap: 8px;
      height: 36px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  }

  &_hoverButton:hover {
    background: #257cdb !important;
    color: white !important;
  }

  &_commentBtnIcon {
    padding-left: 0.6rem;
    width: 2rem;

    @media (max-width: 1200px) {
      width: 2.4rem;
      vertical-align: middle;
    }
    @include respond(xs) {
      width: 2.5rem;
    }
  }

  &_commentBtnIconDisabled {
    padding-left: 0.6rem;
    width: 2rem;

    @media (max-width: 1200px) {
      width: 2.4rem;
    }
    @include respond(xs) {
      width: 2.5rem;
    }
  }

  &_filterCommnetButton {
    width: 195px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #d3d6e1;
    border-radius: 5px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #223369;
  }

  &_filter {
    font-size: 1.5rem;
    padding-left: 3rem;
  }

  &_seeAllButton {
    display: flex;
    justify-content: end;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #257cdb;

    .ant-btn {
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      border: 0px solid transparent;
      box-shadow: none;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;
      border-color: #d9d9d9;
    }
  }

  &_seeAllButton-mobile {
    display: flex;
    justify-content: end;
    line-height: 30px;
    text-align: center;
    color: #257cdb;

    .ant-btn {
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      border: 0px solid transparent;
      box-shadow: none;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 0px;
      font-size: 14px;
      border-radius: 2px;
      border-color: #d9d9d9;
    }
  }

  &_SeeAllbtn {
    color: #257cdb;
    font-weight: 500;
    font-size: 16px;

    @media (min-width: 3455px) {
      font-size: 2rem;
    }
  }

  &_replyCommnetDiv {
    margin-bottom: 24px;
  }

  &_userimage {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 20px;
  }

  &_userName {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding-top: 0.5rem;
    color: #666666;

    @media (min-width: 3455px) {
      font-size: 1.7rem;
      padding-top: 0.8rem;
    }
  }

  &_replyUserName {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    padding-left: 12px;
    padding-top: 0.5rem;
    color: #666666;

    @include respond(xs) {
      padding-top: 0rem;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &_ratingDuration {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    text-align: right;
    padding-left: 12px;
    padding-top: 0.5rem;
    color: #7f7f7f;

    @media (min-width: 3455px) {
      font-size: 1.4rem;
      padding-top: 0.8rem;
    }
  }

  &_ratingDuration-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: right;
    padding-left: 12px;
    padding-top: 0.5rem;
    color: #7f7f7f;
  }

  &_replyRatingDuration {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    padding-left: 12px;
    padding-top: 0.5rem;
    color: #7f7f7f;

    @include respond(xs) {
      padding-top: 0rem;
      font-weight: 300;
      font-size: 12px;
    }
  }

  &_outlined {
    font-size: 1.3rem;
    padding-left: 0.8rem;
  }

  &_divider-mobile {
    width: 319px;
    height: 0px;
    top: 151px;

    border: 1px solid #d65514;
  }

  &_exprience {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  &_excellent {
    font-style: normal;
    width: 75px;
    height: 25px;
    font-size: 12px;

    border: 1px solid #008b0e;
    background-color: #c0e8c4;
    border-radius: 15px;
    color: #008b0e;
    text-align: center;
  }

  &_userName-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding-left: 12px;
    padding-top: 0.5rem;
  }

  &_descriptio-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7f7f7f;
  }

  @media only screen and (min-width: 767px) and (max-width: 992px) {
    &_replyCommnetButton {
      font-size: 1.4rem;
      vertical-align: middle;
      height: 3.75rem;
      width: 13rem;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    &_commentBtnIcon {
      padding-left: 0.6rem;
      width: 3rem;
    }

    &_commentBtnIconDisabled {
      padding-left: 0.6rem;
      width: 3rem;
    }


    &_mainContainerRow-comment {
      background: #f4f8fd;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0.625rem;
      padding-left: 24px;
      padding-top: 22px;
      margin-bottom: 1.5rem;
    }
  }
}

.reviewcomments_replyCommnetButton:hover .reviewcomments_commentBtnIcon {
  filter: brightness(0) invert(1);
}

.ant-btn-primary[disabled] {
  background-color: #CCCCC8 !important;
  border-color: #CCCCC8 !important;
  color: #7f7f7f !important
}

svg {
  overflow: hidden;
  vertical-align: top;
}

.commentReply {
  width: 100%;
  padding: 1.5rem;
  background: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 1rem;

  @include respond(too-big) {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.commentReplyDiv {
  padding-top: 12px;
  overflow-y: scroll;
  max-height: 22rem;

  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d6e1;
    border: 1px solid rgba(211, 214, 225, 0.1475);
    border-radius: 10px;
  }
}

.userCommentText {
  padding-top: 12px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  color: #3a3a3a;

  @include respond(xs) {
    font-size: 14px;
    font-weight: 300;
    color: #4d4d4d;
  }

  @media (min-width: 3455px) {
    .ant-comment-content {
      position: relative;
      flex: 1 1 auto;
      min-width: 1px;
      font-size: 40px;
      word-wrap: break-word;
    }
  }
}

.userCommentText .ant-comment-nested {
  margin-left: 0;
}

.ant-comment-inner {
  padding: 0 0;
}

.particular-review {
  &_mainDiv {
    padding: 32px 0px 0px 0px;
  }

  &_leftSideCol1 {
    padding: 12px 24px 24px 24px;
    width: 100%;
    background: #eaf2fc;
    border-radius: 10px;
  }

  &_leftCardDiv {
    padding: 0.5vw 0.5vw 0vw 0.5vw;
    width: 100%;
    border-radius: 10px;
    max-height: 180px;
  }

  &__rightColCardDiv {
    width: 100%;
  }

  &__rightCol {
    width: 100%;
  }

  &_row2nd {
    width: 100%;
  }

  &_imageDiv {
    padding: 12px;
    width: 100%;
  }

  &_reviewStarDiv {
    padding-left: 12px;
    padding-top: 4px;
  }

  &_details {
    margin: 0px 24px 24px 24px;
    background: #eaf2fc;
    padding: 24px 24px 24px 24px;
    border-radius: 10px;
  }

  &_username {
    color: var(--black-60, #666);
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    font-style: normal;
    padding-left: 12px;
    padding-top: 0.5rem;
  }

  &_time {
    color: var(--black-40, #999);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    padding-left: 12px;
    padding-top: 0.5rem;
  }

  &_gobackbtn {
    color: #3a3a3a;
    font-size: 16px;
    line-height: 26px;
    margin: 25px 0px;
    padding: 0%;
    vertical-align: middle;
  }

  &_submitbtn {
    border-radius: 5px;
    background: #257cdb;
    padding: 10px 114px;
    align-self: center;
    height: 46px;
    font-size: 16px;

    :hover {
      background-color: #4f96e3;
      color: white;
    }

    :active {
      background-color: #223369;
    }

    @include respond(phone) {
      padding: 0px 6px;
    }
  }

  &_mainContainerRow {
    padding-top: 24px;
    margin-bottom: 1.5rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--bkacl-20, #ccc);
    background: #fff;
  }

  &_img {
    width: 100%;
    height: 234px;
    border-radius: 10px;
    object-fit: cover;
  }

  &_comments {
    padding-top: 12px;
    border-radius: 10px;
    background: #eaf2fc;
    width: 100%;
    min-height: 600px;
  }

  &_text {
    width: 100%;
    min-height: 218px;
    line-height: normal;
    border-radius: 10px;
    margin: 0px 24px 24px;
    padding: 12px;
    background: #fff;
    line-height: normal;
  }

  &_imagePopUp {
    width: 100%;
    height: 134px;
    border-radius: 10px;
  }

  &_cardPopUp .ant-card-body {
    border-top: 1px solid #d3d6e1 !important;
    background-color: "#EAF2FC";
    border-radius: "10px";
    padding: 12px 24px 24px 24px !important;
  }

  &_cardPopUp {
    background-color: #eaf2fc;
    border-radius: 10px;
    margin-left: 0px;
  }

  &_textClassNameForPopUpText {
    width: 346px;
    height: 208px;
    border-radius: 10px;
    background: #fff;
  }

  &_textArea {
    color: #3a3a3a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 12px;

    background-color: #fff;
    height: 243px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d6e1;
      border-radius: 10px;
    }
  }

  &_textArea .ant-card-body {
    padding: 12px;
  }

  &_cardPopUpText {
    background-color: #fff;
    border-radius: 10px;
    padding: 8px 12px;
    color: #3a3a3a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  &_cardPopUp .ant-card-head-title {
    color: #223369;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    padding: 14px 0px !important;
    font-family: Roboto;
  }

  &_replyOverflow {
    overflow-y: scroll;
    max-height: 280px;

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d6e1;
      border-radius: 10px;
    }
  }

  &_replyCard {
    overflow-y: scroll;
    max-height: 227px;

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d6e1;
      border-radius: 10px;
    }
  }
}

.card1 {
  height: 315px;
}

.textcard1 {
  min-height: 64px;
  margin-top: 8px;
}

.textcard2 {
  height: 218px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d6e1;
    border-radius: 10px;
  }
}

.particular-reviewModal {
  &_time {
    color: var(--black-40, #999);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 3.5px;
  }

  &_reportedComment {
    color: var(--black-60, #666);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    padding-right: 12px;
  }

  &_row {
    width: 100%;
    border-radius: 0.625rem;
    background-color: #eaf2fc;
    margin-bottom: 12px;
    font-size: 16px;
    padding: 24px 12px;
  }
}

.ignore {
  padding: 24px 24px 0px;

  &_content {
    background: #eaf2fc;
    padding: 12px 12px 24px;
    border-radius: 0.625rem;
  }

  &_dropdown {
    height: 41px;
  }

  &_dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 0.625rem;
  }

  &_dropdown.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 41px !important;
  }

  &_textarea.ant-input {
    border: none !important;
    margin-top: 14px;
    height: 210px;
  }
}
