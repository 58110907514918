.appointmentBusiness {
  &_adminbusiness {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #7f7f7f;
  }
  &_mytitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #262626;
  }
  &_inputSearchAppointment {
    display: flex;
    justify-content: flex-end;
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
    }
  }
  &_businessAppointBlock {
    width: 100%;
    // height: 686px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 24px;
  }
  &_dateSlot {
    // color: #7F7F7F;
    border: 1px solid #d3d6e1;
    width: 177px;
    height: 46px;
    // top: 24px
    // left: 272px
    padding: 9.5px, 24px, 9.5px, 24px;
    border-radius: 5px;
    // gap: 10px
  }
  &_bookingList {
    border: 1px solid #d3d6e1;
    // height: 568px;
    border-radius: 10px;
    padding-top: 24px;
  }
  &_locDropdown {
    padding-left: 24px;
    @media screen and (min-width:768px) and (max-width:800px) {
      padding-left: 15px;
      padding-bottom: 15px;
    }
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
      padding-left: 2px;
    }
  }
  &_dropDown {
    border: 1px solid #d3d6e1;
    border-radius: 5px;
    width: 18vw;
    height: 7vh;
   @media screen and (min-width:800px) and (max-width: 1024px) {
      height: 4vh;
      width: 18vw;
    }
    @media screen and (min-width:768px) and (max-width: 800px) {
      height: 4vh;
      width: 18vw;
    }
    @media screen and (min-width:600px) and (max-width: 760px) {
      height: 4vh;
      width: 37vw;
    }
  }
  &_cal {
    padding-bottom: 24px;
    padding-left: 16px;
    
    @media (max-width:800px) {
      padding-bottom: 17px;
      padding-left: 0px;
    }
  }
  &_appointmentCount {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    padding-top: 5px;
    font-size: 1.1vw;
    font-weight: 500;
    line-height: 22px;
    color: #223369;
    // @include respond(tab-land) {
    //   font-size: 1.9vw;
    //   padding-top: 2vw;
    // }
    @media (max-width:1366px) {
      font-size: 1.5vw;
      
    }
    @media (max-width:991px) {
      font-size: 2vw;
      
    }
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
      font-size: 2.5vw;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 10px;
    }
  }
  &_listConatiner {
    background: #ffffff;
    // border: 1px solid red;
    // width: 1028px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;
  }
  &_listHeader {
    height: 46px;
    border-radius: 10px 10px 0px 0px;
    // background: #ff4144;
    padding: 10px;
    @media screen and (min-width:600px) and (max-width:768px) {
      height: auto;
    }
  }
  &_slotTimeIcon {
    width: 20px;
    height: 20px;
  }
  &_slotCount {
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width:600px) and (max-width:768px) {
      display: flex;
      justify-content: center;
    }
  }
  &_slotTiming {
    padding-left: 5px;
    padding-top: 2px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
  }
  &_slotTimeCount{
    @media screen and (min-width:600px) and (max-width:768px) {
      display: flex;
      justify-content: center;
    }
  }

  &_bookingInfoCard {
    // border: 1px solid #ffb8b9;
    // background: #fff4f4;
    width: 200px;
    height: 100px;
    border-radius: 5px;
  }
  &_storeName {
    color: #333333;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    padding-top: 12px;
    padding-left: 12px;
  }
  &_userName {
    padding-left: 12px;
    color: #4d4d4d;
    font-size: 14px;
    padding-top: 3px;
  }
  &_userPhoneNumber {
    padding-left: 12px;
    color: #4d4d4d;
    font-size: 14px;
    // padding-bottom: 3px;
  }

  &_slotDatacard {
    overflow-x: scroll;
    // ::-webkit-scrollbar {
    //   width: 0;
    // }
    // ::-webkit-scrollbar-track {
    //   background: transparent;
    // }
    // ::-webkit-scrollbar-thumb {
    //   background-color: transparent;
    // }
  }
  &_noSlotAvilable {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_noSlotMsg {
    color: #333333;
    //styleName: UI/B3/Reg-400;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 0px 0px 20px;
  }

  &_adminBiz{
    padding-top: 5px;
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }
  }
  &_synbtn{
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }
  }
  &_btnSync{
    background:#FFFFFF;
    color: #257CDB;
    border-radius:5px ;
    font-weight: 500;
    width: 9vw;
    height: 46px;
    border: 1px solid #257CDB;
    padding: 9.5px, 24px, 9.5px, 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:1024px) {
      width: 18vw;
    }
    @media (max-width:768px) {
      width: 18vw;
    }
    @media (max-width:600px) {
      width: 44vw;
    }
   
    
  }
  &_totalCount{
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
    }
  }
  &_btnSyncIcon{
    width: 15px;
    height: 15px;
  }
  &_inputSearch{
    width: 23vw;
    height: 46px;
    padding: 10px, 24px, 10px, 24px;
    border-radius: 5px; 
    @media (max-width:1366px) {
      width: 21vw;
    }
    @media (max-width:800px) {
      width: 17vw;
    }  
    @media (max-width:610px) {
      display: flex;
      justify-content: center;
      width: 44vw;
    }
  }

  &_syncText{
    padding: 1vw 0.5vw 0vw 0vw;
  }
}
.appointmentBusiness_btnSync.ant-btn-primary:hover.ant-btn-primary:focus {
  color: #fff;
  // border-color: #fff;
  background: #fff;
}