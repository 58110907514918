.storecatalogtext{
    width: 18.1875rem;
   height:2.3125rem;
    left: 12.5rem; 
    top: 51.875rem;
    font-family:Helvetica;
        font-style: normal;
        font-weight:400;
        font-size:1.2rem;
        line-height:1.5625rem;
        
        display:flex;
        align-items:center;
        
        color:#454545;
         padding-bottom:-2rem;
}

.storecataloginput{
    font-family: 'Roboto';
                  font-style: 'normal';
                padding-left:'1.7rem'
}