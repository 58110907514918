.floating-button-container {
  position: fixed;
  // bottom: 60px;
  left: 92vw;
  z-index: 1000;
  @media screen and (min-width: 2560px) {
    left: 95vw;
  }
  @media screen and (min-width: 2000px) and (max-width: 2560px) {
    left: 94vw;
  }
  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    left: 92vw;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    left: 91vw;
  }
  @media screen and (min-width: 991px) and (max-width: 1024px) {
    left: 89vw;
  }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    left: 87vw;
  }
  @media screen and (min-width: 280px) and (max-width: 576px) {
    left: 76vw;
  }
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
.floatingImg {
  padding-top: 0.8rem;
}

.relative-container {
  position: relative;

  .floating-background-image {
    position: absolute;
    z-index: 1;
    bottom: 36px;
    height: 150px;
    width: 141px;
    right: -22px;
    cursor: pointer;
    @media screen and (min-width: 991px) and (max-width: 1024px) {
      height: 146px;
      width: 139px;
    }
    @media screen and (min-width: 600px) and (max-width: 991px) {
      width: 133px;
      height: 133px;
    }
    @media screen and (min-width: 280px) and (max-width: 576px) {
      width: 121px;
      height: 120px;
      bottom: 33px;
    }
    @media screen and (min-width: 2550px) {
      bottom: 40px;
      height: 162px;
      width: 147px;
    }
  }

.floating-button {
  width: 100px;
  height: 100px;
  border: none;
  background: #223369;
  cursor: pointer;
  border-radius: 100%;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0px 8px 14.809160232543945px rgba(0, 0, 0, 0.2));
  @media (max-width: 576px) {
    width: 80px;
    height: 80px;
  }
  @media screen and (min-width: 600px) and (max-width: 991px) {
    width: 92px;
    height: 92px;
  }
  @media screen and (min-width: 2550px) {
    height: 108px;
    width: 108px;
  }
}
}
.floating-button:focus-visible {
  border: 0px solid transparent;
}
.floating-button:focus-visible {
  border: 0px solid transparent;
}
.discountNumber {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  padding-top: 0.5vw;
}
.referName_FloatButton {
  display: flex;
  justify-content: center;
  color: #223369;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.5vw;
}
.referModal {
  width: 25.2vw !important;
  height: 28vw !important;
  // flex-shrink: 0;
  border-radius: 26px;
  color: #000;
  // position: absolute;
  border-radius: 26px;
  right: -500px;
  position: fixed;
  z-index: 1002;
  
  @media (min-width: 2560px) {
    width: 20.2vw !important;
    height: 28vw !important;
  }
}

.referModalMObileView {
  width: 80vw !important;
  height: 28vw !important;
  flex-shrink: 0;
  border-radius: 26px;
  color: #000;
  // position: absolute;
  right: 0px;
}
.referModal .ant-modal-title {
  margin: 0;
  color: #223369;
  font-weight: 550;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
}
.referModal .ant-modal-close-x {
  margin-top: 0px;
  margin-right: 12px;
  @media (min-width: 2560px) {
    margin-top: -10px;
  }
}
.referModalMObileView .ant-modal-title {
  margin: 0;
  color: #000;
  font-weight: 550;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.referModal .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 26px 26px 0 0;
}
.referModalMObileView .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 26px 26px 0 0;
}
.referModal .ant-modal-content {
  width: 25.2vw !important;
  height: 22.5vw !important;
  // position: fixed;
  // bottom: 30px;
  // right: 30px;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 26px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.referModalMObileView .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 26px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.referModal .ant-modal-body {
  padding: 24px 0px 0px 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.referModalMObileView .ant-modal-body {
  padding: 24px 0px 0px 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.modalImage {
  width: 150px !important;
  height: 100.238px !important;
  flex-shrink: 0;
  opacity: 0.6;
}
.referModalText {
  padding-top: 26px;
  color: var(--Back-100, #1d1d1f);
  text-align: center;
  // font-size: 1.3vw;
  font-style: normal;
  font-weight: 570;
  line-height: 125%; /* 22.5px */
}
.referModalTextMobileView {
  padding-top: 26px;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--Back-100, #1d1d1f);
  text-align: center;
  font-size: 4vw;
  font-style: normal;
  font-weight: 570;
  line-height: 125%; /* 22.5px */
}
.referModalTextMobile {
  padding-top: 26px;
  color: var(--Back-100, #1d1d1f);
  text-align: center;
  font-size: 5vw;
  font-style: normal;
  font-weight: 570;
  line-height: 125%; /* 22.5px */
}
.referModalCopyRefer {
  width: 100% !important;
  // height: 25.1vw !important;
  height: 4vw;
  flex-shrink: 0;
  border-radius: 3.588px 3.588px 26px 26px;
  border: 1px dashed var(--Yello-Orange, #fa8216);
  background: var(--Yelo-25, #fff1bf);
  display: flex;
  justify-content: center;
  align-items: center;
}
.referModalCopyRefer_MobileView {
  width: 100% !important;
  // height: 25.1vw !important;
  height: 15vw;
  flex-shrink: 0;
  border-radius: 3.588px 3.588px 26px 26px;
  border: 1px dashed var(--Yello-Orange, #fa8216);
  background: var(--Yelo-25, #fff1bf);
  display: flex;
  justify-content: center;
  align-items: center;
}
.referModalCopyRefer2 {
  width: 100% !important;
  // height: 25.1vw !important;
  height: 4vw;
  flex-shrink: 0;
  border-radius: 3.588px 3.588px 26px 26px;
  // border: 1px dashed var(--Yello-Orange, #32871E);
  background: var(--Yelo-25, #50b85a);
  display: flex;
  justify-content: center;
  align-items: center;
}
.referModalCopyRefer2_MobileView {
  width: 100% !important;
  // height: 25.1vw !important;
  height: 15vw;
  flex-shrink: 0;
  border-radius: 3.588px 3.588px 26px 26px;
  // border: 1px dashed var(--Yello-Orange, #32871E);
  background: var(--Yelo-25, #50b85a);
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyTextIcon {
  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0;
  cursor: pointer;
  @media (min-width: 2560px) {
    width: 50px !important;
    height: 50px !important;
  }
}
.copyTextIcon2 {
  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0;
  cursor: pointer;
}
.referModalPara {
  display: flex;
  justify-content: flex-start;
  padding-top: 1vw;
  color: var(--Back-100, #1d1d1f);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 162.5% */
  @media (min-width: 2560px) {
    font-size: 20px;
  }
}
.referModalParaCopyText {
  display: flex;
  justify-content: flex-start;
  padding-top: 1vw;
  color: var(--Back-100, #1d1d1f);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 162.5% */
  @media (min-width: 2560px) {
    font-size: 20px;
  }
}
.referModalPara_MobileView {
  padding-right: 6vw;
  padding-top: 3vw;
  color: var(--Back-100, #1d1d1f);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
.referModalPara2 {
  padding-left: 17px;
  padding-top: 1vw;
  color: var(--Back-100, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.referModalPara2_MobileView {
  padding-left: 17px;
  padding-top: 4vw;
  color: var(--Back-100, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .referModalPara {
    padding-right: 0vw;
    padding-top: 1vw;
    color: var(--Back-100, #1d1d1f);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  .referModal {
    width: 33vw !important;
    height: 30vw !important;
    flex-shrink: 0;
    border-radius: 26px;
    color: #000;
    position: fixed;
    right: -250px;
    // bottom: -20px;
  }
  .referModalText {
    padding-top: 26px;
    color: var(--Back-100, #1d1d1f);
    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 570;
    line-height: 125%; /* 22.5px */
  }
  .referModalCopyRefer {
    width: 100% !important;
    // height: 25.1vw !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #fa8216);
    background: var(--Yelo-25, #fff1bf);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .referModalCopyRefer2 {
    width: 100% !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #32871e);
    background: var(--Yelo-25, #50b85a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .referModalPara {
    padding-right: 0vw;
    padding-top: 1vw;
    color: var(--Back-100, #1d1d1f);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  .referModal {
    width: 40.2vw !important;
    height: 35vw !important;
    flex-shrink: 0;
    border-radius: 26px;
    color: #000;
    position: fixed;
    right: -100px;
    // bottom: -20px;
  }
  .referModalText {
    padding-top: 26px;
    color: var(--Back-100, #1d1d1f);
    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 570;
    line-height: 125%; /* 22.5px */
  }
  .referModalCopyRefer {
    width: 100% !important;
    // height: 25.1vw !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #fa8216);
    background: var(--Yelo-25, #fff1bf);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .referModalCopyRefer2 {
    width: 100% !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #32871e);
    background: var(--Yelo-25, #50b85a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) and (min-width: 280px) {
  .referModal {
    width: 40.2vw !important;
    height: 35vw !important;
    flex-shrink: 0;
    border-radius: 26px;
    color: #000;
    position: fixed;
    right: -100px;
    // bottom: -20px;
  }
  .referModalText {
    padding-top: 26px;
    color: var(--Back-100, #1d1d1f);
    text-align: center;
    font-size: 2vw;
    font-style: normal;
    font-weight: 570;
    line-height: 125%; /* 22.5px */
  }
  .referModalCopyRefer {
    width: 100% !important;
    // height: 25.1vw !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #fa8216);
    background: var(--Yelo-25, #fff1bf);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .referModalPara {
    padding-right: 5vw;
    padding-top: 1vw;
    color: var(--Back-100, #1d1d1f);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
  .referModalCopyRefer2 {
    width: 100% !important;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 3.588px 3.588px 26px 26px;
    border: 1px dashed var(--Yello-Orange, #32871e);
    background: var(--Yelo-25, #50b85a);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.copyTextIconCatalogue {
  width: 30px !important;
  height: 23px !important;
  padding-left: 0.5rem;
  flex-shrink: 0;
  cursor: pointer !important;
  @media (min-width: 2560px) {
    width: 60px !important;
    height: 60px !important;
  }
}

.promo-modal .ant-modal-content {
  border-radius: 10px;
  text-align: center;
  padding: 24px;
}
.promo-banner-image {
  height: 100%;
  width: 100%;
}

.promo-text-overlay {
  position: absolute;
  top: 22%;
  left: 40%;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.getPopup {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}
.amountPopup {
font-family: Roboto;
font-size: 42px;
font-weight: 500;
line-height: 42px;
text-align: center;

}
.cashPopup {
  font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: center;

}

@media (max-width:500px) {
  .getPopup {
    font-size: 12px;
  }
  .amountPopup{
    font-size: 20px;
  }
  .cashPopup{
    font-size: 14px;
  }
  .promo-text-overlay{
    top:14%;
  }
}

@media (max-width: 400px) {
  .promo-text-overlay{
    top:6%;
  }
  
}
