.platformVariablesModal .ant-modal-content {
    border-radius: 0.625rem;
    padding: 0rem;
    margin: 0rem
}

.platformVariablesModal .ant-modal-body {
    padding: 0rem 0rem 1.5rem 0rem;
    margin: 0rem 0rem 1.5rem 0rem;

}

.platformVariablesModal .ant-divider-horizontal {
    margin: 1.5rem 0rem 1.5rem 0rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

}

.platformVariablesModal .ant-modal-header {
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

}

.platformVariablesModal .ant-modal-title {
    font-size: 1.5rem;
}

.platformVariablesModal_btn {
    border: 0.0625rem solid #257CDB;
    width: 70%;
    color: #257CDB;
    height: 2.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 0;

}

.platformVariable-table {
    background-color: white;
    width: 100%;

    @include respond(phone) {
        overflow: auto;
    }

    @include respond(tab-port) {
        overflow: auto;
    }

    @include respond(desktop) {
        overflow: auto;
    }
}

.platformVariable-table .ant-table-fixed {
    table-layout: fixed;
}

.platformVariable-table .ant-table-tbody>tr>td {
    word-wrap: break-word;
    word-break: break-all;
}

.platformVariable-table .ant-table-title {
    background-color: #3F9AF8;
    color: white;
    font-weight: 500;
    font-size: 1.25rem;
}

.platformVariable-table::-webkit-scrollbar {
    background: none
}


.platformVariable-table .ant-table-thead .ant-table-cell {
    background-color: #3F9AF8;
    color: white;
    text-align: center;
    font-size: 16px;
}

.platformVariable-table .ant-table-column-sorters::after {
    background-color: #3F9AF8;
}

.platformVariable-table .ant-table-column-sorter-inner {
    display: none
}

.platformVariable-table .ant-table-cell {
    background-color: white;
    text-align: center;
}

.platformVariable-table .ant-table-tbody .ant-table-cell:nth-child(4) {
    padding: 14px 0px;
}

.platformVariable-table .ant-table-tbody .ant-table-cell:nth-child(5) {
    padding: 0rem;
}

.platformVariables_divider .ant-divider-horizontal {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.platformVariablesModal_button {
    height: 36px;
    padding: 3px 36px;
    width: 20%;
    font-size: 20px
}

.platformVariablesModal_overflow {
    max-height: 15.5rem;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar {
        background: transparent;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #D3D6E1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar {
        margin-right: 20px;
    }
}

    .platformVariablesModal_dropdown .ant-select-selector {
        min-height: 3.25rem;
        align-self: center;
    }

    .platformVariablesModal_dropdown .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        align-items: center;
    }

    .platformVariablesModal_dropdown .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        align-items: center;
    }

    .platformVariablesModal_checkbox .ant-checkbox-inner {
        height: 32px;
        width: 32px;
        border: 2px solid #D3D6E1;
    }

    .platformVariablesModal_checkbox .ant-checkbox+span {
        align-self: center;
    }

    .platformVariablesModal_checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
        height: 22px;
        width: 11px;
        border: 3px solid #fff;
        border-top: 0;
        border-left: 0;
        border-radius: 2px;

    }
    .anticon{
        overflow: hidden;
    }