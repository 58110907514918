.wholler {
  background: linear-gradient(
    180deg,
    rgba(196, 224, 255, 0) 0%,
    rgba(194, 218, 245, 0.34) 47.88%,
    rgba(136, 179, 227, 0.66) 100%
  );
}
.about {
  width: 100%;
}
.another {
  margin-top: 16px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery_ {
  margin: 10px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  opacity: 0.9;
  transition: width 500ms ease-in-out;
}
.gallery_:nth-child(2) {
  background-size: cover;
  background-position: center;
}
.gallery_:nth-child(3) {
  background-size: cover;
  background-position: center;
}
.gallery_:nth-child(4) {
  background-size: cover;
  background-position: center;
}
.gallery_ h3 {
  color: #ffffff;
  transform: rotate(-90deg);
  transform-origin: bottom left;
}
.gallery_ .textAboutUs {
  opacity: 0;
  color: #ffffff;
}
.gallery_:hover h3 {
  transform: rotate(0deg);
  background-color: transparent;
  bottom: 15%;
}
.gallery_:hover .textAboutUs {
  opacity: 1;
  margin-top: 342px;
  margin-left: 11px;
}
.gallery_:hover {
  width: 400px;
  transition: width 500ms ease-in-out;
}
.text {
  margin-top: 50px;
}
.whole {
  margin-bottom: 10px;
}
.side_ {
  width: 320px !important;
  height: 420px !important;
}
.side_2 {
  width: 320px !important;
  height: 350px !important;
}
.slid-show {
  width: 320px;
  height: 300px;
}
.us {
  color: rgb(97, 97, 245);
  margin-bottom: 2px;
}
.collage-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  overflow: hidden;
}
.pararAboutus {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left !important;
}
.collage-container img {
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.3s ease;
}
.collage-container img:hover {
  transform: scale(1.1);
}
.heading {
  color: #1e90ff;
}
.wrapper {
  background-repeat: no-repeat;
}
.downloadss {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.banner-outer-div {
  background: linear-gradient(90deg, #f0592a 0%, #253c77 0.01%, #255577 46.67%);
  width: 100%;
  height: 26rem;
  display: flex;
  justify-content: center;
}
.banner-inner-div {
  height: 26rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  // @media (max-width: 425px) {
  //   height: 0rem;
  // }
}
.banner-content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 20px;
  }
}
.play-store-buttons {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  @media (max-width : 480px) {
    flex-direction: column;
  }
}
.outer-faq-box {
  border: 1px solid #ddd;
  margin-top: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.FaqPanel {
  background-color: #ffffff;
  margin-top: 7px;
}
.header-faq {
  text-align: center;
  margin-bottom: 20px;
}
.unicode {
  width: 0px;
  transform: rotate(50deg);
  color: #1e90ff;
  font-weight: 700;
}
.h1-text {
  width: 20px;
}
.app-container {
  padding-top: 24px;
  background: #fff;
}
.fyndr-for {
  text-align: center;
}
.mid-section {
  overflow: auto;
  height: 500px;
}
.mid-section::-webkit-scrollbar {
  display: none;
}
.ani-image {
  animation: fade-in linear;
  animation-timeline: view();
  animation-range-start: 10px;
  animation-range-end: 20px;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tabViewAboutUs.ant-tabs-tab-active {
  border-bottom: none !important;
}
.tabViewAboutUs.ant-tabs-tab-btn {
  font-size: 20px;
  font-weight: 500;
  color: #a1a1a1;
  /* color: rgb(0, 0, 0) !important; */
}
/* .ant-tabs-ink-bar {
  display: none;
} */
.tabViewAboutUs.ant-tabs {
  display: flex;
  align-items: center;
}
.aboutUs {
  &_aboutFyndr {
    padding-top: 24px;
    padding-bottom: 24px;
    @media (max-width: 576px) {
      padding-left: 4vw;
      padding-right: 2vw;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  &_aboutFyndrText {
    @media screen and (min-width: 600px) and (max-width: 768px) {
      padding: 0vw 2vw 0vw 2vw;
    }
  }
  &_aboutFyndrImg {
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      padding-top: 3vw;
      padding-right: 7vw;
    }
    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
      // padding-top: 20px;
      padding-right: 45px;
      // padding-bottom: 24px;
    }
  }
  &_fyndrHelp {
    padding: 4vw 4vw 0vw 0vw;
  }
  &_fyndrHelpText {
    padding: 0vw 2vw 3vw 0vw;
  }
  &_fyndrHelpImg {
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
      padding-top: 20px;
    }
  }
}
.faq {
  &_conatiner {
    padding: 50px;
    display: flex;
    justify-content: center;
  }
}
.col.ant-collapse {
  font-size: 14px;
  line-height: 1.5715;
  background-color: transparent;
  border: 0px solid #d9d9d9;
  border-radius: 2px;
}
.photoCarousal {
  height: 61vw;
  border-radius: 10px;
  display: flex;
  justify-content: last baseline;
  padding: 10px;
}
.photoText {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ffffff;
  font-size: 13px;
  z-index: 2;
}
.businessIndividualUsertext {
  font-size: 28px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
}
.aboutUsFloat {
  position: fixed;
  bottom: 20px;
  left: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.floatImg {
  height: 90px;
  width: 90px;
}
.aboutUsFloat:hover {
  background-color: #0056b3;
}
.aboutUsFloat:focus {
  outline: none;
}
// #app-content{
//   background-color: #FFFFFF !important;
// }
.mobileAboutUs_photoCrousal {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.452813) 50.42%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.textAboutUs {
  z-index: 1002;
  position: absolute;
}
.pararAboutus::first-letter {
  font-size: 150%;
}
.faq_container {
  box-shadow: 0px 4px 10px 0px #0000000d;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 0px 10px 24px 10px;
  width: 90%;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
}
.FaqText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  padding: 24px 0px 24px 0px;
  @media (max-width: 576px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
  }

  .faq-search{
    width: 42%;
    height: 42px;
    border-radius: 10px;
    background-color: #F4F8FD;
    border: 1px solid #D3D6E1;
    @media (max-width: 576px) {
      width: 90%;
    }
  }
}

.faq-search .ant-input{
  background-color: #F4F8FD;
}

.faq-custom-tab{
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 375px) {
      margin-left: 23px;
    }
}

.faq-custom-tab .ant-tabs-nav-list{
  @media (max-width: 576px) {
  width: 300px;
  overflow-x: scroll;
  }   
}

 .top-faq-question-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 

.faqQuestionBox {
  margin-bottom: 16px;
  width: 90%;
  @media (max-width: 576px) {
    margin-bottom: 16px;
  }
}
.faqQnA {
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 6px 0px #00000014;
  border: 1px solid #e6e6e6;
  color: #333333;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.faqQuestion {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    text-align: left;
    color: #333333;
  }
}
.faqAns {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  line-height: 30px;
  text-align: left;
  padding: 12px;
  box-shadow: 0px 4px 6px 0px #00000014;
  border: 1px solid #e6e6e6;
  border-top: none;
  background: #fff;
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #333333;
  }
}
.faqQuestionBox:hover {
  background-color: #eaf2fc;
}
.faqQnA:hover {
  background-color: #eaf2fc !important;
}
.tabPaneAboutUs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  justify-content: center;
}
.bankCarousel_tabPane .ant-tabs-tab {
  // border: 1px solid #fff !important;
  color: #7a8086 !important;
  font-size: 1.7vw;
  font-weight: 400;
  @media (max-width: 992px) {
    font-size: 2.5vw;
  }
  @media (max-width: 768px) {
    font-size: 3vw;
  }
  @media (max-width: 576px) {
    font-size: 4vw;
  }
}
.sliderPromotion {
  @media (max-width: 1024px) {
    padding-top: 10px;
  }
}

.ManuItem,
.aboutUsMenu {
  position: relative;

  .imgss {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }

  .textss,
  .WalletTextss,
  .AboutTextss {
    position: absolute;
    bottom: -16px;
    // left: 10px;
    width: 100%;
    padding: 2px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    font-size: 11px;
  }

  .textss {
    left: 10px;
  }

  .WalletTextss {
    left: -2px;
  }

  .AboutTextss {
    left: 4px;
  }
  &:hover .imgss {
    transform: translateY(-10px);
  }

  &:hover .textss,
  &:hover .WalletTextss,
  &:hover .AboutTextss {
    transform: translateY(0);
  }
}
.aboutUs_aboutFyndrImg{
  margin-left: 2rem;
}