.admin-table {
  background-color: white;
  width: 100%;

  @include respond(phone) {
    overflow: auto;
  }

  @include respond(tab-port) {
    overflow: auto;
  }

  @include respond(desktop) {
    overflow: auto;
  }
}

.drpdown-date {
  width: 10%;

  @include respond(tab-port) {
    margin-right: 5rem;
  }
}

.admin-table .ant-table-title {
  background-color: #3f9af8;
  color: white;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: left;
}

.admin-table ::-webkit-scrollbar {
  background: none;
}

.admin-table ::-webkit-scrollbar-thumb {
  background: #3f9af8;
}

.admin-table .ant-table-thead .ant-table-cell {
  background-color: #3f9af8;
  color: white;
  text-align: center;
}

.cust-dashboard {
  margin-bottom: 0.5rem;
  color: grey;
  font-family: "Helvetica";
  font-size: 500;
}

.admin-table .ant-table-column-sorters::after {
  background-color: #3f9af8;
}

.admin-table .ant-table-column-sorter-inner {
  display: none;
}

.admin-table .ant-table-cell {
  background-color: white;
  text-align: center;
}

.cust-dashboard-modal {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.admin-modal .ant-modal-header {
  background-color: #3f9af8;
}

.admin-modal .ant-modal-close-x {
  color: white;
}

.admin-modal .ant-modal-title {
  color: white;
  display: flex;
  justify-content: center;
}

.modal-div {
  display: flex;
  align-content: space-between;
  flex-direction: column;
}

.modal-cafe-name {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 0.5rem;
  height: 32px;
  display: flex;
  justify-content: center;
}

.dot-status-active {
  height: 0.5rem;
  width: 0.5rem;
  background-color: rgb(18, 227, 18);
  border-radius: 50%;
  display: inline-block;
}

.dot-status-inactive {
  height: 0.5rem;
  width: 0.5rem;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.dot-status-suspended {
  height: 0.5rem;
  width: 0.5rem;
  background-color: rgb(240, 240, 26);
  border-radius: 50%;
  display: inline-block;
}

.dot-status-deleted {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.admin-dashboard-userdetails {
  color: #3f9af7;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 2rem;
}

.admin_container {
  padding: 2rem;
}

.quickLoader {
  margin: 2rem;
  position: absolute;
  right: 5%;
  top: 10;
  z-index: 1;
  width: 5rem;
  height: 5rem;
}

.admininfo {
  // margin: 2rem;
  position: absolute;
  right: -23rem;
  top: 6rem;
  z-index: 1;
  width: auto;
  height: 2rem;
  background-color: $color-fyndr-danger-red;
  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-shadow: 0px 85px 102px -40px rgba(245, 61, 61, 1);
  -moz-box-shadow: 0px 85px 102px -40px rgba(245, 61, 61, 1);
  box-shadow: 0px 85px 102px -40px rgba(245, 61, 61, 1);
  cursor: pointer;
  @media screen and (min-width: 2200px) {
    top: 3rem;
    right: -20rem;
  }

  h5 {
    margin: 0;
    color: #eee;
  }
}

td p {
  margin-bottom: 0;
}

.report {
  padding-top: 2px;
  align-items: center;
}

.roleDropDown.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}

.report_dropdown {
  min-width: 100%;
  font-size: 1.125rem;
}

.report_dropdown
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  align-items: center;
}

.report_dropdown .ant-select-selector {
  height: 36px;
}

.report_dropdownCol {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #3a3a3a;
  font-weight: 400;
}

.comment_heading {
  padding-bottom: 2rem;
  margin-bottom: -2rem !important;
  margin-top: -2rem !important;
  padding-top: 3rem !important;
}

.user_management_form {
  padding: 0 12px;
}

.user_form_input {
  width: 100%;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #d3d6e1;
  background: #fff;
}

.user_form_input::placeholder {
  color: #4e5c87;
}

.user_form_input_select {
  width: 100%;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #d3d6e1;
  background: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.user_form_input_select.ant-select .ant-select-selector {
  color: #4e5c87 !important;
  border: none !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.react-tel-input .form-control {
  width: 100%;
  color: #4e5c87 !important;
  font-size: 14px;
  border: none;
}
