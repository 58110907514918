.viewStoreModal {
  overflow: hidden;
  background: #ffffff;
  border-radius: 0.625rem;

  &_text {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
  }
  &_image {
    border-radius: 0.625rem;
    width: 100%;
    // height: 15rem;
    object-fit: cover;
    aspect-ratio: 21/9;
  }

  &_btn-incr-decr {
    padding: 0 1rem 0 1rem;
  }

  &_btn-footer {
    // width: 14rem;
    border-radius: 0.5rem;
    background-color: #3f9af7;
    color: #ffffff;
  }

  &_items-list {
    height: 5.75rem;
    background-color: #eaf2fc;
    justify-content: space-between;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    // flex-direction: row;
    // gap: 16px;
    margin-bottom: 12px;
    border: 1px solid #d3d6e1;
  }

  &_item-price-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &_pricelist {
    line-height: 24px;
    padding-bottom: 0.25rem;
    font-size: 15px;
  }

  &_circle-img {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    object-fit: cover;
    margin: 1rem;
  }
}
.getDirection_modal .ant-modal-content {
  border-radius: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .getDirection_modal .ant-modal-content {
    border-radius: 0.5rem;
  }
}

.getDirection_modal .ant-modal-header {
  border-radius: 0.5rem;
}

.viewStoreModal .ant-modal-title {
  color: #223369;
  font-size: 20px;
}
.viewStoreModal .ant-modal-body {
  padding: 24px;
}
.viewStoreModal .ant-checkbox-wrapper {
  margin-right: 8px;
}
.viewStoreModal_btn-footer:active {
  background-color: #223369;
}

.viewStoreModal-input .ant-input-number-input {
  text-align: center;
}
.viewStoreModal .ant-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.viewStoreModal .ant-modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
