.customize_modal .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #d3d6e1;
  // padding: 24px;
}

.customize_modal .ant-modal-footer {
  border-top: 1px solid #d3d6e1;
  border-radius: 0px 0px 10px 10px;
}

.customize_modal .ant-modal-body {
  padding: 0px;
}


.customize_modal .ant-modal-footer {
  padding: 0px;
  border-top: none;
}

.customize_modal .ant-modal-content {
  border-radius: 0.625rem;
}

.customize_divider.ant-divider-horizontal {
  border: 1px solid #d3d6e1;
  margin: 24px 0px 0px 0px;
}

.customize_btn {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding: 10px 20px;
}
.customize_btn:hover {
  background-color: #4998ed;
  color: white;
}
.customize_btn:active {
  background-color: #223369;
}
.customize_title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.75rem;
  text-align: right;
  color: #223369;
  padding: 0px 24px;
  height: 60px;
}

.customize_modal .ant-modal-close-x {
  display: flex;
  align-items: center;
  height: 60px;
  text-transform: none;
  text-rendering: auto;
  // padding: 12px 0px 0px 0px;
}
.btn1:disabled {
  background-color: #f2f2f2;
}
.customize_divider2.ant-divider-horizontal {
  border: 1px solid #D3D6E1;
  margin: 0px;
}
