.app-header {
  // badge
  .ant-badge {
    sup {
      box-shadow: none;
    }
  }

  .bg-dark,
  .bg-primary,
  .bg-success,
  .bg-info,
  .bg-danger {
    color: $white;
    a {
      color: $white;
    }

    // badge
    .ant-badge {
      i {
        color: $white;
      }
    }

    // search box
    .search-box {
      ::placeholder {
        color: rgba($white, 0.65);
      }
      .input-bar:before,
      .input-bar:after {
        background: $white;
      }
    }
  }

  .bg-white,
  .bg-warning {
    color: $body-color2;
    a {
      color: $body-color2;
    }
  }
}

.app-header-menu-item {
  position: relative;
  z-index: 1000;
  width: 100%;
  min-width: 150px;
  right: 12rem;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2) !important;
}
