.promo-heading {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}
.promo-drop-down {
  width: 200px;
  height: 46px !important;
  display: flex;
  align-items: center;
  background: #257cdb;
  border-radius: 10px !important;
}
.promo-drop-down .ant-select-selection-placeholder {
  color: #fff;
  padding: 2rem 0 2rem 0;
}
.promo-drop-down .ant-select {
  display: none !important;
}

.promo-drop-down .ant-select-selector {
  background: #257cdb !important;
  border: none !important;
  color: #fff !important;
  border-radius: 10px !important;
  top: 8px;
}

.promo-drop-down .ant-select-arrow {
  color: #fff;
  border-radius: 10px !important;
}

.promo-drop-down .ant-select-item-option-content {
  color: #000;
  border-radius: 10px !important;
}

.promo-drop-down .ant-select-dropdown {
  background: #fff;
  border-radius: 10px !important;
}

.promo-drop-down .ant-select-item-option-active {
  background: #f0f0f0 !important;
  border-radius: 10px !important;
}

.promo-cards-container {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 1rem;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #cccccc transparent;
}

.activepromo {
  flex-shrink: 0;
  width: 185px;
  // height: 256px;
  background: #ffffff;
  top: 7px;
  gap: 0px;
  border-radius: 10px;
  border: 1px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #d3d6e1;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}
.active-promo-image {
  width: 185px;
  height: 185px;
  border-radius: 10px;
  z-index: 999;
  opacity: 1;
  // object-fit: contain;
}
.active-promo-heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: #257cdb;
}
.active-promo-date {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #666666 !important;
}
.horizontal-div-active {
  margin-top: 2rem;
}
.history-heading {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #333333;
}
.history-card .ant-card-body {
  padding: 0px;
}
.history-content {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.list-history .ant-list-split {
  border-bottom: 1px solid #fff !important;
}
.list-history .ant-list-item {
  border-bottom: 1px solid #fff !important;
}

.promo-buttons {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  transition: opacity 0.3s ease;
}

.edit123-button,
.active123-button {
  background: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transform: translateX(-150%);
  transition: transform 0.3s ease;
}

.active123-button {
  transform: translateX(100%);
}

.activepromo:hover .promo-buttons {
  opacity: 1;
}

.activepromo:hover .edit123-button {
  transform: translateX(0);
}

.activepromo:hover .active123-button {
  transform: translateX(0);
}
.country-dropdown {
  display: flex;
  align-items: center;
}
.country-dropdown .ant-select-selector {
  border-radius: 10px !important;
  height: 46px !important;
  top: 8px !important;
}
.country-dropdown .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}
.country-dropdown .ant-select-selection-item {
  display: flex;
  align-items: center;
}

// other css---------------------------------------------------------------

.all-promo-container {
  background: #ffffff;
  height: 90vh;
  border-radius: 10px;
}

.form-reg .ant-form-item {
  margin-bottom: 12px !important;
}

.right-image-container {
  position: relative;
  height: 100%;
  width: 100%;
}
#content-right-container {
  position: absolute;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.side-text-desc {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #ffffff !important;
}
.copy-to-clipboard {
  background: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
  backdrop-filter: blur(8px) !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  height: auto !important;
}

.copy-to-clipboard:hover {
  background: rgba(0, 0, 0, 0.8) !important;
}
.copy-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
  color: #ffffff !important;
}
.list-image {
  width: 105px;
  height: 105px;
  border-radius: 10px;
}
.list-inner-div {
  height: 105px;
  padding: 8px 8px 8px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.allpromo-outercontainer {
  padding: 42px 150px 56px;
}
@media (max-width: 1024px) {
  .allpromo-outercontainer {
    padding: 42px 50px 56px;
  }
  .copy-text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .allpromo-outercontainer {
    padding: 42px 10px 56px;
  }
  .copy-text {
    font-size: 16px;
  }
  .list-image {
    height: 95px;
    width: 95px;
  }
  .list-inner-div {
    height: 95px;
    // width: 95px;
  }
  .active-promo-heading {
    font-size: 12px;
  }
  .active-promo-date {
    font-size: 10px;
  }
  .history-content {
    font-size: 10px;
  }
}

.promoscroll-container {
  scroll-behavior: smooth;
}
.promoscroll-container {
  height: 76vh;
  overflow-y: auto;
}

.promoscroll-container::-webkit-scrollbar {
  width: 8px;
}

.promoscroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.promoscroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.promoscroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mobile-button-div {
  position: fixed;
  bottom: 2px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  width: 100%;
}

.public-private-switch .ant-switch-handle::before {
  background-color: #257cdb;
}
.public-private-switch .ant-switch-inner {
  color: #257cdb;
}