.slotTiming.ant-btn:active {
  color: #50B85A;
  border-color: #50B85A;

}

.slotTiming:disabled {
  color: #D3D6E1;
  border-color: #D3D6E1;
  background: none;
}

.slotTiming {
  color: #257CDB;
  border: 1px solid #257CDB;
}

.slotDate {
  color: #257CDB;
  border: 1px solid #257CDB;
}

.slotDate.ant-btn:active {
  color: #50B85A;
  border-color: #50B85A;

}

.slotDate:disabled {
  color: #D3D6E1;
  border-color: #D3D6E1;
  background: none;
}

.selectedButton {
  color: #50B85A;
  border-color: #50B85A;
}

.slotBook {
  &_calendar {
      width: 100%;
  }

  &_row {
      display: flex;
      padding: 0px 24px 136px 24px;
      
      @media (max-width: 1200px) {
          justify-content: space-between;
          padding: 0px 24px 136px 24px

      }
      @media (max-width: 992px) {
          justify-content:flex-start;
          padding: 0px 24px 136px 45px

      }
      @media (max-width: 768px) {
          justify-content:flex-start;
          padding: 0px 10px 136px 24px

      }
      @media (max-width: 576px) {
          justify-content: center;
          padding:0px 10px 12px 10px;
      }
  }
}

.location-dropdown .ant-select-arrow{
  @media (min-width:768px) and (max-width:874px) {
    top:27% !important;
  }
}
.location-dropdown {
  border: 1px solid var(--SECONDRY-20, #D3D6E1);
}
.location-dropdown { 
  @media (min-width:768px) and (max-width:874px) {
    border: none !important;
  }
  
}